import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/auth.guard';
import { ClientSyncGuard } from './views/client-sync.guard';
import { HandleCampaignLinksGuard } from './views/handle-campaign-links.guard';
import { HandlePromoLinksGuard } from './views/handle-promo-links.guard';
import { HandleSignOutGuard } from './views/handle-sing-out.guard';
import { HandleVerificationLinksGuard } from './views/handle-verification-links-guard.service';

export const appRoutes = {
  funnel: { path: '/' },
  login: { path: 'login' },
  loginWithBirthdate: { path: 'loginb' },
  loginFromApp: { path: 'loginc' },
  existingAccountLogin: { path: 'existing-login' },
  myaccount: { path: 'myaccount' },
  debug: { path: 'debug' },
  retention: { path: 'retention' },
  taxdataedit: { path: 'taxdataedit' },
  logout: { path: 'logout' },
  approval: { path: 'release-tax-declaration' },
  verification: { path: 'verify' },
  oldVerification: {
    path: 'verification',
    params: { taxFormId: 'taxFormId', type: 'type' },
  },
  oldCampaign: { path: 'info/campaign', params: { campaignId: 'campaignId' } },
  oldPromo: { path: 'info/promo', params: { promoCode: 'promoCode' } },
};

export type AppRoute = (typeof appRoutes)[keyof typeof appRoutes];

const routes: Routes = [
  {
    path: appRoutes.login.path,
    loadChildren: () =>
      import('./views/login-view/login-view.module').then(
        (m) => m.LoginViewModule
      ),
    data: {
      isEmailReadonly: false,
      allowLoginWithEmailLink: true,
      allowLoginWithBirthdate: false,
    },
    canActivate: [ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.loginFromApp.path,
    loadChildren: () =>
      import('./views/login-view/login-view.module').then(
        (m) => m.LoginViewModule
      ),
    data: {
      isEmailReadonly: false,
      allowLoginWithEmailLink: true,
      allowLoginWithBirthdate: false,
    },
    canActivate: [ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.loginWithBirthdate.path,
    loadChildren: () =>
      import('./views/login-view/login-view.module').then(
        (m) => m.LoginViewModule
      ),
    data: {
      isEmailReadonly: false,
      allowLoginWithEmailLink: true,
      allowLoginWithBirthdate: true,
    },
    canActivate: [ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.existingAccountLogin.path,
    loadChildren: () =>
      import('./views/login-view/login-view.module').then(
        (m) => m.LoginViewModule
      ),
    data: {
      isEmailReadonly: true,
      allowLoginWithEmailLink: false,
      allowLoginWithBirthdate: false,
    },
    canActivate: [ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.myaccount.path,
    loadChildren: () =>
      import('./views/myaccount-view/myaccount-view.module').then(
        (m) => m.MyaccountViewModule
      ),
    canActivate: [AuthGuard, ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.approval.path,
    loadChildren: () =>
      import('./views/taxcase-approval-view/taxcase-approval-view.module').then(
        (m) => m.TaxcaseApprovalViewModule
      ),
    canActivate: [AuthGuard, ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.debug.path,
    loadChildren: () =>
      import('./views/funnel-debug-view/funnel-debug-view.module').then(
        (mod) => mod.FunnelDebugViewModule
      ),
    canActivate: [ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.retention.path,
    loadChildren: () =>
      import('./views/retention-view/retention-view.module').then(
        (mod) => mod.RetentionViewModule
      ),
    canActivate: [AuthGuard, ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.taxdataedit.path,
    loadChildren: () =>
      import('./views/retention-view/retention-view.module').then(
        (mod) => mod.RetentionViewModule
      ),
    canActivate: [AuthGuard, ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: appRoutes.verification.path,
    loadChildren: () =>
      import('./views/verification-view/verification-view.module').then(
        (mod) => mod.VerificationViewModule
      ),
    canActivate: [AuthGuard, ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: `${appRoutes.oldVerification.path}/:${appRoutes.oldVerification.params.taxFormId}/:userEmail/:${appRoutes.oldVerification.params.type}`,
    canActivate: [HandleVerificationLinksGuard],
    children: [
      {
        path: '**',
        children: [],
      },
    ],
  },
  {
    path: `${appRoutes.oldCampaign.path}/:${appRoutes.oldCampaign.params.campaignId}`,
    canActivate: [HandleCampaignLinksGuard],
    children: [],
  },
  {
    path: `${appRoutes.oldPromo.path}/:${appRoutes.oldPromo.params.promoCode}`,
    canActivate: [HandlePromoLinksGuard],
    children: [],
  },
  {
    path: `${appRoutes.logout.path}`,
    canActivate: [HandleSignOutGuard],
    children: [],
  },
  {
    //default route has to be the last one, contains the default funnel
    path: '',
    loadChildren: () =>
      import('./views/funnel-view/funnel-view.module').then(
        (mod) => mod.FunnelViewModule
      ),
    canActivate: [ClientSyncGuard],
    canDeactivate: [ClientSyncGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./views/not-found-view/not-found-view.module').then(
        (mod) => mod.NotFoundViewModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
  providers: [
    ClientSyncGuard,
    HandleVerificationLinksGuard,
    HandleCampaignLinksGuard,
    HandlePromoLinksGuard,
    HandleSignOutGuard,
  ],
})
export class AppRoutingModule {}
