import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LanguageQuery } from '../language/language.query';

/**
 * A store-less query providing static properties from firebase directly
 */
@Injectable({ providedIn: 'root' })
export class StaticDataQuery {
  private settings$ = this.db
    .collection('statics')
    .doc<StaticData>('web_v1')
    .valueChanges()
    .pipe(shareReplay(1));

  private configurations$ = of({
    taxform: { openYears: environment.data.supportedTaxYears },
  });
  // https://app.clickup.com/t/2t201m4
  // should we load `configurations` from firestore? maybe once we
  // have https://firebase.google.com/docs/firestore/solutions/serve-bundles
  // so the initial page load is not effected?
  // this.db
  //   .collection('statics')
  //   // patch DB or is this stored somewhere else already?
  //   .doc<{ taxform: { openYears: string[] } }>('configurations')
  //   .valueChanges()
  //   .pipe(shareReplay(1));

  public offeredYears$ = this.configurations$.pipe(
    map((configurations) =>
      configurations?.taxform.openYears.map((openYear) => {
        const yearAsInt = openYear;
        const yearAsString = `${openYear}`;
        return {
          value: yearAsInt,
          order: -yearAsInt,
          labelDe: yearAsString,
        };
      })
    ),
    map((e) => StaticDataQuery.orderStatics(e ?? []))
  );

  public religions$ = this.settings$.pipe(
    map((statics) => StaticDataQuery.orderStatics(statics?.religions ?? []))
  );
  public unions$ = this.settings$.pipe(
    map((statics) => StaticDataQuery.orderStatics(statics?.unions ?? []))
  );
  public jobstates$ = this.settings$.pipe(
    map((statics) => StaticDataQuery.orderStatics(statics?.jobstatus ?? []))
  );
  public disabilities$ = this.settings$.pipe(
    map((statics) => StaticDataQuery.orderStatics(statics?.disabilities ?? []))
  );
  public translated = {
    religions$: combineLatest([
      this.religions$,
      this.languageQuery.currentLanguage$,
    ]).pipe(
      map(([values, language]) =>
        StaticDataQuery.translateStaticValue(values, language)
      )
    ),
    offeredYears$: combineLatest([
      this.offeredYears$,
      this.languageQuery.currentLanguage$,
    ]).pipe(
      map(([values, offeredYears]) =>
        StaticDataQuery.translateStaticValue(values, offeredYears)
      )
    ),
    unions$: combineLatest([
      this.unions$,
      this.languageQuery.currentLanguage$,
    ]).pipe(
      map(([values, language]) =>
        StaticDataQuery.translateStaticValue(values, language)
      )
    ),
    jobstates$: combineLatest([
      this.jobstates$,
      this.languageQuery.currentLanguage$,
    ]).pipe(
      map(([values, language]) =>
        StaticDataQuery.translateStaticValue(values, language)
      )
    ),
    disabilities$: combineLatest([
      this.disabilities$,
      this.languageQuery.currentLanguage$,
    ]).pipe(
      map(([values, language]) =>
        StaticDataQuery.translateStaticValue(values, language)
      )
    ),
  };

  constructor(
    private db: AngularFirestore,
    private languageQuery: LanguageQuery
  ) {}

  private static orderStatics<TStaticValue extends StaticValue>(
    statics: TStaticValue[]
  ): TStaticValue[] {
    return statics.sort((s1, s2) => {
      if (s1.order > s2.order) {
        return 1;
      }

      if (s1.order < s2.order) {
        return -1;
      }

      return 0;
    });
  }

  private static translateStaticValue<TStaticValue extends StaticValue>(
    staticValues: TStaticValue[],
    language: string
  ) {
    const defaultKey = 'labelDe';
    let labelKey: 'labelDe' | 'labelEn' | 'labelEs' = defaultKey;
    switch (language) {
      case 'en':
        labelKey = 'labelEn';
        break;
      case 'es':
        labelKey = 'labelEs';
        break;
    }
    return staticValues.map((e) => ({
      ...e,
      text: e[labelKey] ?? e[defaultKey] ?? '',
    }));
  }
}

export interface StaticValue<TValue = unknown> {
  value: TValue;
  labelDe: string;
  labelEn?: string;
  labelEs?: string;
  order: number;
}

export interface Religion extends StaticValue {
  tax: boolean;
}

type Jobstatus = StaticValue<string>;
type Union = StaticValue<string>;
type Disablitity = StaticValue<string>;
type OfferedYears = StaticValue<number>;

export interface StaticData {
  disabilities: Disablitity[];
  jobstatus: Jobstatus[];
  religions: Religion[];
  unions: Union[];
  offeredYears: OfferedYears[];
}
