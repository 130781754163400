import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlSerializer,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { appRoutes } from '../../app-routing.module';
import { AuthQuery } from '../../state/auth/auth.query';
import { queryParams } from '../../state/router/login-params.query';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authQuery: AuthQuery,
    private urlSerializer: UrlSerializer,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authQuery.isLoggedIn$.pipe(
      map((isLoggedIn) => {
        const routeUrlTree = this.urlSerializer.parse(state.url);
        const emailParam = routeUrlTree.queryParams.email;

        if (
          isLoggedIn &&
          routeUrlTree.queryParams.mode !== queryParams.mode.signIn
          // catch user that need to re-login as link contains mode signIn
        ) {
          if (emailParam) {
            // remove email from query param as user is already logged in
            const currentPath = state.url.split('?')[0];
            return this.router.createUrlTree([currentPath], {
              queryParams: { ...route.queryParams, email: undefined },
            });
          } else {
            return true;
          }
        } else {
          const continueUrlTree = routeUrlTree;
          // we extract and delete only the signIn Parameters to allow usage of queryparams in the continue Url
          // example signInLink: http://localhost:4204/info?mode=signIn&lang=en&oobCode=IYKj0gTKtbYg8wHnemXrveaoTxYkF0YZ-6wkUP7JgWn_zB57UY_yEN&apiKey=fake-api-key
          const signInParams = {
            mode: continueUrlTree.queryParams.mode,
            lang: continueUrlTree.queryParams.lang,
            oobCode: continueUrlTree.queryParams.oobCode,
            apiKey: continueUrlTree.queryParams.apiKey,
          };
          if (signInParams.mode === queryParams.mode.signIn) {
            delete continueUrlTree.queryParams.mode;
            delete continueUrlTree.queryParams.lang;
            delete continueUrlTree.queryParams.oobCode;
            delete continueUrlTree.queryParams.apiKey;
          }
          if (emailParam) {
            delete continueUrlTree.queryParams.email;
          }
          const continueUrl = this.urlSerializer.serialize(continueUrlTree);
          const continueUrlEncoded = encodeURIComponent(continueUrl);

          return this.router.createUrlTree([appRoutes.login.path], {
            queryParams: {
              email: emailParam,
              continue: continueUrlEncoded,
              ...signInParams,
            },
          });
        }
      })
    );
  }
}
