export enum FunnelQueryParam {
  isTest = 'isTest',
  taxFormId = 'taxFormId',
  skipInitialRedirect = 'skipInitialRedirect',
  year = 'year',
  createTaxForm = 'createTaxForm',
  createTaxFormFromLatestCompleted = 'createTaxFormFromLatestCompleted',
  friendId = 'friendid',
  campaignId = 'campaignId',
  promoCode = 'promoCode',
  continueFunnel = 'continueFunnel', // Do not load or reset TaxFormStore, used for example after login in funnel
  reportBug = 'reportBug',
  abTests = 'abTests',
  featureToggles = 'featureToggles',
}
