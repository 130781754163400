import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  CreateJwtForBrazeClientSdkAuthInput,
  CreateJwtForBrazeClientSdkAuthOutput,
} from '@expresssteuer/braze-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class CreateJwtForBrazeClientSdkAuth {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: CreateJwtForBrazeClientSdkAuthInput) {
    console.log('Calling braze-createJwtForBrazeClientSdkAuth');
    return this.afFunctions.httpsCallable<
      CreateJwtForBrazeClientSdkAuthInput,
      CreateJwtForBrazeClientSdkAuthOutput
    >('braze-createJwtForBrazeClientSdkAuth')(input);
  }
}
