import { Injectable } from '@angular/core';
import { Store } from '@datorama/akita';
import { TodoStatus } from '@expresssteuer/client-todos-api-angular';
import {
  Answer,
  AnswerFeedback,
  ClientTodo,
  ClientTodoId,
} from '@expresssteuer/client-todos-api-interfaces';

export type ClientTodoWithStatus = ClientTodo & {
  status: TodoStatus;
};

export type TodoSessionState = {
  initiatedCompletion?: boolean;
  initiatedDraftUpsert?: boolean;
  failedToWriteAnswer?: boolean;
  succeededToWriteAnswer?: boolean;
  initiatedEscalation?: boolean;
  succeededToEscalateTodo?: boolean;
  failedToEscalateTodo?: boolean;
};

export interface TodosState {
  todos?: Record<ClientTodoId, ClientTodoWithStatus>;
  answers?: Record<ClientTodoId, Record<string, Answer>>;
  feedback?: Record<
    ClientTodoId,
    Record<string, Record<string, AnswerFeedback>>
  >;

  activeTodoId?: ClientTodoId;

  /**
   * Indicators for things that happend in this user's
   * browser window session.
   * ie. for optimistic loading and processing indicators queries
   */
  session?: Record<ClientTodoId, TodoSessionState | undefined>;
}

@Injectable({ providedIn: 'root' })
export class TodosStore extends Store<TodosState> {
  constructor() {
    super({}, { name: 'client-todos', resettable: true });
  }

  updateSession(args: {
    todoId: ClientTodoId;
    reduce: (s: TodoSessionState) => TodoSessionState;
  }) {
    this.update((s) => {
      return {
        ...s,
        session: {
          ...s.session,
          [args.todoId]: {
            ...args.reduce({ ...(s.session?.[args.todoId] ?? {}) }),
          },
        },
      };
    });
  }
}
