import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthQuery } from '../auth/auth.query';
import { HotjarQuery } from './hotjar.query';
import { HotjarStore } from './hotjar.store';
import { MixpanelEvent } from './mixpanel.event';

const CACHE_BUFFER_SIZE = 10;

type hj = (type: 'event' | 'identify', payload: string) => void;

declare global {
  interface Window {
    hj: hj;
  }
}

@Injectable({ providedIn: 'root' })
export class HotjarService {
  private window: Window;
  private trackingCache = new ReplaySubject<MixpanelEvent>(CACHE_BUFFER_SIZE);
  private trackingCacheSubscription?: Subscription;
  private syncUserSubscription?: Subscription;

  /**
   * Helper to observe the latest setter value to `globalThis.ga`
   */
  private hotjarVar$ = new Subject<void>();

  #syncHotjarLoaded$ = this.hotjarVar$.pipe(
    switchMap(async () => {
      this.store.update(() => {
        return {
          hotjarLoaded: true,
        };
      });
    }),
    shareReplay(1)
  );

  #activateOrDeactivateWhenReady$ = this.query.hotjarIsLoaded$.pipe(
    tap((isLoaded) => {
      if (isLoaded) {
        this.activate();
      } else {
        this.deactivate();
      }
    })
  );

  constructor(
    private authQuery: AuthQuery,
    @Inject(DOCUMENT) private document: Document,
    private store: HotjarStore,
    private query: HotjarQuery
  ) {
    if (!this.document.defaultView) {
      throw new Error('Browser does not support window or globalThis');
    }
    this.window = this.document.defaultView;

    // spy on the setter of `window.hj` and notify `this.hotjarVar$` on set
    const hotjarVar$ = this.hotjarVar$;
    let proxyHotjar: hj;
    Object.defineProperty(this.window, 'hj', {
      set: function (val) {
        if (typeof val === 'function') {
          proxyHotjar = val;
          hotjarVar$.next();
        }
      },
      get: function () {
        return proxyHotjar;
      },
    });
  }

  syncAll() {
    return environment.toggles.sendHotjarEvents
      ? combineLatest([
          this.#syncHotjarLoaded$,
          this.#activateOrDeactivateWhenReady$,
        ])
      : of();
  }

  private activate() {
    this.startSendingFromCache();
    this.syncUser();
  }

  private deactivate() {
    this.stopSendingFromCache();
    this.stopSyncUser();
  }

  private startSendingFromCache() {
    this.trackingCacheSubscription = this.trackingCache.subscribe((event) => {
      this.sendEvent(event.eventName);
    });
  }

  private stopSendingFromCache() {
    if (this.trackingCacheSubscription) {
      this.trackingCacheSubscription.unsubscribe();
      this.trackingCacheSubscription = undefined;
      /*
        We need to create a new tracking cache as otherwise all events will be
        resent when the cache is subscribed again. But we should only do this when
        there was a running subscription as otherwise we would drop initial events.
     */
      this.trackingCache = new ReplaySubject<MixpanelEvent>(CACHE_BUFFER_SIZE);
    }
  }

  private syncUser() {
    this.syncUserSubscription = this.authQuery.user$.subscribe((user) => {
      if (user && user.isAnonymous === false) {
        this.setIdentifier(user.uid);
      }
    });
  }

  private stopSyncUser() {
    if (this.syncUserSubscription) {
      this.syncUserSubscription.unsubscribe();
      this.syncUserSubscription = undefined;
    }
  }

  private setIdentifier(uid: string) {
    if (typeof this.window.hj === 'function') {
      this.window.hj('identify', uid);
    }
  }

  addMixpanelEvent(mixpanelEvent: MixpanelEvent) {
    this.trackingCache.next(mixpanelEvent);
  }

  private sendEvent(event: string) {
    if (typeof this.window.hj === 'function') {
      this.window.hj('event', event);
    }
  }
}
