import { Injectable, Injector } from '@angular/core';
import { Query } from '@datorama/akita';
import { TodoStatus } from '@expresssteuer/client-todos-api-angular';
import {
  Answer,
  AnswerFeedback,
  ClientTodo,
  ClientTodoId,
  ClientTodoType,
  VmaProofQAA,
} from '@expresssteuer/client-todos-api-interfaces';
import { selectReader, withSync } from '@expresssteuer/state-angular';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
} from 'rxjs';
import { ClientQuery } from '../client/client.query';
import { JobsDataQuery } from '../jobs-data/jobs-data.query';
import { TodosService } from './todos.service';
import { ClientTodoWithStatus, TodosState, TodosStore } from './todos.store';

interface QueryBase extends TodosState {
  openTodos?: Record<ClientTodoId, ClientTodoWithStatus & { active: true }>;
  completedTodos:
    | Record<
        ClientTodoId,
        ClientTodoWithStatus & { active: false; status: TodoStatus.completed }
      >
    | undefined;
  /**
   * Uncompleted Client Todos which are not escalated
   */
  pendingTodos:
    | Record<
        ClientTodoId,
        ClientTodoWithStatus & {
          active: true;
          status: TodoStatus.new | TodoStatus.draft | TodoStatus.erroneous;
        }
      >
    | undefined;
  openTodosCount?: number;
  activeGroup?: {
    todo: ClientTodoWithStatus;
    answer?: Answer;
    feedback?: AnswerFeedback;
  };
}

@Injectable({ providedIn: 'root' })
export class TodosQuery extends Query<TodosState> {
  constructor(
    protected store: TodosStore,
    private injector: Injector,
    private clientQuery: ClientQuery,
    private jobsDataQuery: JobsDataQuery
  ) {
    super(store);
  }

  /**
   * To assure all queries are in sync, instead of using
   * combineLatest wich could potentially lead to slight
   * offsets between queries, we have this base.
   */
  private base$ = this.withSync(() =>
    this.select((s) => TodosQuery.buildBaseFromState(s))
  );

  todos$ = this.selectReaderFromBase((s) => s.todos);
  answers$ = this.selectReaderFromBase((s) => s.answers);
  isLoading$ = combineLatest({
    clientCreatedDate: this.clientQuery.select((e) =>
      e.data?.created?.toDate()
    ),
    todos: this.todos$,
  }).pipe(
    map(({ todos, clientCreatedDate }) => {
      if (todos && Object.keys(todos).length > 0) {
        return false;
      }
      if (!clientCreatedDate) {
        return true;
      }
      const initialWonderlandReleaseDate = new Date(
        'Fri Jan 27 2023 00:30:00 GMT+0100'
      );
      if (+clientCreatedDate > +initialWonderlandReleaseDate) {
        return true;
      }
      // we dont know, so for now, lets fallback to false
      return false;
    })
  );
  openTodos$ = this.selectReaderFromBase((s) => s.openTodos);
  openTodosCount$ = this.selectReaderFromBase((s) => s.openTodosCount);
  pendingTodos$ = this.selectReaderFromBase((s) => s.pendingTodos);
  completedTodos$ = this.selectReaderFromBase((s) => s.completedTodos);
  activeTodoId$ = this.selectReaderFromBase((s) => s.activeTodoId);
  activeGroup$ = this.selectReaderFromBase((s) => s.activeGroup);
  session$ = this.selectReaderFromBase((s) => s.session);
  hasPartnerKycTodos$ = this.selectReaderFromBase((s) => {
    let partnerInOpenTodos = false;
    let partnerInCompletedTodos = false;

    if (s.openTodos) {
      partnerInOpenTodos = Object.values(s.openTodos).some((todo) =>
        this.isKycTodo(todo, { forClient: false, forPartner: true })
      );
    }
    if (s.completedTodos) {
      partnerInCompletedTodos = Object.values(s.completedTodos).some((todo) =>
        this.isKycTodo(todo, { forClient: false, forPartner: true })
      );
    }
    return partnerInOpenTodos || partnerInCompletedTodos;
  });
  /**
   * Indicates if user has uncompleted KYC todos which are not escalated
   */
  hasPendingKycTodos$ = this.pendingTodos$.pipe(
    map((todos) => {
      if (!todos) {
        return false;
      }
      const pendingKycTodos = Object.values(todos).filter((todo) =>
        this.isKycTodo(todo)
      );
      return pendingKycTodos.length > 0;
    })
  );

  public descriptionFor$(todo: ClientTodo) {
    if (VmaProofQAA.isTodo(todo)) {
      const jobItemId = todo.todoMatcherMetadata.input.jobItemId;
      const year = todo.todoMatcherMetadata.input.year;
      if (jobItemId) {
        return this.jobsDataQuery.for(jobItemId).jobsData$.pipe(
          map((data) => data?.employerName),
          map((employerName) =>
            year ? `${employerName} (${year})` : employerName
          )
        );
      }
    }
    return of(null);
  }

  private isKycTodo(
    todo: ClientTodoWithStatus,
    options: { forClient: boolean; forPartner: boolean } = {
      forClient: true,
      forPartner: true,
    }
  ) {
    let isClientKycTodo = false;
    let isPartnerKycTodo = false;
    if (options.forClient) {
      isClientKycTodo =
        todo.type === ClientTodoType.ClientTaxId ||
        todo.type === ClientTodoType.ClientIdentifyingDocument;
    }

    if (options.forPartner) {
      isPartnerKycTodo =
        todo.type === ClientTodoType.PartnerTaxId ||
        todo.type === ClientTodoType.PartnerIdentifyingDocument;
    }

    return isClientKycTodo || isPartnerKycTodo;
  }

  private static buildBaseFromState(s: TodosState): QueryBase {
    const openTodosEntries = s.todos
      ? Object.entries(s.todos)?.filter(
          (t): t is [ClientTodoId, ClientTodoWithStatus & { active: true }] =>
            (t[1] as ClientTodoWithStatus).active
        )
      : undefined;
    const openTodos = openTodosEntries
      ? Object.fromEntries(openTodosEntries)
      : undefined;
    const openTodosCount = openTodosEntries?.length;

    const completedTodosEntries = s.todos
      ? Object.entries(s.todos)?.filter(
          (
            t
          ): t is [
            ClientTodoId,
            ClientTodoWithStatus & {
              active: false;
              status: TodoStatus.completed;
            }
          ] => {
            const todo = t[1] as ClientTodoWithStatus;
            return !todo.active && todo.status === TodoStatus.completed;
          }
        )
      : undefined;
    const completedTodos = completedTodosEntries
      ? Object.fromEntries(completedTodosEntries)
      : undefined;

    const pendingTodosEntries = s.todos
      ? Object.entries(s.todos)?.filter(
          (
            t
          ): t is [
            ClientTodoId,
            ClientTodoWithStatus & {
              active: true;
              status: TodoStatus.new | TodoStatus.draft | TodoStatus.erroneous;
            }
          ] => {
            const todo = t[1] as ClientTodoWithStatus;
            return (
              todo.active &&
              (todo.status === TodoStatus.new ||
                todo.status === TodoStatus.draft ||
                todo.status === TodoStatus.erroneous)
            );
          }
        )
      : undefined;
    const pendingTodos = pendingTodosEntries
      ? Object.fromEntries(pendingTodosEntries)
      : undefined;

    const activeTodo = s.activeTodoId ? s.todos?.[s.activeTodoId] : undefined;
    const activeAnswer = s.activeTodoId
      ? Object.values(s.answers?.[s.activeTodoId] ?? {}).sort(
          (a, b) => +b?.createdAt?.toDate() - +a?.createdAt?.toDate()
        )[0]
      : undefined;
    const latestFeedback =
      s.activeTodoId && activeAnswer?.id
        ? Object.values(s.feedback?.[s.activeTodoId]?.[activeAnswer.id] ?? {})
            // get the latest accepted feedback, or the latest if none accepted
            // TODO is this correct? should a CS employee be able to change a false positive feedback?
            .sort((a, b) => +a?.createdAt?.toDate() - +b?.createdAt?.toDate())
            .reduce((acc, feedback) => {
              if (feedback?.accepted) {
                return feedback;
              }
              if (!acc) {
                return feedback;
              }
              return acc;
            }, undefined as undefined | AnswerFeedback)
        : undefined;

    const activeGroup = activeTodo
      ? {
          todo: activeTodo,
          answer: activeAnswer,
          feedback: latestFeedback,
        }
      : undefined;

    return {
      ...s,
      openTodos,
      pendingTodos,
      openTodosCount,
      completedTodos,
      activeGroup,
    };
  }

  private withSync<T>(callback: () => Observable<T>): Observable<T> {
    return withSync(
      () => this.injector.get(TodosService).syncAll$,
      () => callback()
    );
  }

  private selectReaderFromBase<TVal>(selector: (state: QueryBase) => TVal) {
    return selectReader(
      {
        getValue: () => TodosQuery.buildBaseFromState(this.store.getValue()),
      },
      {
        select: (se) => {
          return this.base$.pipe(
            map(se),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true })
          );
        },
      },
      selector
    );
  }
}
