import { AngularFirestore } from '@angular/fire/compat/firestore';
import { applyTransaction } from '@datorama/akita';
import { DeepPartial } from '@expresssteuer/deep-object-helper';
import { WithId } from '@expresssteuer/models';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  FirebaseDocumentQuery,
  removeFromDotObjectIfAlreadyInObject,
} from './firebase-document.query';
import {
  FirebaseDocumentState,
  FirebaseDocumentStore,
} from './firebase-document.store';

export class FirebaseDocumentService<
  TData,
  TMeta = undefined,
  TError = any,
  TLoading = any
> {
  #logger: EsuiLoggerService;
  _syncDocument$?: Observable<
    Partial<FirebaseDocumentState<TData, TMeta, TError>>
  >;

  constructor(
    private firebaseDocumentStore: FirebaseDocumentStore<
      TData,
      TMeta,
      TError,
      TLoading
    >,
    private firebaseDocumentQuery: FirebaseDocumentQuery<
      TData,
      TMeta,
      TError,
      TLoading
    >,
    protected afs: AngularFirestore,
    logger: EsuiLoggerService
  ) {
    this.#logger = logger.getNewInstance(this);
  }

  /**
   * Write data directly to firebase bypassing the localChangedData.
   * Loading and error tracking is not handled.
   * @throws
   */
  protected async writeDirectlyToFirebase(data: Partial<TData>) {
    const { refPath } = this.firebaseDocumentStore.getValue();
    this.#logger.debug('writeDirectlyToFirebase', { refPath, data });

    if (!refPath) {
      throw new Error('no refPath set for writeDirectlyToFirebase');
    }
    if (!data || Object.keys(data).length < 1) {
      throw new Error('no changes to update');
    }

    await this.afs.doc(refPath).update(data);
  }

  setActiveDocumentReference(refPath?: string | null) {
    if (refPath === this.firebaseDocumentStore.getValue().refPath) {
      return;
    }
    this.firebaseDocumentStore.reset();
    if (refPath) {
      this.firebaseDocumentStore.update({ refPath });
    }
  }

  /**
   * Get a downstream to the active document
   * and set loading indicator
   * @note Can be overwritten
   */
  protected syncDocument_getDownstreamReferenceToActiveDocument$(): Observable<
    (TData & { id: string }) | undefined
  > {
    return this.firebaseDocumentQuery.refPath$.pipe(
      distinctUntilChanged(),
      tap(() => this.firebaseDocumentStore.setLoading(true)),
      switchMap((activeDocumentRefPath) => {
        if (activeDocumentRefPath) {
          return this.afs
            .doc<TData>(activeDocumentRefPath)
            .valueChanges({ idField: 'id' });
        } else {
          return of(undefined);
        }
      })
    );
  }

  /**
   * Run side effects on a recieved document before finishing up
   *
   * @note Can be overwritten to implement side effects (default is to do nothing)
   */
  protected syncDocument_sideEffectOnLatestDocument$(
    observable: Observable<TData & { id: string }>
  ): Observable<TData & { id: string }> {
    return observable;
  }

  /**
   * Run when finishing up the complete load process
   *
   * @note Can be overwritten
   */
  protected syncDocument_whenFinishedLoadingLatestDocument$(
    observable: Observable<Partial<FirebaseDocumentState<TData, TMeta>>>
  ): Observable<Partial<FirebaseDocumentState<TData, TMeta>>> {
    return observable.pipe(
      tap({
        next: (state) => {
          this.firebaseDocumentStore.setError(null);
          this.firebaseDocumentStore.update(state);
          this.firebaseDocumentStore.setLoading(false);
        },
        error: (e) => {
          this.#logger.error('failed to receive Document', e);
          this.firebaseDocumentStore.update({
            data: undefined,
          });
          this.firebaseDocumentStore.setError({ syncFailed: true });
          this.firebaseDocumentStore.setLoading(false);
        },
      })
    );
  }

  /**
   * Build new Partial state with the new set of data
   *
   * @note Can be overwritten
   */
  protected syncDocument_buildStateFromLatestDocument$(
    observable: Observable<TData & { id: string }>,
    discardChangesWhenNotNew = true
  ): Observable<Partial<FirebaseDocumentState<TData, TMeta>>> {
    return observable.pipe(
      map((data) => {
        const currentValue = this.firebaseDocumentStore.getValue();

        let newLocalChangedData;
        if (discardChangesWhenNotNew) {
          const withoutFieldsAlreadyInDownstream =
            removeFromDotObjectIfAlreadyInObject(
              currentValue.localChangedData,
              data
            );
          newLocalChangedData =
            withoutFieldsAlreadyInDownstream &&
            Object.keys(withoutFieldsAlreadyInDownstream).length > 0
              ? withoutFieldsAlreadyInDownstream
              : undefined;
        } else {
          newLocalChangedData = currentValue.localChangedData;
        }

        return {
          data: data ?? undefined,
          hasOnlineChangedAfterLocalChanged:
            !!newLocalChangedData?.localChangedData,
          localChangedData: newLocalChangedData,
        };
      })
    );
  }

  /**
   * Sync the active document
   *
   * Run the lifecycle steps (which may be overriden):
      1. this.syncDocument_getDownstreamReferenceToActiveDocument$
      2. this.syncDocument_sideEffectOnLatestDocument$
      2.1 this.syncDocument_buildStateFromLatestDocument$
      3. this.syncDocument_whenFinishedLoadingADocument$
   */
  syncDocument() {
    if (!this._syncDocument$) {
      this._syncDocument$ =
        this.syncDocument_getDownstreamReferenceToActiveDocument$().pipe(
          filter((e): e is WithId<TData> => !!e),
          (e) => this.syncDocument_sideEffectOnLatestDocument$(e),
          (e) => this.syncDocument_buildStateFromLatestDocument$(e),
          (e) => this.syncDocument_whenFinishedLoadingLatestDocument$(e),
          shareReplay({ bufferSize: 1, refCount: true })
        );
    }
    return this._syncDocument$;
  }

  updateLocalChanges(
    data: DeepPartial<TData>,
    options?: { onlyRealChanges: boolean }
  ) {
    return this.firebaseDocumentStore.updateLocalChanges(data, options);
  }

  discardLocalChanges() {
    if (this.firebaseDocumentStore.getHasPendingSaveData()) {
      this.#logger.warn(
        'Discard while having pendingSaveData; the save operation is still being processed. However, if the operation fails, changes reappear as localChangedData.'
      );
    }

    this.firebaseDocumentStore.update({
      localChangedData: undefined,
      hasOnlineChangedAfterLocalChanged: false,
    });
  }

  private moveLocalChangedDataToPendingSave() {
    const id = Date.now();
    this.firebaseDocumentStore.update((s) => {
      return {
        ...s,
        localChangedData: undefined,
        pendingSaveData: {
          ...s.pendingSaveData,
          ...(s.localChangedData ? { [id]: s.localChangedData } : undefined),
        },
      };
    });
    return { id };
  }

  private mergePendingSaveBackWithLocalChangedData(id: number) {
    this.firebaseDocumentStore.update((s) => {
      const newPendingSaveData = {
        ...s.pendingSaveData,
      };
      delete newPendingSaveData[id];

      return {
        ...s,
        localChangedData: {
          ...s.pendingSaveData?.[id],
          ...s.localChangedData,
        },
        pendingSaveData: newPendingSaveData,
      };
    });
    return { id };
  }

  private dropPendingSave(id: number) {
    this.firebaseDocumentStore.update((s) => {
      const newPendingSaveData = {
        ...s.pendingSaveData,
      };
      delete newPendingSaveData[id];
      return {
        ...s,
        pendingSaveData: newPendingSaveData,
      };
    });
    return { id };
  }

  async save(options = { logError: true }) {
    const { refPath, localChangedData } = this.firebaseDocumentStore.getValue();
    this.#logger.debug('save', localChangedData);

    if (!localChangedData) {
      // throw new Error('no changes to update');
      this.#logger.info('no changes to update');
      return;
    }

    const { id: saveId } = this.moveLocalChangedDataToPendingSave();

    try {
      if (!refPath) {
        throw new Error('no refPath set for update');
      }
      if (!localChangedData) {
        // throw new Error('no changes to update');
        this.#logger.info('no changes to update');
        return;
      }

      applyTransaction(() => {
        this.firebaseDocumentStore.update({
          isSaving: true,
        });
        this.firebaseDocumentStore.setLoading(true);
      });
      await this.afs.doc(refPath).update(localChangedData);
      applyTransaction(() => {
        this.firebaseDocumentStore.setError(null);
        this.dropPendingSave(saveId);
      });
    } catch (error) {
      if (options.logError) {
        this.#logger.error('failed to save Document', error);
      }
      applyTransaction(() => {
        this.firebaseDocumentStore.setError({
          updateFailed: true,
        });
        this.mergePendingSaveBackWithLocalChangedData(saveId);
        this.firebaseDocumentStore.setLoading(false);
      });
      throw error;
    }

    applyTransaction(() => {
      this.firebaseDocumentStore.update({
        isSaving: false,
      });
      this.firebaseDocumentStore.setLoading(false);
    });
  }

  reset() {
    return this.firebaseDocumentStore.reset();
  }

  resetWithoutLocalChanges() {
    if (this.firebaseDocumentStore.getHasPendingSaveData()) {
      this.#logger.warn('Reset while having pendingSaveData.');
    }

    applyTransaction(() => {
      const { localChangedData, pendingSaveData } =
        this.firebaseDocumentStore.getValue();

      this.firebaseDocumentStore.reset();
      this.firebaseDocumentStore.update({ localChangedData, pendingSaveData });
    });
  }
}
