import { Component, Inject, Input } from '@angular/core';
import {
  BindingsFrom,
  EASY_AKITA_UI_BINDINGS,
} from '@expresssteuer/state-angular';
import { LegacyAnalyticsService } from '../../../state/analytics/legacy-analytics.service';
import { navigationEAU } from '../navigation.ui-state';

@Component({
  selector: 'app-action-profile',
  templateUrl: './action-profile.component.html',
  styleUrls: ['../navigation-user-actions.scss'],
})
export class ActionProfileComponent {
  @Input()
  userActions?: (
    | {
        label: string;
        routerLink: string;
        badge?: string | number | null;
      }
    | undefined
  )[];

  public hasUserActionsWithBadge(
    actions: (
      | {
          label: string;
          routerLink: string;
          badge?: string | number | null;
        }
      | undefined
    )[]
  ) {
    return actions.some((e) => !!e?.badge);
  }

  constructor(
    private analyticsService: LegacyAnalyticsService,
    @Inject(EASY_AKITA_UI_BINDINGS)
    public bindings: BindingsFrom<typeof navigationEAU>
  ) {}

  routeClickHandler(fullPath: string) {
    this.bindings.showUserDropdown.setValue(false);
    this.analyticsService.sendClickEvent(fullPath);
  }
}
