import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {
  @Input()
  headline?: string;
  @Input()
  content?: string | null;
  @Input()
  loading?: boolean | null;
  @Input()
  noBottomPadding = false;
  @Input()
  proseFormat = true;

  @Output()
  closeClicked = new EventEmitter<void>();
}
