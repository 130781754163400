import { guid, Store, StoreConfigOptions } from '@datorama/akita';
import { DeepPartial } from '@expresssteuer/deep-object-helper';
import {
  assignDotObjectToObject,
  toDotObjectForFirebaseWithoutArrays,
} from '@expresssteuer/firebase-helper';
import {
  mergeDataWithChanges,
  mergePendingSaveData,
  removeFromDotObjectIfAlreadyInObject,
} from './firebase-document.query';

export type FirebaseDocumentState<
  TData,
  TMeta = undefined,
  TError = any,
  TLoader = any
> = {
  data?: TData;
  localChangedData?: Record<string, unknown>;
  pendingSaveData?: Record<number | number, Record<string, unknown>>;
  refPath?: string;
  /**
   * @deprecated
   */
  isSaving: boolean;
  hasOnlineChangedAfterLocalChanged: boolean;
  meta?: TMeta;
  error?: TError | null;
  loaders?: TLoader | null;
};

export class FirebaseDocumentStore<
  TData,
  TMeta = undefined,
  TError = any,
  TLoader = any
> extends Store<FirebaseDocumentState<TData, TMeta, TError, TLoader>> {
  constructor(
    options: Partial<StoreConfigOptions> = {
      name: `firebase-document-${guid()}`,
      resettable: true,
    }
  ) {
    super(
      {
        isSaving: false,
        hasOnlineChangedAfterLocalChanged: false,
      },
      options
    );
  }

  getTemplate(): TData | undefined {
    return undefined;
  }

  /**
   * Update the localChangedData with a generator callback.
   * @param callback called with the currentDataWithChanges; Expects the DeepPartial localChangedData as a return value to update the localChangedData with.
   */
  updateLocalChanges(
    callback: (
      data: TData,
      options?: { onlyRealChanges: boolean }
    ) => DeepPartial<TData>
  ): void;
  /**
   * Update the localChangedData with DeepPartial data
   * @param data to update the localChangedData with
   */
  updateLocalChanges(
    data: DeepPartial<TData>,
    options?: { onlyRealChanges: boolean }
  ): void;
  updateLocalChanges(
    dataOrCallback: DeepPartial<TData> | ((data: TData) => DeepPartial<TData>),
    options?: { onlyRealChanges: boolean }
  ): void {
    const isFunction = (
      value: any
    ): value is (data: TData) => DeepPartial<TData> =>
      value &&
      (Object.prototype.toString.call(value) === '[object Function]' ||
        'function' === typeof value ||
        value instanceof Function);

    if (isFunction(dataOrCallback)) {
      this.updateLocalChangesViaCallback(dataOrCallback, options);
    } else {
      this.updateLocalChangesWithData(dataOrCallback, options);
    }
  }

  private updateLocalChangesViaCallback(
    callback: (data: TData) => DeepPartial<TData>,
    options?: { onlyRealChanges: boolean }
  ) {
    const currentState = this.getCurrentDataWithUnsavedChanges();
    if (!currentState) {
      throw new Error('TODO');
    }
    const newState = callback(currentState);
    if (typeof newState === 'function') {
      throw new Error(
        'updateLocalChanges callback should not return a function but the new Partial localChangedData'
      );
    }
    this.updateLocalChanges(newState, options);
  }

  public updateMeta(meta: Partial<TMeta>) {
    this.update((s) => ({
      ...s,
      meta: {
        ...(s.meta ?? this.getMetaTemplate()),
        ...meta,
      },
    }));
  }

  protected getMetaTemplate(): TMeta {
    throw new Error(
      `getMetaTemplate not implemented for ${this.constructor.name}.`
    );
  }

  private updateLocalChangesWithData(
    data: DeepPartial<TData>,
    options = { onlyRealChanges: true }
  ): void {
    // this.logger.debug('updateLocalChanges', data);

    const proposedChangesAsDotObject = toDotObjectForFirebaseWithoutArrays(
      data as any
    );

    let changesToApply;

    if (options.onlyRealChanges) {
      const currentData = this.getCurrentDataWithUnsavedChanges() as Record<
        string,
        any
      >;
      // remove values that have not changed:
      changesToApply = removeFromDotObjectIfAlreadyInObject(
        proposedChangesAsDotObject,
        currentData
      );
    } else {
      changesToApply = proposedChangesAsDotObject;
    }

    return this.update({
      localChangedData: {
        ...this.getValue().localChangedData,
        ...changesToApply,
      },
    });
  }

  getCurrentDataWithUnsavedChanges() {
    const { data, localChangedData, pendingSaveData } = this.getValue();

    const pendingSaveDataMerged = pendingSaveData
      ? mergePendingSaveData(pendingSaveData)
      : {};

    const baseData: TData | undefined = data ? data : this.getTemplate();

    return mergeDataWithChanges(baseData, {
      ...pendingSaveDataMerged,
      ...localChangedData,
    });
  }

  /**
   * Get the store's localChangedData as a DeepPartial
   */
  getCurrentUnsavedChangesAsDeepPartial(): DeepPartial<TData> | undefined {
    const { localChangedData, pendingSaveData } = this.getValue();
    if (!localChangedData && !pendingSaveData) {
      return undefined;
    }

    const changes = pendingSaveData
      ? Object.keys(pendingSaveData)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .reduce((acc, curr) => {
            return {
              ...acc,
              ...pendingSaveData?.[curr as any],
            };
          }, {})
      : {};

    assignDotObjectToObject(localChangedData ?? {}, changes);
    return changes as DeepPartial<TData>;
  }

  getHasPendingSaveData() {
    const { pendingSaveData } = this.getValue();
    return !!pendingSaveData && Object.keys(pendingSaveData).length > 0;
  }

  /**
   * Update the store's localChangedData by removing the properties for the
   * provided property names from the `localChangedData`.
   * @note currently only supporting first level properties
   */
  deleteLocalChangedDataFor(propertyNamesToRemove: (keyof TData)[]) {
    const currentLocalChangedDataAsDeepPartial =
      this.getCurrentUnsavedChangesAsDeepPartial();

    if (!currentLocalChangedDataAsDeepPartial) {
      return;
    }

    propertyNamesToRemove.forEach((key) => {
      delete currentLocalChangedDataAsDeepPartial[key];
    });

    const keysLeft = Object.keys(currentLocalChangedDataAsDeepPartial);

    const newDotObject =
      keysLeft.length < 1
        ? undefined
        : toDotObjectForFirebaseWithoutArrays(
            currentLocalChangedDataAsDeepPartial as any
          );

    this.update((state) => {
      return {
        ...state,
        localChangedData: newDotObject,
      };
    });
  }
}
