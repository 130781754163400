<fieldset
  class="fieldset"
  [ngClass]="{
    'fieldset--stripe': type === 'stripe',
    'fieldset--stripe--error':
      isInvalid && (isTouched || showErrorsDespitePristine)
  }"
>
  <legend class="legend" *ngIf="legend">{{ legend }}</legend>
  <div *ngFor="let item of items" class="item">
    <input
      class="input peer"
      [ngClass]="{
        'input--stripe': type === 'stripe',
        'input--error': isInvalid && (isTouched || showErrorsDespitePristine)
      }"
      type="radio"
      [id]="name + '.' + item.value"
      [name]="name"
      [value]="item.value"
      [(ngModel)]="value"
      (ngModelChange)="onChange($event)"
      (click)="onClick()"
    />
    <label
      class="label"
      [ngClass]="{ 'label--stripe': type === 'stripe' }"
      [for]="name + '.' + item.value"
      >{{ item.label }}</label
    >
  </div>
</fieldset>
<div
  *ngIf="isInvalid && errorText && (isTouched || showErrorsDespitePristine)"
  class="error-text"
>
  {{ errorText }}
</div>
