import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface HotjarState {
  hotjarLoaded: boolean;
}

const initialState: () => HotjarState = () => ({ hotjarLoaded: false });

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'hotjar', resettable: true })
export class HotjarStore extends Store<HotjarState> {
  constructor() {
    super(initialState());
  }
}
