import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  InjectFlags,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validator,
} from '@angular/forms';
import { DateString } from '@expresssteuer/models';
import { Subscription, filter, tap } from 'rxjs';
import { UnkownFunction } from '../helper/helper';

export interface DateFieldErrors {
  incorrectDate: boolean;
}

interface DateFields {
  day: string;
  month: string;
  year: string;
}

@Component({
  selector: 'ed-date-separate',
  templateUrl: './ed-date-separate.component.html',
  styleUrls: ['./ed-date-separate.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EdDateSeparateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EdDateSeparateComponent,
      multi: true,
    },
  ],
})
export class EdDateSeparateComponent
  implements ControlValueAccessor, OnInit, OnDestroy, Validator, AfterViewInit
{
  private static nextUniqueId = 0;

  subs: Subscription[] = [];

  dayName = `ed-date-day-${EdDateSeparateComponent.nextUniqueId++}`;
  monthName = `ed-date-month-${EdDateSeparateComponent.nextUniqueId++}`;
  yearName = `ed-date-year-${EdDateSeparateComponent.nextUniqueId++}`;

  @Input() dayPlaceholder?: string;
  @Input() monthPlaceholder?: string;
  @Input() yearPlaceholder?: string;

  dayControl = new UntypedFormControl();
  monthControl = new UntypedFormControl();
  yearControl = new UntypedFormControl();

  @ViewChild('day') dayElement?: ElementRef;
  @ViewChild('month') monthElement?: ElementRef;
  @ViewChild('year') yearElement?: ElementRef;

  @Input()
  type: 'code' | 'form' = 'form';

  @Input()
  get autofocus(): boolean {
    return this._autofocus;
  }
  set autofocus(value: BooleanInput) {
    this._autofocus = coerceBooleanProperty(value);
  }
  private _autofocus = false;

  @Input()
  label = '';

  @Input()
  get showErrorsDespitePristine() {
    return this._showErrorsDespitePristine;
  }
  set showErrorsDespitePristine(value: BooleanInput) {
    this._showErrorsDespitePristine = coerceBooleanProperty(value);
  }
  private _showErrorsDespitePristine = false;

  @Input()
  get validityRequired(): boolean {
    return this._validityRequired;
  }
  set validityRequired(value: BooleanInput) {
    this._validityRequired = coerceBooleanProperty(value);
  }
  private _validityRequired = true;

  @Input()
  errorText?: string | null;

  @Input()
  renderErrorContent?: boolean;

  @Input()
  incorrectDateTranslation?: string;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  isBlurred = false;

  public ngControl?: NgControl | null;
  get isInvalid() {
    return this.ngControl?.invalid;
  }
  get isTouched() {
    return this.ngControl?.touched;
  }
  get isDirty() {
    return this.ngControl?.dirty;
  }
  get isValid() {
    return this.ngControl?.valid;
  }
  get isUntouched() {
    return this.ngControl?.untouched;
  }
  get isPristine() {
    return this.ngControl?.pristine;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.dayControl.disable();
      this.monthControl.disable();
      this.yearControl.disable();
    } else {
      this.dayControl.enable();
      this.monthControl.enable();
      this.yearControl.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: DateString | null) => {};
  registerOnChange(fn: UnkownFunction) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};
  registerOnTouched(fn: UnkownFunction) {
    this.onTouch = fn;
  }

  writeValue(dateString: DateString) {
    const { day, month, year } = this.convertDateStringToDateForm(dateString);
    // do not emit events when state is written from outside (e.g. initially), avoids re-emit and wrong focus
    this.dayControl.setValue(day, { emitEvent: false });
    this.monthControl.setValue(month, { emitEvent: false });
    this.yearControl.setValue(year, { emitEvent: false });
  }

  inputChanged() {
    const day = this.dayControl.value;
    const month = this.monthControl.value;
    const year = this.yearControl.value;
    if (!day && !month && !year) {
      this.onChange(null);
      return;
    }
    const dateString = this.parseDateString({ day, month, year });
    this.onChange(dateString);
  }

  validate(): Partial<DateFieldErrors> | null {
    if (
      this.dayControl.invalid ||
      this.monthControl.invalid ||
      this.yearControl.invalid
    ) {
      return {
        incorrectDate: true,
      };
    } else {
      return null;
    }
  }

  onBlur() {
    if (
      this.dayControl.touched &&
      this.monthControl.touched &&
      this.yearControl.touched
    ) {
      this.onTouch();
    }
  }

  parseDateString({ day, month, year }: DateFields): DateString {
    return `${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(
      2,
      '0'
    )}` as DateString;
  }

  // {seconds: , nanoseconds: } -> 2022-05-22
  private convertDateStringToDateForm(dateString: DateString): DateFields {
    if (!dateString) {
      return {
        day: '',
        month: '',
        year: '',
      };
    }
    const splittedDate = dateString.split('-');
    return {
      day: splittedDate[2],
      month: splittedDate[1],
      year: splittedDate[0],
    };
  }

  constructor(
    private injector: Injector,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl, null, InjectFlags.Optional);
    this.subs.push(
      this.dayControl.valueChanges
        .pipe(
          tap(() => this.inputChanged()),
          filter((value: string) => {
            return value.length === 2 || parseInt(value) > 3; // there is no day greater then 31; first letter 3
          })
        )
        .subscribe(() => this.monthElement?.nativeElement.focus()),
      this.monthControl.valueChanges
        .pipe(
          tap(() => this.inputChanged()),
          filter((value: string) => {
            return value.length === 2 || parseInt(value) > 1; // there is no month greater than 12; first letter 1
          })
        )
        .subscribe(() => this.yearElement?.nativeElement.focus()),
      this.yearControl.valueChanges
        .pipe(tap(() => this.inputChanged()))
        .subscribe()
    );
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.dayElement?.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
