import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule],
})
export class IconModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry
      .addSvgIcon(`phone-outline`, this.getTrustedIconPath(`phone-outline.svg`))
      .addSvgIcon(
        `translate-outline`,
        this.getTrustedIconPath(`translate-outline.svg`)
      )
      .addSvgIcon(
        `user-circle-outline`,
        this.getTrustedIconPath(`user-circle-outline.svg`)
      )
      .addSvgIcon(
        `logout-outline`,
        this.getTrustedIconPath(`logout-outline.svg`)
      )
      .addSvgIcon(`user-solid`, this.getTrustedIconPath(`user-solid.svg`))
      .addSvgIcon(`menu-outline`, this.getTrustedIconPath(`menu-outline.svg`))
      .addSvgIcon(`x-outline`, this.getTrustedIconPath(`x-outline.svg`))
      .addSvgIcon(`loading`, this.getTrustedIconPath(`loading.svg`))
      .addSvgIcon(
        `loading-spinner`,
        this.getTrustedIconPath(`loading-spinner.svg`)
      )
      .addSvgIcon(
        `check-circle-outline`,
        this.getTrustedIconPath(`check-circle-outline.svg`)
      )
      .addSvgIcon(`check-badge`, this.getTrustedIconPath(`check-badge.svg`))
      .addSvgIcon(
        `x-circle-outline`,
        this.getTrustedIconPath(`x-circle-outline.svg`)
      )
      .addSvgIcon(`questionmark`, this.getTrustedIconPath(`questionmark.svg`))
      .addSvgIcon(
        `question-mark-circle`,
        this.getTrustedIconPath(`question-mark-circle.svg`)
      )
      .addSvgIcon(`alert`, this.getTrustedIconPath(`alert.svg`))
      .addSvgIcon(`arrow-right`, this.getTrustedIconPath(`arrow-right.svg`))
      .addSvgIcon(`arrow-right-1`, this.getTrustedIconPath(`arrow-right-1.svg`))
      .addSvgIcon(`chevron-left`, this.getTrustedIconPath(`chevron-left.svg`))
      .addSvgIcon(`hashtag`, this.getTrustedIconPath(`hashtag.svg`))
      .addSvgIcon(`bank`, this.getTrustedIconPath(`bank.svg`))
      .addSvgIcon(
        `identification`,
        this.getTrustedIconPath(`identification.svg`)
      )
      .addSvgIcon(`info`, this.getTrustedIconPath(`info.svg`))
      .addSvgIcon(`waterfalls`, this.getTrustedIconPath(`waterfalls.svg`))
      .addSvgIcon(`caution`, this.getTrustedIconPath(`caution.svg`))
      .addSvgIcon(`palace`, this.getTrustedIconPath(`palace.svg`))
      .addSvgIcon(`edit-one`, this.getTrustedIconPath(`edit-one.svg`))
      .addSvgIcon(`find`, this.getTrustedIconPath(`find.svg`))
      .addSvgIcon(`mail`, this.getTrustedIconPath(`mail.svg`))
      .addSvgIcon(`abnormal`, this.getTrustedIconPath(`abnormal.svg`))
      .addSvgIcon(`send`, this.getTrustedIconPath(`send.svg`))
      .addSvgIcon(`cog`, this.getTrustedIconPath(`cog.svg`))
      .addSvgIcon(`doc-success`, this.getTrustedIconPath(`doc-success.svg`))
      .addSvgIcon(`file-upload`, this.getTrustedIconPath(`file-upload.svg`))
      .addSvgIcon(`doc`, this.getTrustedIconPath(`doc.svg`))
      .addSvgIcon(`close-one`, this.getTrustedIconPath(`close-one.svg`))
      .addSvgIcon(`chevron-right`, this.getTrustedIconPath(`chevron-right.svg`))
      .addSvgIcon(`chevron-up`, this.getTrustedIconPath(`chevron-up.svg`))
      .addSvgIcon(`chevron-down`, this.getTrustedIconPath(`chevron-down.svg`))
      .addSvgIcon(`arrow-right-1`, this.getTrustedIconPath(`arrow-right-1.svg`))
      .addSvgIcon(`chevron-left`, this.getTrustedIconPath(`chevron-left.svg`))
      .addSvgIcon(`hashtag`, this.getTrustedIconPath(`hashtag.svg`))
      .addSvgIcon(`bank`, this.getTrustedIconPath(`bank.svg`))
      .addSvgIcon(
        `identification`,
        this.getTrustedIconPath(`identification.svg`)
      )
      .addSvgIcon(
        `magnifying-glass`,
        this.getTrustedIconPath(`magnifying-glass.svg`)
      )
      .addSvgIcon(`arrow-up-tray`, this.getTrustedIconPath(`arrow-up-tray.svg`))
      .addSvgIcon(`calculator`, this.getTrustedIconPath(`calculator.svg`))
      .addSvgIcon(`currency-euro`, this.getTrustedIconPath(`currency-euro.svg`))
      .addSvgIcon(`hand-thumb-up`, this.getTrustedIconPath(`hand-thumb-up.svg`))
      .addSvgIcon(`clock`, this.getTrustedIconPath(`clock.svg`))
      .addSvgIcon(`fire`, this.getTrustedIconPath(`fire.svg`))
      .addSvgIcon(`info`, this.getTrustedIconPath(`info.svg`));
  }
  private getTrustedIconPath(iconFileName: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      '/assets/images/icon/' + iconFileName
    );
  }
}
