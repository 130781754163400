import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface VerificationState {
  requestSucceeded: boolean;
  requestFailed: boolean;
  restartSucceeded: boolean;
  restartFailed: boolean;
  noCodeProvided: boolean;
}

function createInitialState(): VerificationState {
  return {
    requestSucceeded: false,
    requestFailed: false,
    restartFailed: false,
    restartSucceeded: false,
    noCodeProvided: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'verification', resettable: true })
export class VerificationStore extends Store<VerificationState> {
  constructor() {
    super(createInitialState());
  }
}
