<div class="mobile-menu-button">
  <!-- Mobile menu button -->
  <button
    type="button"
    class="mobile-menu-button__button"
    (click)="clicked.next()"
  >
    <span class="show-sr-only">Open main menu</span>
    <mat-icon
      *ngIf="!showMobileMenu"
      svgIcon="menu-outline"
      class="mobile-menu-button__icon"
    ></mat-icon>
    <mat-icon
      *ngIf="showMobileMenu"
      svgIcon="x-outline"
      class="mobile-menu-button__icon"
    ></mat-icon>
  </button>

  <span *ngIf="showBadge && !showMobileMenu" class="mobile-menu-button__badge">
    {{ badgeContent ?? '' }}
  </span>
</div>
