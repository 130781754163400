import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UnkownFunction } from '../helper/helper';

@Component({
  selector: 'ed-slider',
  templateUrl: './ed-slider.component.html',
  styleUrls: ['./ed-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EdSliderComponent),
      multi: true,
    },
  ],
})
export class EdSliderComponent implements ControlValueAccessor {
  @Input()
  min = 1;

  @Input()
  max = 100;

  @Output()
  release = new EventEmitter<number>();

  value = 0;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: number) => {};
  registerOnChange(fn: UnkownFunction) {
    this.onChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};
  registerOnTouched(fn: UnkownFunction) {
    this.onTouch = fn;
  }

  writeValue(value: number) {
    this.value = value;
  }

  onRelease() {
    this.onTouch();
    this.release.emit(this.value);
  }
}
