import { Component } from '@angular/core';
import { FeatureTogglesQuery } from '@expresssteuer/state-angular';
import { LegalDocumentsQuery } from '../../state/legal-documents/legal-documents.query';
import { LegalOverlaysQuery } from '../../state/legal-overlays/legal-overlays.query';
import { LegalOverlaysService } from '../../state/legal-overlays/legal-overlays.service';
import { SignableContractQuery } from '../../state/signable-contract/signable-contract.query';

@Component({
  selector: 'app-legal-overlays',
  templateUrl: './legal-overlays.component.html',
  styleUrls: ['./legal-overlays.component.scss'],
})
export class LegalOverlaysComponent {
  constructor(
    public legalDocumentsQuery: LegalDocumentsQuery,
    public legalOverlaysQuery: LegalOverlaysQuery,
    public legalOverlaysService: LegalOverlaysService,
    public signableContractQuery: SignableContractQuery,
    public featureTogglesQuery: FeatureTogglesQuery
  ) {}
}
