import { MARITALSTATUS } from '@expresssteuer/models';
import { literal, nativeEnum, number, object } from 'zod';

export const EstimateTaxReturnInputV1Zod = object({
  version: literal('1'),
  taxYear: number(),
  maritalStatus: nativeEnum(MARITALSTATUS),
  monthlySalary: number(),
  workingMonths: number().lte(12),

  kilometersToWork: number().optional(),
  offsiteDays: number().optional(),
});
