import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { EdButtonModule } from '@expresssteuer/design-system';
import { PureMethodModule } from '@expresssteuer/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { UserButtonModule } from '../auth/user-button/user-button.module';
import { PopupModule } from '../components/popup/popup.module';
import { ActionHotlineComponent } from './action-hotline/action-hotline.component';
import { ActionLoginComponent } from './action-login/action-login.component';
import { ActionProfileComponent } from './action-profile/action-profile.component';
import { ActionTranslationComponent } from './action-translation/action-translation.component';
import { MobileMenuButtonComponent } from './mobile-menu-button/mobile-menu-button.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { NavigationComponent } from './navigation.component';
import { navigationEAU } from './navigation.ui-state';

@NgModule({
  declarations: [
    NavigationComponent,
    MobileMenuComponent,
    ActionTranslationComponent,
    ActionHotlineComponent,
    ActionProfileComponent,
    ActionLoginComponent,
    MobileMenuButtonComponent,
  ],
  imports: [
    CommonModule,
    UserButtonModule,
    TranslateModule,
    RouterModule,
    EdButtonModule,
    MatIconModule,
    PopupModule,
    PureMethodModule,
  ],
  exports: [NavigationComponent],
  providers: [navigationEAU.providers],
})
export class NavigationModule {}
