import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { map, shareReplay } from 'rxjs';
import { MarketingParamsService } from './marketing-params.service';

@Injectable({ providedIn: 'root' })
export class MarketingParamsQuery {
  utmParams$ = this.activatedRoute.queryParams.pipe(
    map((params) => this.paramsService.parseUtmParams(params)),
    shareReplay()
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private paramsService: MarketingParamsService
  ) {}

  syncUtmParams() {
    return this.utmParams$;
  }
}
