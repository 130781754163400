<div class="code-input">
  <ed-textfield
    #textfieldReferences
    *ngFor="let code of codes; let i = index; trackBy: trackByIndex"
    [autocomplete]="i === 0 ? 'one-time-code' : uniqueNameProperty + '-' + i"
    [name]="uniqueNameProperty + '-' + i"
    class="code-input__field"
    [ngModel]="code"
    [maxlength]="1"
    [inputmode]="'numeric'"
    [disabled]="disabled ?? false"
    [required]="true"
    [showErrorsDespitePristine]="!!errorText"
    (ngModelChange)="onFieldChange($event, i)"
    (keyup)="onFieldKeyUp($event, i)"
    (paste)="onPaste($event)"
  ></ed-textfield>
</div>
<div *ngIf="errorText" class="error-text">
  {{ errorText }}
</div>
