import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LegalDocumentsQuery {
  constructor(private http: HttpClient) {}

  private _conditionsDocument$?: Observable<string>;
  get conditionsDocument$() {
    if (!this._conditionsDocument$) {
      this._conditionsDocument$ = this.getLegalDocument('conditions.html');
    }
    return this._conditionsDocument$;
  }

  private _imprintDocument$?: Observable<string>;
  get imprintDocument$() {
    if (!this._imprintDocument$) {
      this._imprintDocument$ = this.getLegalDocument('imprint.html');
    }
    return this._imprintDocument$;
  }

  private _dataProtectionDocument$?: Observable<string>;
  get dataProtectionDocument$() {
    if (!this._dataProtectionDocument$) {
      this._dataProtectionDocument$ = this.getLegalDocument(
        'data-protection.html'
      );
    }
    return this._dataProtectionDocument$;
  }

  private _dataProcessingDocument$?: Observable<string>;
  get dataProcessingDocument$() {
    if (!this._dataProcessingDocument$) {
      this._dataProcessingDocument$ = this.getLegalDocument(
        'data-processing.html'
      );
    }
    return this._dataProcessingDocument$;
  }

  private _referralDocument$?: Observable<string>;
  get referralDocument$() {
    if (!this._referralDocument$) {
      this._referralDocument$ = this.getLegalDocument('referral.html');
    }
    return this._referralDocument$;
  }

  private getLegalDocument(documentFileName: string) {
    return this.http
      .get('assets/legal/' + documentFileName, {
        responseType: 'text',
      })
      .pipe(shareReplay(1));
  }
}
