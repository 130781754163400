<!-- Component Styling is a duplication of ed-textfield until the new version is implemented -->
<div
  class="textfield"
  [ngClass]="{
    'textfield--error': isInvalid && (isTouched || showErrorsDespitePristine),
    'textfield--disabled': disabled
  }"
>
  <label *ngIf="label" class="label" [for]="name">{{ label }}</label>
  <input
    type="date"
    class="input"
    [id]="name"
    [name]="name"
    [min]="min ?? type === 'birthdate' ? DEFAULT_BIRTHDAY_MIN_VALUE : null"
    [max]="
      max ?? type === 'birthdate'
        ? DEFAULT_BIRTHDAY_MAX_VALUE
        : DEFAULT_MAX_VALUE
    "
    [placeholder]="placeholder"
    [ngModel]="value"
    (ngModelChange)="inputChanged($event)"
    (blur)="blurTriggered()"
    [disabled]="disabled"
  />
</div>
<ng-container *ngIf="isInvalid && (isTouched || showErrorsDespitePristine)">
  <div *ngIf="errorText" class="error-text">
    {{ errorText }}
  </div>
  <div *ngIf="renderErrorContent" class="error-text">
    <ng-content select="[errorContent]"></ng-content>
  </div>
</ng-container>
