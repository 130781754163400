import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface GoogleMapsApiState {
  googleMapsApiLoaded: boolean;
  requestStarted: boolean;
}

const initialState: () => GoogleMapsApiState = () => ({
  googleMapsApiLoaded: false,
  requestStarted: false,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'google-maps-api', resettable: false })
export class GoogleMapsApiStore extends Store<GoogleMapsApiState> {
  constructor() {
    super(initialState());
  }
}
