import { DateString } from '@expresssteuer/models';

export interface AuthenticateByDataInput {
  /**
   * The birthdate the user has to type in in the form. Never provide a user all the information
   */
  birthdate: DateString;
  /**
   * encrypted client data
   */
  encryptedData: string;
}

export abstract class AuthenticateByDataInput {
  public static guard(input: unknown): input is AuthenticateByDataInput {
    if (typeof input !== 'object' || input === null) {
      return false;
    }
    const { birthdate, encryptedData } = input as AuthenticateByDataInput;
    if (isNaN(+new Date(birthdate))) {
      return false;
    }
    return typeof birthdate === 'string' && typeof encryptedData === 'string';
  }
}

export interface AuthenticateByDataOutput {
  /**
   * If the authentication was successfull, the token to be used to authenticate the client
   */
  token: string | null;
}
