import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { shareReplay, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthQuery } from '../auth/auth.query';

@Injectable({ providedIn: 'root' })
export class SentryService {
  constructor(private authQuery: AuthQuery) {}

  #syncUser$ = this.authQuery.user$.pipe(
    tap((user) => {
      // don't check for user.isAnonymous because anonymous users should also be identified
      if (user) {
        this.setIdentifier(user.uid);
      }
    }),
    shareReplay(1)
  );

  syncUser() {
    return this.#syncUser$;
  }

  setIdentifier(identifier: string) {
    if (environment.toggles.useSentry) {
      Sentry.setUser({ id: identifier });
    }
  }
}
