import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ed-button-group-item]',
  templateUrl: './ed-button-group-item.component.html',
  styleUrls: ['./ed-button-group-item.component.scss'],
})
export class EdButtonGroupItemComponent {
  @Input() active?: boolean;

  @HostBinding('class')
  get classes() {
    return {
      button: true,
      'button--active': !!this.active,
    };
  }
}
