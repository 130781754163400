import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { MarketingIds } from '@expresssteuer/tracking-types';

export enum MarketingIdKeys {
  VOLUUM = 'sub2',
  AWIN = 'awc',
}

export const utmKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
] as const;
export type UtmKeys = typeof utmKeys[number];
export type UtmParams = {
  [key in UtmKeys]: string;
};

@Injectable({ providedIn: 'root' })
export class MarketingParamsService {
  parseMarketingIds(params: Record<string, unknown>): MarketingIds {
    const marketingIds: MarketingIds = {};

    if (params[MarketingIdKeys.VOLUUM]) {
      marketingIds.voluumId = params[MarketingIdKeys.VOLUUM] as string;
    }
    if (params[MarketingIdKeys.AWIN]) {
      marketingIds.awinId = params[MarketingIdKeys.AWIN] as string;
    }

    return marketingIds;
  }

  parseUtmParams(params: Params): Partial<UtmParams> {
    return Object.entries(params)
      .filter(([key]) => utmKeys.includes(key as UtmKeys))
      .reduce(
        (utmParams, [key, value]) => ({ ...utmParams, [key]: value }),
        {}
      );
  }
}
