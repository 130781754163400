<nav
  class="nav"
  *ngIf="{
    userName: userQuery.firstname$ | async,
    userEmail: userQuery.firebaseEmail$ | async,
    menuItems: [
      {
        label: 'navigation.howItWorks' | translate,
        href: 'https://www.expresssteuer.de/de/so-funktioniert-expresssteuer/'
      },
      {
        label: 'navigation.benefit' | translate,
        href: 'https://www.expresssteuer.de/de/expresssteuer/warum-expresssteuer/'
      },
      {
        label: 'navigation.faq' | translate,
        href: 'https://www.expresssteuer.de/de/hilfe/'
      }
    ],
    userActions: [
      {
        label: 'profile.overview' | translate,
        routerLink: myAccountRoutes.overview.fullPath,
        badge:
          clientTaxCasesQuery.taxCasesWaitingForClientApprovalAndClientEditsCount$
          | async
      },
      {
        label: 'profile.friendsInvite' | translate,
        routerLink: myAccountRoutes.friendsinvite.fullPath
      },
      {
        label: 'profile.profile' | translate,
        routerLink: myAccountRoutes.profile.fullPath
      },
      (featureTogglesQuery.enableWonderland$ | async)
        ? {
            label: 'profile.todos' | translate,
            routerLink: myAccountRoutes.wonderland.fullPath,
            badge: todosQuery.openTodosCount$ | async
          }
        : {
            label: 'profile.documents' | translate,
            routerLink: myAccountRoutes.documents.fullPath
          }
    ],
    badgeTotal: todosQuery.openTodosCount$ | async,
    isLoggedIn: !!(isLoggedIn$ | async),
    showMobileMenu: bindings.showMobileMenu.value$ | async
  } as data"
>
  <div class="nav__content-wrapper">
    <div class="nav__content">
      <div class="menu">
        <a
          class="menu__logo-link"
          routerLink="/"
          (click)="bindings.showMobileMenu.setValue(false)"
        >
          <img
            class="menu__logo"
            src="assets/images/logo.png"
            alt="ExpressSteuer Logo"
          />
        </a>
        <div class="menu__desktop-item-list">
          <!-- <a href="#" class="menu-item menu-item&#45;&#45;active"> Start </a> -->
          <a
            *ngFor="let item of data.menuItems"
            [href]="item.href"
            class="menu__item"
            target="_blank"
            rel="noopener"
          >
            {{ item.label }}
          </a>
        </div>
      </div>
      <div class="menu__user-actions">
        <app-action-hotline
          *ngIf="featureTogglesQuery.displayCallNavButton$ | async"
        ></app-action-hotline>
        <app-action-translation></app-action-translation>
        <app-action-login *ngIf="!data.isLoggedIn"></app-action-login>
        <app-action-profile
          *ngIf="data.isLoggedIn"
          [userActions]="data.userActions"
        ></app-action-profile>
      </div>
      <app-mobile-menu-button
        class="mobile-menu-button"
        (click)="bindings.showMobileMenu.setValue(!data.showMobileMenu)"
        [showMobileMenu]="data.showMobileMenu"
        [badgeContent]="data.badgeTotal"
        [showBadge]="(data.badgeTotal ?? 0) > 0"
      ></app-mobile-menu-button>
    </div>
  </div>

  <app-mobile-menu
    *ngIf="data.showMobileMenu"
    [userName]="data.userName"
    [userEmail]="data.userEmail"
    [userActions]="data.userActions"
    [menuItems]="data.menuItems"
    [isLoggedIn]="data.isLoggedIn"
    (itemClicked)="bindings.showMobileMenu.setValue(false)"
    class="mobile-menu"
  ></app-mobile-menu>
</nav>
