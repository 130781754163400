import { Injectable } from '@angular/core';
import * as braze from '@braze/web-sdk';
import { CreateJwtForBrazeClientSdkAuth } from '@expresssteuer/braze-api-angular';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import isEqual from 'lodash/isEqual';
import { combineLatest, firstValueFrom, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthQuery } from '../auth/auth.query';
import { CookiebotQuery } from '../cookiebot/cookiebot.query';
import { BrazeQuery } from './braze.query';
import { BrazeStore } from './braze.store';

@Injectable({ providedIn: 'root' })
export class BrazeService {
  private logger: EsuiLoggerService;

  #waitForCookiebotResponse$ = this.cookiebotQuery.hasResponse$.pipe(
    filter((hasResponse) => hasResponse),
    take(1)
  );

  #activateOrDeactivateWhenReady$ = combineLatest({
    isStatisticsAllowed: this.cookiebotQuery.isStatisticsAllowed$,
    waitForCookiebotResponse: this.#waitForCookiebotResponse$,
    isLoggedIn: this.authQuery.isLoggedIn$,
  }).pipe(
    distinctUntilChanged(isEqual),
    switchMap(({ isStatisticsAllowed, isLoggedIn }) =>
      isLoggedIn === true
        ? this.initialize({ noCookies: isStatisticsAllowed !== true })
        : this.destroy()
    )
  );

  #syncUser$ = combineLatest({
    user: this.authQuery.user$,
    untilIsInitialized: this.query.untilIsInitialized$,
    isInitialized: this.query.isInitialized$,
  }).pipe(
    distinctUntilChanged(isEqual),
    tap(({ user, isInitialized }) => {
      if (user?.uid != null && isInitialized === true) {
        void this.setExternalId(user.uid);
      }
    }),
    shareReplay(1)
  );

  constructor(
    logger: EsuiLoggerService,
    private store: BrazeStore,
    private query: BrazeQuery,
    private cookiebotQuery: CookiebotQuery,
    private authQuery: AuthQuery,
    private createJwtForBrazeClientSdkAuth: CreateJwtForBrazeClientSdkAuth
  ) {
    this.logger = logger.getNewInstance(this);
  }

  sync() {
    return environment.toggles.useBraze
      ? combineLatest([this.#activateOrDeactivateWhenReady$, this.#syncUser$])
      : of();
  }

  private async initialize(options?: { noCookies?: boolean }) {
    if ((await firstValueFrom(this.query.isInitialized$)) === true) {
      this.logger.info('braze.alreadyInitialized.so.do.nothing');
      return;
    }
    if (this.store.getValue().isInitializing === true) {
      this.logger.warn('braze.alreadyInitializing.so.do.nothing');
      return;
    }
    this.store.update((state) => ({ ...state, isInitializing: true }));

    try {
      this.logger.info('braze.initialize');
      const initializationSuccess = braze.initialize(environment.braze.apiKey, {
        baseUrl: environment.braze.endpoint,
        enableLogging: true,
        enableSdkAuthentication: true,
        minimumIntervalBetweenTriggerActionsInSeconds: 5,
        serviceWorkerLocation: '/sw-braze.js',
        noCookies: options?.noCookies ?? false,
        allowUserSuppliedJavascript: true,
      });
      if (initializationSuccess !== true) {
        this.logger.error('braze.initialization.failed');
        return;
      }

      braze.setLogger((message) => this.logger.info(message));

      this.registerServiceWorker();

      this.logger.info('braze.automaticallyShowInAppMessages');
      braze.automaticallyShowInAppMessages();

      this.logger.info('braze.openSession');
      braze.openSession();

      this.store.update({ isInitialized: true });
    } catch (err) {
      this.logger.error('braze.initialization.failed', { options, err });
    } finally {
      this.store.update((state) => ({ ...state, isInitializing: false }));
    }
  }

  private async destroy() {
    if ((await firstValueFrom(this.query.isInitialized$)) === false) {
      this.logger.info('braze.alreadyDestroyed.so.do.nothing');
      return;
    }
    this.logger.info('braze.destroy');
    braze.destroy();
    this.store.update({ isInitialized: false });
  }

  public handleRegisterPushClick = async () => {
    braze.requestPushPermission(
      (endpoint, publicKey, userAuth) => {
        this.logger.info('requestPushPermission.success', {
          endpoint,
          publicKey,
          userAuth,
        });
      },
      (temporaryDenial) => {
        this.logger.warn('requestPushPermission.failed', { temporaryDenial });
      }
    );
  };

  private async setExternalId(clientId: string) {
    const jwt = await firstValueFrom(
      this.createJwtForBrazeClientSdkAuth.call({ empty: true })
    );
    if (jwt == null) {
      this.logger.error('braze.jwt.token.is.null', { clientId, jwt });
    } else {
      this.logger.info('braze.jwt.loaded', { clientId, jwt });
    }
    this.logger.info('braze.changeUser', { clientId });
    braze.changeUser(clientId, jwt ?? undefined);
  }

  private registerServiceWorker() {
    if (environment.toggles.useBraze !== true) {
      this.logger.warn(
        'skip registration of service worker because braze is toggled off'
      );
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    if ('serviceWorker' in window.navigator) {
      // Register a service worker hosted at the root of the
      // site using the default scope.
      // eslint-disable-next-line no-restricted-globals
      navigator.serviceWorker.register('/sw-braze.js').then(
        (registration) => {
          this.logger.info(
            'Service worker registration succeeded:',
            registration
          );
        },
        (error) => {
          this.logger.error(`Service worker registration failed: ${error}`);
        }
      );
    } else {
      this.logger.error('Service workers are not supported.');
    }
  }
}
