import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ed-alert',
  templateUrl: './ed-alert.component.html',
  styleUrls: ['./ed-alert.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EdAlertComponent),
      multi: true,
    },
  ],
})
export class EdAlertComponent {
  @Input() title?: string;
  @Input() text?: string;
  @Input() primaryLabel?: string;
  @Input() secondaryLabel?: string;
  @Input() icon = 'alert';
  @Input() type: 'primary' | 'warning' | 'error' = 'primary';
  @Input() shadow = false;

  @Output() primaryClicked = new EventEmitter<void>();
  @Output() secondaryClicked = new EventEmitter<void>();
}
