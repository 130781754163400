import { Component } from '@angular/core';
import { buildMeta } from '../../../../build-meta/build-meta';
import {
  CallFabButtonEventName,
  StandardEvent,
} from '../../../state/analytics/event-types/standard.event';
import { MixpanelService } from '../../../state/analytics/mixpanel.service';
import { CallFabQuery } from '../../../state/call-fab/call-fab.query';
import { CallFabService } from '../../../state/call-fab/call-fab.service';

@Component({
  selector: 'app-call-fab',
  templateUrl: './call-fab.component.html',
  styleUrls: ['./call-fab.component.scss'],
})
export class CallFabComponent {
  constructor(
    private mixpanel: MixpanelService,
    public callFabQuery: CallFabQuery,
    public callFabService: CallFabService
  ) {}

  handleOpenFabButtonClick() {
    this.mixpanel.sendEvent(
      new StandardEvent(CallFabButtonEventName.OpenedOnMobile, {
        buildHash: buildMeta.commitHash,
      })
    );
    this.callFabService.setOpenState(true);
  }

  handleCallClick() {
    this.mixpanel.sendEvent(
      new StandardEvent(CallFabButtonEventName.CallClicked, {
        buildHash: buildMeta.commitHash,
      })
    );
  }
}
