import { AuthErrors } from '../../state/auth/auth.store';

//ref: node_modules/firebase/index.d.ts firebase.auth Auth
export function convertFirebaseAuthCode(code: string): keyof typeof AuthErrors {
  switch (code) {
    // User error
    case 'auth/email-already-in-use':
    case 'auth/account-exists-with-different-credential':
      return 'emailAlreadyInUse';

    case 'auth/invalid-email':
      return 'invalidEmail';
    case 'auth/invalid-phone-number':
      return 'invalidPhoneNumber';
    case 'auth/invalid-verification-code':
      return 'invalidVerificationCode';
    case 'auth/missing-phone-number':
      return 'missingPhoneNumber';
    case 'auth/unverified-email':
      return 'unverifiedEmail';

    case 'auth/wrong-password':
      return 'wrongPassword';
    case 'auth/weak-password':
      return 'weakPassword';

    case 'auth/credential-already-in-use':
      return 'socialProviderAlreadyInUse';
    case 'auth/popup-closed-by-user':
    case 'auth/user-cancelled':
      return 'socialProviderGeneric';

    case 'auth/user-disabled':
    case 'auth/user-mismatch':
    case 'auth/user-not-found':
      return 'unableToFindUser';

    case 'auth/code-expired':
    case 'auth/invalid-action-code':
    case 'auth/expired-action-code':
    case 'auth/user-token-expired':
      return 'expired';

    default:
      return 'authInternal';
    // Other errors
    // case "auth/argument-error":
    // case "auth/cancelled-popup-request":
    // case "auth/custom-token-mismatch":
    // case "auth/captcha-check-failed":
    // case "auth/app-deleted":
    // case "auth/app-not-authorized":
    // case "auth/auth-domain-config-required":
    // case "auth/invalid-api-key":
    // case "auth/invalid-continue-uri":
    // case "auth/invalid-credential":
    // case "auth/invalid-custom-token":
    // case "auth/web-storage-unsupported":
    // case "auth/invalid-multi-factor-session":
    // case "auth/invalid-persistence-type":
    // case "auth/invalid-tenant-id":
    // case "auth/invalid-user-token":
    // case "auth/invalid-verification-id":
    // case "auth/maximum-second-factor-count-exceeded":
    // case "auth/missing-android-pkg-name":
    // case "auth/missing-continue-uri":
    // case "auth/missing-ios-bundle-id":
    // case "auth/missing-multi-factor-session":
    // case "auth/missing-verification-code":
    // case "auth/missing-verification-id":
    // case "auth/multi-factor-auth-required":
    // case "auth/multi-factor-info-not-found":
    // case "auth/network-request-failed":
    // case "auth/no-such-provider":
    // case "auth/null-user":
    // case "auth/operation-not-allowed":
    // case "auth/operation-not-supported-in-this-environment":
    // case "auth/popup-blocked":
    // case "auth/popup-closed-by-user":
    // case "auth/provider-already-linked":
    // case "auth/quota-exceeded":
    // case "auth/requires-recent-login":
    // case "auth/second-factor-already-in-use":
    // case "auth/tenant-id-mismatch":
    // case "auth/timeout":
    // case "auth/too-many-requests":
    // case "auth/unauthorized-continue-uri":
    // case "auth/unauthorized-domain":
    // case "auth/unsupported-first-factor":
    // case "auth/unsupported-persistence-type":
  }
}
