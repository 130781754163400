import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AnalyticsState {
  gaClientId?: string;
  analyticsIsActive?: boolean;
}

const initialState: () => AnalyticsState = () => ({});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'analytics', resettable: true })
export class LegacyAnalyticsStore extends Store<AnalyticsState> {
  constructor() {
    super(initialState());
  }
}
