import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FeatureToggles } from '@expresssteuer/models';
import { combineLatest, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { EnforcedFeatureTogglesParamsQuery } from './enforced-feature-toggles-params.query';

@Injectable({
  providedIn: 'root',
})
export class FeatureTogglesQuery {
  configFeatureToggles$ = this.afs
    .doc<FeatureToggles>('configuration/feature_toggles')
    .valueChanges()
    .pipe(shareReplay(1));

  featureToggles$ = combineLatest([
    this.configFeatureToggles$,
    this.enforcedFeatureTogglesParamsQuery.featureToggles$,
  ]).pipe(
    map(([configFeatureToggles, queryParamFeatureToggles]) => {
      return {
        // init with firestore config collection
        ...configFeatureToggles,
        // override with runtime query param
        ...queryParamFeatureToggles,
      } as FeatureToggles;
    })
  );
  enableEmailVerification$ = this.selectToggle$(
    (featureToggles) => featureToggles?.enableEmailVerification
  );
  enablePartnerSwitchInOneTouch$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.enablePartnerSwitchInOneTouch)
  );
  enableWonderland$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.enableWonderland)
  );
  enforceFinalTaxcaseReviewV2$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.enforceFinalTaxcaseReviewV2)
  );
  exp07SocialButtons$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.exp07SocialButtons)
  );
  displayCallFabButton$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.displayCallFabButton)
  );
  displayCallNavButton$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.displayCallNavButton)
  );
  enableIntercom$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.enableIntercom)
  );
  enableMasterVollmachtViaBackend$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.enableMasterVollmachtViaBackend)
  );
  enableStatusModalV2$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.enableStatusModalV2)
  );
  enableCustomContractStyles$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.enableCustomContractStyles)
  );
  pauseSignup$ = this.selectToggle$((featureToggles) =>
    Boolean(featureToggles?.pauseSignup)
  );

  constructor(
    private afs: AngularFirestore,
    private enforcedFeatureTogglesParamsQuery: EnforcedFeatureTogglesParamsQuery
  ) {}

  untilFeatureToggles(): Promise<FeatureToggles> {
    return firstValueFrom(
      this.featureToggles$.pipe(
        filter(
          (
            featureToggles: FeatureToggles | undefined
          ): featureToggles is FeatureToggles => Boolean(featureToggles)
        )
      )
    );
  }

  private selectToggle$<T>(selctor: (featureToggles: FeatureToggles) => T) {
    return this.featureToggles$.pipe(
      map((featureToggles) => Boolean(selctor(featureToggles))),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }
}
