import { Component, Inject, Input } from '@angular/core';
import {
  BindingsFrom,
  EASY_AKITA_UI_BINDINGS,
} from '@expresssteuer/state-angular';
import { MixpanelEvent } from '../../../state/analytics/mixpanel.event';
import { MixpanelService } from '../../../state/analytics/mixpanel.service';
import { tooltipEAU } from './tooltip.ui-state';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  providers: [tooltipEAU.providers],
})
export class TooltipComponent {
  @Input()
  content?: string | null;

  @Input()
  eventOnClick?: MixpanelEvent;

  constructor(
    @Inject(EASY_AKITA_UI_BINDINGS)
    public bindings: BindingsFrom<typeof tooltipEAU>,
    private mixpanel: MixpanelService
  ) {}

  sendEventOnClick() {
    if (this.eventOnClick) {
      this.mixpanel.sendEvent(this.eventOnClick);
    }
  }
}
