import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlModule } from '@expresssteuer/ui-components';
import { ContractStyleWrapperComponent } from './contract-style-wrapper.component';

@NgModule({
  declarations: [ContractStyleWrapperComponent],
  imports: [CommonModule, SafeHtmlModule],
  exports: [ContractStyleWrapperComponent],
})
export class ContractStyleWrapperModule {}
