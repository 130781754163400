import {
  ExpsAddressFixtures,
  HOMEOFFICE,
  MARITALSTATUS,
  OFFSITE,
} from '@expresssteuer/models';
import {
  SignUpV1,
  WayToSpecifyDistanceToWorkV1,
} from './sign-up.api-interface.v1';
import { SignUpV3 } from './sign-up.api-interface.v3';

export const validMinimalSignUpV1: SignUpV1 = {
  version: '1',
  taxYear: 2022,
  maritalStatus: MARITALSTATUS.single,
  monthlySalary: 2000,
  workingMonths: 12,
  isSelfEmployed: false,
  isSoliderOrCivilServantOrPensioner: false,
  hasHomeOffice: false,
  distanceToWork: 12,
  homeAddress: ExpsAddressFixtures['office-Expresssteuer-HQ'],
  workAddress: ExpsAddressFixtures['work-hh'],
  wayToSpecifyDistanceToWork: WayToSpecifyDistanceToWorkV1.address_calculation,
  hasOffsite: false,
  acceptTerms: true,
  clientFirstName: 'Anton',
  clientLastName: 'Test',
  clientEmail: 'anton.test@test.test',
  clientPhone: '+49 163 2666 22',
  clientBirthdate: '1980-02-10',
  clientCurrentAddress: ExpsAddressFixtures['work-hh'],
};
export const validFullSignUpV1: SignUpV1 = {
  ...validMinimalSignUpV1,
  marriedSince: '1980-02-10',
  divorcedSince: '1980-02-10',
  homeOfficeDays: HOMEOFFICE.EVERYDAY,
  offsiteDays: OFFSITE.NEVER,
  clientSignature: 'data:image/png;base64,',
  partnerFirstName: 'Brigitte',
  partnerLastName: 'Test',
  partnerBirthdate: '1970-10-02',
  partnerSignature: 'data:image/png;base64,',
  languageKey: 'de',
  metadata: {
    isTest: false,
    friendId: 'friend-1234',
    campaignId: '150Xw9k5OSTPCDgjHUBi',
    promoCode: 'promo-1234',
    voluumId: 'vo-1234',
    awinId: 'aw-1234',
    gaClientId: 'ga-1234',
    initialQueryParams: '?abTests=(enableJigglypuff:true)',
    referrer: 'https://www.expresssteuer.de',
    userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X)',
  },
};

export const validMinimalSignUpV3: SignUpV3 = {
  version: '3',
  taxYear: 2022,
  maritalStatus: MARITALSTATUS.single,
  isSelfEmployed: false,
  isSoliderOrCivilServantOrPensioner: false,
  homeAddress: ExpsAddressFixtures['office-Expresssteuer-HQ'],
  workAddress: ExpsAddressFixtures['work-hh'],
  wayToSpecifyDistanceToWork: WayToSpecifyDistanceToWorkV1.address_calculation,
  acceptTerms: true,
  clientFirstName: 'Anton',
  clientLastName: 'Test',
  clientEmail: 'anton.test@test.test',
  clientPhone: '+49 163 2666 22',
  clientBirthdate: '1980-02-10',
  clientCurrentAddress: ExpsAddressFixtures['work-hh'],
};
export const validFullSignUpV3: SignUpV3 = {
  ...validMinimalSignUpV3,
  marriedSince: '1980-02-10',
  divorcedSince: '1980-02-10',
  homeOfficeDays: HOMEOFFICE.EVERYDAY,
  offsiteDays: OFFSITE.NEVER,
  clientSignature: 'data:image/png;base64,',
  partnerFirstName: 'Brigitte',
  partnerLastName: 'Test',
  partnerBirthdate: '1970-10-02',
  partnerSignature: 'data:image/png;base64,',
  languageKey: 'de',
  metadata: {
    isTest: false,
    friendId: 'friend-1234',
    campaignId: '150Xw9k5OSTPCDgjHUBi',
    promoCode: 'promo-1234',
    voluumId: 'vo-1234',
    awinId: 'aw-1234',
    gaClientId: 'ga-1234',
    initialQueryParams: '?abTests=(enableJigglypuff:true)',
    referrer: 'https://www.expresssteuer.de',
    userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X)',
  },
};
