import { Injectable } from '@angular/core';
import { LegalOverlaysStore } from './legal-overlays.store';

@Injectable({ providedIn: 'root' })
export class LegalOverlaysService {
  constructor(private store: LegalOverlaysStore) {}

  showImprintOverlay() {
    this.store.update({ showImprintOverlay: true });
  }
  hideImprintOverlay() {
    this.store.update({ showImprintOverlay: false });
  }
  showConditionsOverlay() {
    this.store.update({ showConditionsOverlay: true });
  }
  hideConditionsOverlay() {
    this.store.update({ showConditionsOverlay: false });
  }
  showDataProtectionOverlay() {
    this.store.update({ showDataProtectionOverlay: true });
  }
  hideDataProtectionOverlay() {
    this.store.update({ showDataProtectionOverlay: false });
  }
  showDataProcessingOverlay() {
    this.store.update({ showDataProcessingOverlay: true });
  }
  hideDataProcessingOverlay() {
    this.store.update({ showDataProcessingOverlay: false });
  }
  showSignableContractOverlay() {
    this.store.update({ showSignableContract: true });
  }
  hideLoadedContract() {
    this.store.update({ showSignableContract: false });
  }
  showReferralOverlay() {
    this.store.update({ showReferralOverlay: true });
  }
  hideReferralOverlay() {
    this.store.update({ showReferralOverlay: false });
  }
}
