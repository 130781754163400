import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface CallFabState {
  open: boolean;
}

const initialState: () => CallFabState = () => ({
  open: false,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'call-fab', resettable: true })
export class CallFabStore extends Store<CallFabState> {
  constructor() {
    super(initialState());
  }
}
