<ng-container
  *ngIf="{
    showUserDropdown: bindings.showUserDropdown?.value$ | async
  } as data"
>
  <div class="user-action --with-dropdown">
    <button
      type="button"
      class="user-action__dropdown-trigger"
      (click)="bindings.showUserDropdown.setValue(!data.showUserDropdown)"
    >
      <span class="show-sr-only">Open user menu</span>
      <mat-icon
        svgIcon="user-circle-outline"
        class="user-action__icon --profile"
      ></mat-icon>
      <div
        *ngIf="
          userActions &&
          (hasUserActionsWithBadge | pureMethod : { args: [userActions] })
        "
        class="user-action__dot"
      ></div>
    </button>

    <app-popup
      *ngIf="data.showUserDropdown"
      anchor="right"
      (closeClick)="bindings.showUserDropdown.setValue(false)"
    >
      <div
        class="user-action-dropdown-list"
        role="menu"
        aria-orientation="vertical"
      >
        <ng-container *ngFor="let action of userActions">
          <a
            *ngIf="action"
            [routerLink]="action.routerLink"
            (click)="routeClickHandler(action.routerLink)"
            routerLinkActive="--active"
            class="user-action-dropdown-list__item"
            role="menuitem"
          >
            <span>
              {{ action.label }}
              <span
                *ngIf="action.badge && action.badge > 0"
                class="user-action-dropdown-list__badge"
                >{{ action.badge }}</span
              >
            </span>
          </a>
        </ng-container>
        <button
          type="button"
          appLogoutButton
          (click)="bindings.showUserDropdown.setValue(false)"
          class="user-action-dropdown-list__item"
          role="menuitem"
        >
          <mat-icon
            svgIcon="logout-outline"
            class="user-action-dropdown-list__item-icon"
          ></mat-icon>
          <span> {{ 'navigation.logout' | translate }} </span>
        </button>
      </div>
    </app-popup>
  </div>
</ng-container>
