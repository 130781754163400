import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  forwardRef,
  InjectFlags,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UnkownFunction } from '../helper/helper';

@Component({
  selector: 'ed-textarea',
  templateUrl: './ed-textarea.component.html',
  styleUrls: ['./ed-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EdTextareaComponent),
      multi: true,
    },
  ],
})
export class EdTextareaComponent implements ControlValueAccessor, OnInit {
  @Input()
  placeholder = '';

  @Input()
  name = '';

  @Input()
  rows = 4;

  @Input()
  cols = 50;

  @Input()
  errorText?: string | null;

  @Input()
  get showErrorsDespitePristine() {
    return this._showErrorsDespitePristine;
  }
  set showErrorsDespitePristine(value: BooleanInput) {
    this._showErrorsDespitePristine = coerceBooleanProperty(value);
  }
  private _showErrorsDespitePristine = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  value = '';

  private ngControl?: NgControl | null;

  get isInvalid() {
    return this.ngControl?.invalid;
  }
  get isTouched() {
    return this.ngControl?.touched;
  }
  get isDirty() {
    return this.ngControl?.dirty;
  }
  get isValid() {
    return this.ngControl?.valid;
  }
  get isUntouched() {
    return this.ngControl?.untouched;
  }
  get isPristine() {
    return this.ngControl?.pristine;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: string) => {};
  registerOnChange(fn: UnkownFunction) {
    this.onChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};
  registerOnTouched(fn: UnkownFunction) {
    this.onTouch = fn;
  }

  onBlur() {
    this.onTouch();
  }

  writeValue(value: string) {
    this.value = value;
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl, null, InjectFlags.Optional);
  }

  constructor(private injector: Injector) {}
}
