import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { GoogleMapsApiStore } from './google-maps-api.store';

export const GOOGLE_AUTOCOMPLETE_API_KEY = new InjectionToken<string>(
  'GOOGLE_AUTOCOMPLETE_API_KEY'
);
declare global {
  interface Window {
    googleMapsApiComplete: () => void;
  }
}

@Injectable({ providedIn: 'root' })
export class GoogleMapsApiService {
  private window: Window;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(GOOGLE_AUTOCOMPLETE_API_KEY) private apiToken: string,
    private store: GoogleMapsApiStore
  ) {
    const window = this.document.defaultView;
    if (!window) {
      throw new Error('Browser does not support window or globalThis');
    }
    this.window = window;
  }

  /**
   * Load the google maps api script if and only if not
   * already done before.
   *
   * @note this is automatically done within the query on
   * demand. (see
   * `GoogleMapsApiQuery.googleMapsApiIsLoaded$` and
   * `GoogleMapsApiQuery.waitUntilGoogleMapsApiIsLoaded`).
   *
   * To preload, call it manually.
   */
  public loadGoogleMapsApiScript() {
    if (
      this.store.getValue().requestStarted ||
      this.store.getValue().googleMapsApiLoaded
    ) {
      return;
    }
    this.store.update({ requestStarted: true });
    this.window.googleMapsApiComplete = () => this.setGoogleMapsApiToLoaded();
    this.injectGoogleMapsApiScript();
  }

  // TODO: gMaps api is also needed for distance matrix -> make globlal service to inject
  // script that notifies other services which use it
  private injectGoogleMapsApiScript() {
    const GOOGLE_MAPS_API_SCRIPT_ID = 'google-maps-api-script';
    const element = this.document.getElementById(GOOGLE_MAPS_API_SCRIPT_ID);
    if (!element) {
      const googleAutocompleteScript = this.document.createElement('script');
      googleAutocompleteScript.id = GOOGLE_MAPS_API_SCRIPT_ID;
      googleAutocompleteScript.src =
        `https://maps.googleapis.com/maps/api/js` +
        `?key=${this.apiToken}` +
        `&callback=googleMapsApiComplete` +
        `&libraries=places`;
      googleAutocompleteScript.defer = true;
      this.document.body.appendChild(googleAutocompleteScript);
    }
  }

  private setGoogleMapsApiToLoaded() {
    this.store.update({
      googleMapsApiLoaded: true,
      requestStarted: false,
    });
  }
}
