import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 } from 'uuid';

@Component({
  selector: 'ed-collapsible-item',
  templateUrl: './ed-collapsible-item.component.html',
  styleUrls: ['./ed-collapsible-item.component.scss'],
})
export class EdCollapsibleItemComponent {
  public id = v4();

  @Input() name?: string;
  @Input()
  get open(): boolean {
    return this._open;
  }
  set open(value: BooleanInput) {
    this._open = coerceBooleanProperty(value);
  }
  private _open = false;

  @Output() opened = new EventEmitter<string>();

  handleButtonClick() {
    if (this.open) {
      this.setClose();
    } else {
      this.setOpen();
    }
  }

  public setClose() {
    this.open = false;
  }

  setOpen() {
    this.open = true;
    this.opened.emit(this.id);
  }
}
