import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EdTextfieldModule } from '../ed-textfield/ed-textfield.module';
import { EdCodeComponent } from './ed-code.component';

@NgModule({
  declarations: [EdCodeComponent],
  imports: [CommonModule, FormsModule, EdTextfieldModule],
  exports: [EdCodeComponent],
})
export class EdCodeModule {}
