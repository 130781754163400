import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageQuery {
  constructor(private translateService: TranslateService) {}

  currentLanguage$ = merge(
    Promise.resolve(this.getCurrentLanguage()),
    this.translateService.onLangChange.pipe(map((e) => e.lang))
  );

  getCurrentLanguage() {
    return (
      this.translateService.currentLang || this.translateService.defaultLang
    );
  }
}
