import {
  TrackingProductType,
  TrackingSourceType,
} from '@expresssteuer/tracking-types';
import { SupportedSocialProviders } from '../../auth/auth.service';
import { MixpanelBaseEvent, MixpanelEvent } from '../mixpanel.event';

export enum AuthMethodsEventName {
  SocialPasswordLinkSlideSocialProviderButtonClicked = 'auth.socialPasswordLinkSlide.socialProviderButtonClicked',
  SocialPasswordLinkSlideSocialProviderAlreadyInUseError = 'auth.socialPasswordLinkSlide.socialProviderAlreadyInUseError',
  SocialPasswordLinkSlideSocialProviderError = 'auth.socialPasswordLinkSlide.socialProviderError',
  SocialPasswordLinkSlideSocialProviderLinked = 'auth.socialPasswordLinkSlide.socialProviderLinked',
  SocialPasswordLinkSlidePasswordButtonClicked = 'auth.socialPasswordLinkSlide.passwordButtonClicked',
  SocialPasswordLinkSlideSetPasswordButtonClicked = 'auth.socialPasswordLinkSlide.setPasswordButtonClicked',
  SocialPasswordLinkSlidePasswordError = 'auth.socialPasswordLinkSlide.passwordError',
  SocialPasswordLinkSlidePasswordUpdated = 'auth.socialPasswordLinkSlide.passwordUpdated',
  SignInWithSocialPageSocialProviderClicked = 'auth.signInWithSocialPage.socialProviderButtonClicked',
  SignInWithSocialPageClientDoesNotExistError = 'auth.signInWithSocialPage.clientDoesNotExistError',
  SignInWithSocialPageSocialProviderError = 'auth.signInWithSocialPage.error',
  SignInWithSocialPageSocialLoginSuccessful = 'auth.signInWithSocialPage.successfullyLoggedIn',
}

type AuthMethodsEventNameType =
  `${TrackingProductType.Auth}.${string}.${string}`;

export interface AuthMethodsEventProperties extends MixpanelBaseEvent {
  authType: SupportedSocialProviders | 'password';
}

export class AuthMethodsEvent implements MixpanelEvent {
  sourceType = TrackingSourceType.Website;
  eventProperties: AuthMethodsEventProperties;

  constructor(
    public eventName: AuthMethodsEventNameType,
    eventProperties: Omit<AuthMethodsEventProperties, 'sourceType'>
  ) {
    this.eventProperties = {
      sourceType: this.sourceType,
      ...eventProperties,
    };
  }
}
