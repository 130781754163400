import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EdButtonComponent } from './ed-button.component';

@NgModule({
  declarations: [EdButtonComponent],
  imports: [CommonModule, MatIconModule],
  exports: [EdButtonComponent],
})
export class EdButtonModule {}
