<div
  class="textfield"
  [ngClass]="{
    'textfield--error': isInvalid && (isTouched || showErrorsDespitePristine),
    'textfield--disabled': disabled
  }"
>
  <label *ngIf="label" class="label" [for]="name">{{ label }}</label>
  <input
    #inputElement
    class="input"
    [type]="type"
    [id]="name"
    [name]="name"
    [step]="step"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [(ngModel)]="value"
    (ngModelChange)="inputChanged($event)"
    (focus)="focus.emit($event)"
    (blur)="blurTriggered($event)"
    [disabled]="disabled"
    [edNumbersOnly]="type === 'number'"
    [maxlength]="maxlength"
    [attr.inputmode]="inputmode"
  />
</div>
<div
  *ngIf="isInvalid && errorText && (isTouched || showErrorsDespitePristine)"
  class="error-text"
>
  {{ errorText }}
</div>
