import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface BrazeState {
  isInitialized: boolean;
  isInitializing: boolean;
}

const initialState: () => BrazeState = () => ({
  isInitialized: false,
  isInitializing: false,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'braze', resettable: true })
export class BrazeStore extends Store<BrazeState> {
  constructor() {
    super(initialState());
  }
}
