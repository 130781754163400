import { Directive, HostListener } from '@angular/core';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { LegacyAnalyticsService } from '../../../state/analytics/legacy-analytics.service';
import { AuthService } from '../../../state/auth/auth.service';

@Directive({
  selector: '[appLoginButton]',
  providers: [EsuiLoggerService],
})
export class LoginButtonDirective {
  constructor(
    private authService: AuthService,
    private analyticsService: LegacyAnalyticsService,
    private logger: EsuiLoggerService
  ) {
    this.logger.registerOwner(this);
  }

  @HostListener('click') onClick() {
    this.analyticsService.sendClickEvent('clickLoginButton');
    void this.authService.showLogin().catch((error) => {
      this.logger.error('failed to showLogin', {
        error,
      });
    });
  }
}
