<div
  *ngIf="hasSelectedFile || !!documentData; else notUploading"
  class="uploaded"
>
  <h3 class="uploaded__title">{{ uploadedFileTitle }}</h3>
  <div
    *ngIf="processingFinished && additionalProcessingFinished; else notUploaded"
    class="uploaded__file"
  >
    <span class="uploaded__filename">{{
      documentData?.originalUploadName
    }}</span>
    <div class="uploaded__status">
      <span
        class="verified-icon"
        [class]="{
          'verified-icon--highlighted':
            documentData?.documentState?.verified ||
            documentData?.documentState?.virusChecked
        }"
        *ngIf="displayDocumentState && documentData?.documentState?.uploaded"
      >
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8059 0.196394C14.0656 0.457367 14.0646 0.879476 13.8036 1.1392L5.09527 9.80587C4.83518 10.0647 4.41482 10.0647 4.15473 9.80587L0.196394 5.86647C-0.0645793 5.60674 -0.0655912 5.18463 0.194133 4.92366C0.453858 4.66269 0.875967 4.66168 1.13694 4.9214L4.625 8.39278L12.8631 0.194133C13.124 -0.0655914 13.5461 -0.0645792 13.8059 0.196394Z"
            fill="#001C33"
          />
        </svg>
      </span>
      <button
        *ngIf="
          allowDeletion &&
          !documentData?.documentState?.verified &&
          !documentData?.shouldBeDeleted
        "
        type="button"
        class="uploaded__btn-delete"
        (click)="handleDeleteFile(documentData?.id)"
        [disabled]="isDeleting"
      >
        <span class="show-sr-only">Delete file</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
          />
        </svg>
      </button>
    </div>
  </div>
  <ng-template #notUploaded>
    <ed-progressbar
      [value]="uploadProgressPercentage"
      [hideRemainingTime]="true"
    ></ed-progressbar>
    <div class="upload-progress">
      <span>{{ documentData?.originalUploadName }}</span>
      <span>{{ uploadProgressPercentage / 100 | percent }}</span>
    </div>
  </ng-template>
</div>
<ng-template #notUploading>
  <div
    class="upload-dropzone"
    ngx-dropzone
    #dropzone
    (change)="addFile($event)"
    [accept]="'image/*,application/pdf'"
  >
    <ngx-dropzone-label>
      <mat-icon
        class="upload-dropzone__icon"
        svgIcon="arrow-up-tray"
      ></mat-icon>

      <p class="upload-dropzone__title" [innerHTML]="uploadZoneTitle"></p>
      <p
        class="upload-dropzone__description"
        [innerHTML]="uploadZoneFileTypes"
      ></p>
      <button
        *ngIf="uploadButtonTitle"
        class="upload-dropzone__button"
        ed-button
        type="button"
        (click)="$event.stopPropagation(); drop?.showFileSelector()"
      >
        {{ uploadButtonTitle }}
      </button>
    </ngx-dropzone-label>
  </div>
</ng-template>
