type WithScrollIntoViewIfNeeded = {
  scrollIntoViewIfNeeded: (options: ScrollIntoViewOptions) => void;
};

function isWithScrollIntoViewIfNeeded(
  e: unknown
): e is WithScrollIntoViewIfNeeded {
  return (
    e != null &&
    typeof (e as WithScrollIntoViewIfNeeded).scrollIntoViewIfNeeded ===
      'function'
  );
}

export function scrollToElement(element?: HTMLElement) {
  const scrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'center',
  };

  try {
    if (isWithScrollIntoViewIfNeeded(element)) {
      // webkit browsers support non standard `scrollIntoViewIfNeeded`
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
      element.scrollIntoViewIfNeeded(scrollOptions);
    } else {
      element?.scrollIntoView(scrollOptions);
    }
  } catch (e) {
    console.error('scrolling into view failed', e);
  }
}

export type UnkownFunction = () => unknown;
