import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EdButtonGroupComponent } from './ed-button-group.component';

@NgModule({
  declarations: [EdButtonGroupComponent],
  imports: [CommonModule],
  exports: [EdButtonGroupComponent],
})
export class EdButtonGroupModule {}
