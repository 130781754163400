import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  InjectFlags,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import parsePhoneNumber from 'libphonenumber-js';
import { UnkownFunction } from '../helper/helper';

@Component({
  selector: 'ed-textfield',
  templateUrl: './ed-textfield.component.html',
  styleUrls: ['./ed-textfield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EdTextfieldComponent),
      multi: true,
    },
  ],
})
export class EdTextfieldComponent implements ControlValueAccessor, OnInit {
  private static nextUniqueId = 0;

  @Input()
  placeholder? = '';

  @Input()
  label = '';

  @Input()
  autocomplete = '';

  @Input()
  maxlength: number | null = null;

  @Input()
  inputmode:
    | 'numeric'
    | 'text'
    | 'decimal'
    | 'tel'
    | 'search'
    | 'email'
    | 'url'
    | null = null;

  @Input()
  type: 'text' | 'number' | 'password' | 'email' | 'tel' = 'text';

  @Input()
  name = `ed-textfield-${EdTextfieldComponent.nextUniqueId++}`;

  @Input()
  errorText?: string | null;

  @Input()
  get step() {
    return this._step;
  }
  set step(value: NumberInput) {
    this._step = coerceNumberProperty(value);
  }
  private _step?: number;

  @Input()
  get showErrorsDespitePristine() {
    return this._showErrorsDespitePristine;
  }
  set showErrorsDespitePristine(value: BooleanInput) {
    this._showErrorsDespitePristine = coerceBooleanProperty(value);
  }
  private _showErrorsDespitePristine = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  focus = new EventEmitter();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  @ViewChild('inputElement')
  inputElement?: ElementRef;

  value = '';

  private ngControl?: NgControl | null;
  get isInvalid() {
    return this.ngControl?.invalid;
  }
  get isTouched() {
    return this.ngControl?.touched;
  }
  get isDirty() {
    return this.ngControl?.dirty;
  }
  get isValid() {
    return this.ngControl?.valid;
  }
  get isUntouched() {
    return this.ngControl?.untouched;
  }
  get isPristine() {
    return this.ngControl?.pristine;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: string | number | null) => {};
  registerOnChange(fn: UnkownFunction) {
    this.onChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};
  registerOnTouched(fn: UnkownFunction) {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }

  blurTriggered(value?: unknown) {
    if (this.type === 'tel') {
      this.formatTelInputOnBlur();
    }
    this.onTouch();
    this.blur.emit(value);
  }

  inputChanged(valueString: string) {
    if (this.type === 'number') {
      const value = parseInt(valueString);
      this.onChange(isNaN(value) ? null : value);
    } else {
      this.onChange(valueString);
    }
  }

  getInputElement() {
    return this.inputElement?.nativeElement;
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl, null, InjectFlags.Optional);
  }

  private formatTelInputOnBlur() {
    const oldValue = this.value;
    const formattedValue =
      parsePhoneNumber(this.value, {
        defaultCountry: 'DE',
      })?.number.toString() ?? '';
    if (formattedValue !== oldValue) {
      this.value = formattedValue;
      this.onChange(this.value);
    }
  }

  constructor(private injector: Injector) {}
}
