import { InjectionToken, Injector } from '@angular/core';

type AnyConstructor = new (...args: any[]) => any;

let featureInstanceCount = 0;

/**
 * Create a unique set of providers for class instances of a state feature.
 * This comes in handy when multiple instances of a feature are required.
 * @example
  ```
    \@Injectable()
    class ExampleService {
      constructor(private store: ExampleStore) {}
    }

    \@Injectable()
    class ExampleStore {
      test = Math.random();
    }

    \@Injectable()
    class ExampleQuery {
      exampleField = this.store.test;

      constructor(private store: ExampleStore) {}
    }

    const a = generateNewFeatureProviders({
      store: ExampleStore,
      service: ExampleService,
      query: ExampleQuery,
    });

    const b = generateNewFeatureProviders({
      store: ExampleStore,
      service: ExampleService,
      query: ExampleQuery,
    });

    \@Component({
      selector: 'hello',
      template: `
        a: {{queryA.exampleField}}<br/>
        b: {{queryB.exampleField}}
      `,
      providers: [a.providers, b.providers],
    })
    export class HelloComponent {
      constructor(
        \@Inject(a.QueryToken) public queryA: ExampleQuery,
        \@Inject(b.QueryToken) public queryB: ExampleQuery
      ) {}
    }
  ```
 */
export function generateNewFeatureProviders<
  TStore extends AnyConstructor,
  TService extends AnyConstructor,
  TQuery extends AnyConstructor
>(
  featureConstructors: { store: TStore; service: TService; query: TQuery },
  featureName = `Feature`
) {
  // lets create a unique token for each feature class

  const ServiceToken = new InjectionToken<TService>(
    `${featureConstructors.service.name}${featureInstanceCount}`
  );
  const StoreToken = new InjectionToken<TStore>(
    `${featureConstructors.store.name}${featureInstanceCount}`
  );
  const QueryToken = new InjectionToken<TQuery>(
    `${featureConstructors.query.name}${featureInstanceCount}`
  );

  // and another token for combining the unique set of feature classes in a single object
  const FeatureToken = new InjectionToken<{
    store: TStore;
    service: TService;
    query: TQuery;
  }>(`${featureName}${featureInstanceCount}`);

  featureInstanceCount++; // assure we always have a unique name

  const featureFactory = (injector: Injector) => {
    console.log('injector', injector);
    const child = Injector.create({
      providers: [
        { provide: featureConstructors.service },
        { provide: featureConstructors.store },
        { provide: featureConstructors.query },
      ],
      parent: injector,
    });
    const service = child.get(featureConstructors.service);
    const store = child.get(featureConstructors.store);
    const query = child.get(featureConstructors.query);

    return {
      service,
      store,
      query,
    };
  };

  return {
    providers: [
      {
        provide: FeatureToken,
        useFactory: featureFactory,
        deps: [Injector],
      },
      {
        provide: ServiceToken,
        useFactory: (feature: { service: TService }) => feature.service,
        deps: [FeatureToken],
      },
      {
        provide: StoreToken,
        useFactory: (feature: { store: TStore }) => feature.store,
        deps: [FeatureToken],
      },
      {
        provide: QueryToken,
        useFactory: (feature: { query: TQuery }) => feature.query,
        deps: [FeatureToken],
      },
    ],
    ServiceToken,
    StoreToken,
    QueryToken,
    FeatureToken,
  };
}
