import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoutes } from '../../app-routing.module';
import { MyaccountViewComponent } from './myaccount-view.component';

export const myAccountRoutes = {
  overview: {
    path: 'overview',
    fullPath: '/' + appRoutes.myaccount.path + '/overview',
  },
  friendsinvite: {
    path: 'friendsinvite',
    fullPath: '/' + appRoutes.myaccount.path + '/friendsinvite',
  },
  profile: {
    path: 'profile',
    fullPath: '/' + appRoutes.myaccount.path + '/profile',
  },
  documents: {
    path: 'documents',
    fullPath: '/' + appRoutes.myaccount.path + '/documents',
  },
  wonderland: {
    path: 'todos',
    fullPath: '/' + appRoutes.myaccount.path + '/todos',
  },
  optimize: {
    path: 'optimize',
    fullPath: '/' + appRoutes.myaccount.path + '/optimize',
  },
};

const routes: Routes = [
  {
    path: '' as const,
    component: MyaccountViewComponent,
    children: [
      {
        path: myAccountRoutes.overview.path,
        loadChildren: () =>
          import(
            '../../modules/profile/overview-page/overview-page.module'
          ).then((m) => {
            return m.OverviewPageModule;
          }),
      },
      {
        path: myAccountRoutes.friendsinvite.path,
        loadChildren: () =>
          import(
            '../../modules/profile/friendsinvite-page/friendsinvite-page.module'
          ).then((m) => {
            return m.FriendsinvitePageModule;
          }),
      },
      {
        path: myAccountRoutes.profile.path,
        loadChildren: () =>
          import('../../modules/profile/profile-page/profile-page.module').then(
            (m) => {
              return m.ProfilePageModule;
            }
          ),
      },
      {
        path: myAccountRoutes.wonderland.path,
        loadChildren: () =>
          import('../../modules/profile/wonderland/wonderland.module').then(
            (m) => {
              return m.WonderlandModule;
            }
          ),
      },
      {
        path: myAccountRoutes.documents.path,
        loadChildren: () =>
          import(
            '../../modules/profile/documents-page/documents-page.module'
          ).then((m) => {
            return m.DocumentsPageModule;
          }),
      },
      {
        path: myAccountRoutes.optimize.path,
        loadChildren: () =>
          import('../../modules/profile/optimize/optimize.module').then((m) => {
            return m.OptimizeModule;
          }),
      },
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyaccountViewRoutingModule {}
