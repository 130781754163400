import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ActionCodeSettings,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthenticateByData } from '@expresssteuer/authentication-api-angular';
import { AuthenticationFailure } from '@expresssteuer/authentication-api-interfaces';
import { DateString } from '@expresssteuer/models';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import {
  catchError,
  concat,
  defer,
  filter,
  interval,
  lastValueFrom,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { buildMeta } from '../../../build-meta/build-meta';
import { appRoutes } from '../../app-routing.module';
import { convertFirebaseAuthCode } from '../../modules/auth/error-codes';
import {
  AuthMethodsEvent,
  AuthMethodsEventName,
} from '../analytics/event-types/auth-methods.event';
import {
  AuthEventName,
  StandardEvent,
} from '../analytics/event-types/standard.event';
import { MixpanelService } from '../analytics/mixpanel.service';
import { AuthPersistedService } from '../auth-persisted/auth-persisted.service';
import { AuthErrors, AuthStore } from './auth.store';

export type SupportedSocialProviders =
  | 'google.com'
  | 'twitter.com'
  | 'facebook.com';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private window: Window;
  private logger: EsuiLoggerService;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private afAuth: AngularFireAuth,
    private authStore: AuthStore, // todo waiting on another PR #845 for custom mail // public sendVerificationEmail: SendVerificationEmail
    logger: EsuiLoggerService,
    private mixpanelService: MixpanelService,
    private authPersistedService: AuthPersistedService,
    private authenticateByData: AuthenticateByData,
    private mixpanel: MixpanelService
  ) {
    this.logger = logger.getNewInstance(this);
    if (!this.document.defaultView) {
      throw new Error('Browser does not support window or globalThis');
    }
    this.window = this.document.defaultView;
  }

  #redirectResultPrerequisite = defer(async () => {
    try {
      this.updateIsHandlingSocialRedirectResult(true);
      const result = await this.afAuth.getRedirectResult();
      if (!!result.user && result.additionalUserInfo) {
        if (result.operationType == 'link') {
          await this.handleSuccessfulSocialLinkRedirectResult(
            result.additionalUserInfo?.providerId as SupportedSocialProviders
          );
        } else if (result.operationType == 'signIn') {
          await this.handleSuccessfulSocialLoginRedirectResult(
            result.additionalUserInfo?.providerId as SupportedSocialProviders,
            result.additionalUserInfo?.isNewUser
          );
        }
      } else {
        this.updateRedirectResultState({
          withShouldntHandleLink: true,
          withShouldntHandleLogin: true,
        });
      }
      this.updateIsHandlingSocialRedirectResult(false);
    } catch (error) {
      const fireAuthError = error as any;
      const errorCode = convertFirebaseAuthCode(fireAuthError.code);
      this.logger.warn('failed to retrieveRedirectResult', {
        error,
        errorCode,
      });
      this.authStore.updateError({
        [errorCode]: true,
      });
      if (fireAuthError.customData?._tokenResponse?.providerId) {
        this.updateSelectedSocialProvider(
          fireAuthError.customData._tokenResponse.providerId
        );
      }
      if (
        errorCode === 'socialProviderAlreadyInUse' ||
        errorCode === 'emailAlreadyInUse'
      ) {
        await this.signOut({
          withRedirect: false,
          withMixpanelReset: false,
          withKeepErrors: true,
          withoutSocialRedirectReset: true,
        });
      }
      /*
       * If there was an error, there is always a redirect result
       * that should be handled
       */
      this.updateRedirectResultState({
        withShouldHandleUnsuccessfulLink: true,
        withShouldHandleUnsuccessfulLogin: true,
      });
      this.updateIsHandlingSocialRedirectResult(false);
    }
  }).pipe(shareReplay());

  /**
   * We first wait for redirect results to be fetched so it can be handled
   * in LinkSocialPasswordComponent and LoginPage. Then we continue syncing the
   * current af user to state.
   * We need to do it subsequently, because a subscribtion on afAuth.user deletes
   * the redirect results.
   */
  #syncer = this.#redirectResultPrerequisite.pipe(
    switchMap(() => this.afAuth.user),
    switchMap((user) => {
      // https://app.clickup.com/t/2vcc7ve
      // This will hopefully be fixed with the
      // auth lib update as auth seems to be
      // messed up here.
      // Therefore, lets observe the user object
      // for changes and emit again everytime it
      // changes.
      // Addition: Observation does not seem to
      // be trivial as the object itself seems to
      // be wrapped in a Proxy. The heck, lets do
      // some polling until we got our heads
      // wrapped around this.

      // The following polls for changes to the
      // `isAnonymous` flag on the user if it
      // changes from `true` to `false` and
      // then emits the user once again.

      const timeoutMS = 50;
      const recursionLimitCount = 200;
      let iterationCount = 0;

      const startedAnonymous = user?.isAnonymous === true;

      return concat(
        of(user), // lets emit the user
        interval(timeoutMS).pipe(
          // then, lets do some polling
          take(startedAnonymous ? recursionLimitCount : 0), // but only if the initial emitted user isAnonymous, further we stop after `recursionLimitCount` tries
          tap(() => iterationCount++),
          filter(() => user?.isAnonymous === false), // here we check if the user is not `isAnonymous` anymore, if so lets pass
          map(() => user), // and emit the user
          tap(() =>
            this.logger.info(
              'Found user as isAnonymous===false through polling',
              { timeoutMS, iterationCount }
            )
          ),
          take(1) // once we found the user is isAnonymous === false, lets stop
        )
      );
    }),
    tap({
      next: (user) => {
        this.logger.info('Got user with uid ', user?.uid);
        this.authStore.update((state) => {
          return {
            ...state,
            firebaseUser: JSON.parse(JSON.stringify(user)),
          };
        });
        this.authStore.setLoading(false);
      },
      error: (e) => {
        this.logger.warn('afAuth.user.error', e);
        this.authStore.update((state) => {
          return {
            ...state,
            firebaseUser: null,
          };
        });
        this.authStore.updateError({ syncFailed: true });
        this.authStore.setLoading(false);
      },
    }),
    catchError(() => {
      return Promise.resolve();
    }),
    shareReplay(1)
  );

  sync() {
    return this.#syncer;
  }

  async signInAnonymously() {
    this.authStore.setLoading(true);
    await this.afAuth.signInAnonymously().catch((error) => {
      const errorCode = convertFirebaseAuthCode(error.code);
      this.logger.error('failed to signInAnonymously', {
        error,
        errorCode,
      });
      this.authStore.updateError({
        signInAnonymouslyFailed: true,
        [errorCode]: true,
      });
    });
    this.authStore.setLoading(false);
  }

  async signInWithEmailLink(link?: string, persistedEmail?: string) {
    // persistedEmail is used to try auto submit if available
    const email = persistedEmail ?? this.authStore.getValue()?.enteredEmail;
    if (!email) {
      this.authStore.updateError({
        signInWithEmailLinkFailed: true,
        missingEmail: true,
      });
      return;
    }
    this.authStore.setLoading(true);
    await this.afAuth
      .signInWithEmailLink(email, link)
      .then(() => {
        this.mixpanel.sendEvent(
          new StandardEvent(AuthEventName.SignInWithLinkPageSuccessful, {
            buildHash: buildMeta.commitHash,
          })
        );
      })
      .catch((error) => {
        const errorCode = convertFirebaseAuthCode(error.code);
        if (errorCode === 'expired') {
          this.logger.warn('failed to signInWithEmailLink', {
            errorCode,
            error,
          });
        } else {
          this.logger.error('failed to signInWithEmailLink', {
            errorCode,
            error,
          });
        }
        this.authStore.updateError({
          signInWithEmailLinkFailed: true,
          [errorCode]: true,
        });
      });
    this.authStore.setLoading(false);
  }

  async signInWithEmailAndPassword(): Promise<
    undefined | keyof typeof AuthErrors
  > {
    this.authStore.setLoading(true);
    const { enteredEmail, enteredPassword } = this.authStore.getValue();

    if (!enteredEmail) {
      this.authStore.updateError({
        signInWithEmailAndPasswordFailed: true,
        missingEmail: true,
      });
      this.authStore.setLoading(false);
      return 'missingEmail';
    }

    if (!enteredPassword) {
      this.authStore.updateError({
        signInWithEmailAndPasswordFailed: true,
        missingPassword: true,
      });
      this.authStore.setLoading(false);
      return 'missingPassword';
    }

    this.authStore.clearError('all');

    const error = await this.afAuth
      .signInWithEmailAndPassword(enteredEmail, enteredPassword)
      .then(() => {
        this.authStore.update((state) => {
          return {
            ...state,
            enteredPassword: undefined,
          };
        });
        this.mixpanel.sendEvent(
          new StandardEvent(
            AuthEventName.SignInWithLinkPasswordPageSuccessful,
            { buildHash: buildMeta.commitHash }
          )
        );
        return undefined;
      })
      .catch((error) => {
        const errorCode = convertFirebaseAuthCode(error.code);
        if (errorCode === 'unableToFindUser' || errorCode === 'wrongPassword') {
          this.logger.warn('failed to signInWithEmailAndPassword', {
            error,
            errorCode,
          });
        } else {
          this.logger.error('failed to signInWithEmailAndPassword', {
            error,
            errorCode,
          });
        }
        this.authStore.updateError({
          signInWithEmailAndPasswordFailed: true,
          [errorCode]: true,
        });
        this.authStore.setLoading(false);
        return errorCode;
      });

    this.authStore.setLoading(false);
    return error;
  }

  async signInWithBirthdate(
    token: string
  ): Promise<undefined | keyof typeof AuthErrors> {
    this.authStore.setLoading(true);
    const { enteredBirthdate } = this.authStore.getValue();

    if (!enteredBirthdate) {
      this.authStore.updateError({
        signInWithBirthdateFailed: true,
        authBirthdateMissingBirthdate: true,
      });
      this.authStore.setLoading(false);
      return 'authBirthdateMissingBirthdate';
    }

    this.authStore.clearError('all');
    this.authStore.update({ birthdateTrialsLeft: undefined });

    const error = await lastValueFrom(
      this.authenticateByData.call({
        birthdate: enteredBirthdate,
        encryptedData: token,
      })
    )
      .then((res) => {
        if (!res.token) {
          throw res;
        }
        if (res.token) {
          return this.afAuth.signInWithCustomToken(res.token);
        }
        throw new Error('unexpected bday auth callable result');
      })
      .then(() => {
        this.mixpanel.sendEvent(
          new StandardEvent(AuthEventName.SignInWithBirthdatePageSuccessful, {
            buildHash: buildMeta.commitHash,
          })
        );
        this.authStore.update((state) => {
          return {
            ...state,
            enteredBirthdate: undefined,
            birthdateTrialsLeft: undefined,
          };
        });
        return undefined;
      })
      .catch(
        (
          error:
            | {
                message: AuthenticationFailure | string;
                details?: { retriesLeft?: number };
              }
            | Error
        ) => {
          this.logger.warn('failed to signInWithBirthdate', {
            error,
          });
          this.authStore.updateError({
            signInWithBirthdateFailed: true,
          });
          const retriesLeft =
            (error as Partial<{ details?: { retriesLeft?: number } }>).details
              ?.retriesLeft ?? undefined;
          this.authStore.update({
            birthdateTrialsLeft: retriesLeft,
          });
          if (error.message) {
            switch (error.message) {
              case AuthenticationFailure.failed:
                this.authStore.updateError({
                  authBirthdateAuthFailedRetries: true,
                });
                break;
              case AuthenticationFailure.error:
                this.authStore.updateError({
                  authBirthdateContactSupport: true,
                });
                break;
              case AuthenticationFailure.locked:
                this.authStore.updateError({
                  authBirthdateAccountLocked: true,
                });
                break;
              case AuthenticationFailure.expired:
                this.authStore.updateError({
                  authBirthdateAuthLinkExpired: true,
                });
                break;
              default:
                this.authStore.updateError({
                  authBirthdateGenericAuthFailed: true,
                });
                break;
            }
          }
          this.authStore.setLoading(false);
          return 'signInWithBirthdateFailed' as const;
        }
      );

    this.authStore.setLoading(false);
    return error;
  }

  async signInWithSocialProvider(provider: SupportedSocialProviders) {
    this.authStore.setLoading(true);
    this.updateSelectedSocialProvider(provider);
    this.authStore.clearError();

    const authProvider = this.getAuthProviderForProviderId(provider);

    await this.afAuth.signInWithRedirect(authProvider).catch((error) => {
      const errorCode = convertFirebaseAuthCode(error.code);
      this.logger.warn('failed to signInWithSocialProvider', {
        errorCode,
        error,
      });
      this.authStore.updateError({
        signInWithSocialProviderFailed: true,
        [errorCode]: true,
      });
      this.authStore.setLoading(false);
    });
    this.authStore.setLoading(false);
  }

  async checkEnteredEmailAddress(): Promise<
    undefined | keyof typeof AuthErrors
  > {
    const { enteredEmail } = this.authStore.getValue();
    if (!enteredEmail) {
      return 'missingEmail';
    }
    const signInMethods = await this.afAuth.fetchSignInMethodsForEmail(
      enteredEmail
    );
    if (signInMethods.length === 0) {
      return;
    } else {
      this.authStore.updateError({ emailAlreadyInUse: true });
      return 'emailAlreadyInUse';
    }
  }

  async linkWithEmailAndPassword(): Promise<
    undefined | keyof typeof AuthErrors
  > {
    this.authStore.setLoading(true);
    this.authStore.clearError();
    const { enteredEmail, enteredPassword } = this.authStore.getValue();
    const user = await this.afAuth.currentUser;

    if (!user) {
      //TODO decide autologin as anonymous?

      this.authStore.updateError({
        linkWithCredentialFailed: true,
        noUserToLinkWithCredential: true,
      });
      this.authStore.setLoading(false);
      return 'noUserToLinkWithCredential';
    }

    if (!enteredEmail) {
      this.authStore.updateError({
        linkWithCredentialFailed: true,
        missingEmail: true,
      });
      this.authStore.setLoading(false);
      return 'missingEmail';
    }

    if (!enteredPassword) {
      this.authStore.updateError({
        linkWithCredentialFailed: true,
        missingPassword: true,
      });
      this.authStore.setLoading(false);
      return 'missingPassword';
    }

    const credential = EmailAuthProvider.credential(
      enteredEmail,
      enteredPassword
    );

    const error = await user
      .linkWithCredential(credential)
      .then(() => {
        this.authStore.update((state) => {
          return {
            ...state,
            enteredPassword: undefined,
          };
        });
        this.mixpanel.setProfilePropertyAuthType('link');
        return undefined;
      })
      .catch((error) => {
        const errorCode = convertFirebaseAuthCode(error.code);
        this.logger.error('failed to linkWithEmailAndPassword', {
          error,
          errorCode,
        });
        this.authStore.updateError({
          linkWithCredentialFailed: true,
          [errorCode]: true,
        });
        return errorCode;
      });

    this.authStore.setLoading(false);
    return error;
  }

  async linkWithSocialProvider(
    provider: SupportedSocialProviders
  ): Promise<undefined | keyof typeof AuthErrors> {
    this.authStore.setLoading(true);
    this.updateSelectedSocialProvider(provider);
    this.authStore.clearError();
    const user = await this.afAuth.currentUser;

    if (!user) {
      this.authStore.updateError({
        linkWithCredentialFailed: true,
        noUserToLinkWithCredential: true,
      });
      this.authStore.setLoading(false);
      return 'noUserToLinkWithCredential';
    }

    if (user.isAnonymous) {
      this.authStore.updateError({
        linkWithCredentialFailed: true,
        noSignedInUserToLinkWithCredential: true,
      });
      this.authStore.setLoading(false);
      return 'noSignedInUserToLinkWithCredential';
    }

    await this.router.navigate([], {
      queryParams: {
        skipInitialRedirect: true,
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });

    const authProvider = this.getAuthProviderForProviderId(provider);

    await user.linkWithRedirect(authProvider).catch((error) => {
      const errorCode = convertFirebaseAuthCode(error.code);
      this.logger.warn('failed to linkWithSocialProvider', {
        error,
        errorCode,
      });
      this.authStore.updateError({
        linkWithCredentialFailed: true,
        [errorCode]: true,
      });
    });

    this.authStore.setLoading(false);
    return undefined;
  }

  async resendSignInLink() {
    this.authStore.setLoading(true);
    await this.router.navigate([], {
      queryParams: {
        mode: undefined,
        oobCode: undefined,
        lang: undefined,
        apiKey: undefined,
      },
      queryParamsHandling: 'merge',
    });
    return this.manualSendSignInLink();
  }

  async manualSendSignInLink() {
    this.authStore.setLoading(true);

    const { enteredEmail } = this.authStore.getValue();
    if (!enteredEmail) {
      this.authStore.clearError();
      this.authStore.updateError({
        sendSignInLinkFailed: true,
        missingEmail: true,
      });
      this.authStore.setLoading(false);
      return;
    }

    await this.sendSignInLink(enteredEmail);
  }

  async autoSendSignInLink(email: string) {
    await this.sendSignInLink(email, true);
  }

  private async sendSignInLink(email: string, isAutoRequest = false) {
    this.authStore.setLoading(true);
    this.authStore.clearError();
    this.authStore.update({
      signInLinkWasAutoRequested: isAutoRequest,
    });
    const methods = await this.afAuth
      .fetchSignInMethodsForEmail(email)
      .catch((e) => {
        this.logger.error('failed to fetchSignInMethodsForEmail', e);
        return [];
      });
    if (methods.length === 0) {
      this.authStore.clearError();
      this.authStore.updateError({
        sendSignInLinkFailed: true,
        unableToFindUser: true,
      });
      this.authStore.setLoading(false);
      return;
    }

    const url = this.window.location.href;

    const actionCodeSettings: ActionCodeSettings = {
      url,
      handleCodeInApp: true,
    };

    this.authPersistedService.persistEmailUsedForSignInLink(email);
    await this.afAuth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        this.authStore.clearError();
        this.authStore.update({
          signInLinkLastSentTimestamp: Date.now(),
        });
      })
      .catch((error) => {
        this.authStore.clearError();
        const errorCode = convertFirebaseAuthCode(error.code);
        this.logger.warn('failed to sendSignInLinkToEmail', {
          error,
          errorCode,
        });
        this.authStore.updateError({
          sendSignInLinkFailed: true,
          [errorCode]: true,
        });
      });

    // todo waiting on another PR #845 for custom mail
    // await this.sendVerificationEmail.call({ email }).toPromise();

    this.authStore.setLoading(false);
  }

  async updateUserPassword(): Promise<undefined | keyof typeof AuthErrors> {
    this.authStore.setLoading(true);
    this.authStore.clearError();
    const { enteredPassword } = this.authStore.getValue();
    const user = await this.afAuth.currentUser;

    if (!user) {
      this.authStore.updateError({
        linkWithCredentialFailed: true,
        noUserToLinkWithCredential: true,
      });
      this.authStore.setLoading(false);
      return 'noUserToLinkWithCredential';
    }

    if (user.isAnonymous) {
      this.authStore.updateError({
        linkWithCredentialFailed: true,
        noSignedInUserToLinkWithCredential: true,
      });
      this.authStore.setLoading(false);
      return 'noSignedInUserToLinkWithCredential';
    }

    if (!enteredPassword) {
      this.authStore.updateError({
        linkWithCredentialFailed: true,
        missingPassword: true,
      });
      this.authStore.setLoading(false);
      return 'missingPassword';
    }

    const error = await user
      .updatePassword(enteredPassword)
      .then(() => {
        this.authStore.update((state) => {
          return {
            ...state,
            enteredPassword: undefined,
            passwordUpdateSuccessful: true,
          };
        });
        this.mixpanel.setProfilePropertyAuthType('password');
        return undefined;
      })
      .catch((error) => {
        const errorCode = convertFirebaseAuthCode(error.code);
        this.logger.error('failed to updateUserPassword', {
          error,
          errorCode,
        });
        this.authStore.updateError({
          linkWithCredentialFailed: true,
          [errorCode]: true,
        });
        return errorCode;
      });

    this.authStore.setLoading(false);
    return error;
  }

  async signOut(
    options: {
      withRedirect?: boolean;
      withMixpanelReset?: boolean;
      withKeepErrors?: boolean;
      withoutSocialRedirectReset?: boolean;
    } = {
      withRedirect: true,
      withMixpanelReset: true,
      withKeepErrors: false,
      withoutSocialRedirectReset: false,
    }
  ) {
    this.authStore.setLoading(true);
    if (!options.withKeepErrors) {
      this.authStore.clearError();
    }
    await this.afAuth
      .signOut()
      .then(() => {
        const errors = (
          this.authStore.getValue() as { error: typeof AuthErrors }
        )?.error;
        this.authStore.reset();
        /*
         * User logged out manually and store is reset.
         * LoginPage expects a value true/false and not undefined
         * before navigating.
         * Value is set to false since LoginPage has no redirect it should handle.
         * Resets state by default but can be skipped if reset is handled
         * somewhere else
         */
        if (!options.withoutSocialRedirectReset) {
          this.resetRedirectResultState();
        }
        if (options.withKeepErrors) {
          this.authStore.setError(errors);
        }
        if (options.withMixpanelReset) {
          this.mixpanelService.reset();
        }
        if (options.withRedirect) {
          this.router.navigate([appRoutes.login.path]).catch((error) => {
            this.logger.error('failed to navigate to login', {
              error,
            });
          });
        }
      })
      .catch((error) => {
        const errorCode = convertFirebaseAuthCode(error.code);
        this.logger.warn('failed to signOut', {
          error,
          errorCode,
        });
        this.authStore.updateError({
          signOutFailed: true,
          [errorCode]: true,
        });
      });

    this.authStore.setLoading(false);
  }

  updateEnteredEmail(email: string) {
    this.authStore.update((state) => {
      return { ...state, enteredEmail: email };
    });
    this.authStore.clearError('email');
  }

  updateEnteredPassword(password: string) {
    this.authStore.update((state) => {
      return { ...state, enteredPassword: password };
    });
    this.authStore.clearError('password');
  }

  updatePasswordUpdateSuccessful(success: boolean | undefined) {
    this.authStore.update({ passwordUpdateSuccessful: success });
  }

  // disable lint to be with all other update methods:
  // eslint-disable-next-line @typescript-eslint/member-ordering
  updateEnteredBirthdate(birthdate: DateString) {
    this.authStore.update((state) => {
      return { ...state, enteredBirthdate: birthdate };
    });
  }

  updateSelectedSocialProvider(provider: SupportedSocialProviders | undefined) {
    this.authStore.update((state) => {
      return { ...state, selectedSocialProvider: provider };
    });
  }

  async updateEnteredPasswordWithRandom() {
    const randomPassword = await this.generateRandomPassword();
    this.updateEnteredPassword(randomPassword);
  }

  updateEmailAlreadyInUseOnOfferSlide(emailAlreadyInUseOnOfferSlide: boolean) {
    this.authStore.update({ emailAlreadyInUseOnOfferSlide });
  }

  updateRedirectResultState(options: {
    withShouldHandleSuccessfulLink?: boolean;
    withShouldHandleSuccessfulLogin?: boolean;
    withShouldHandleUnsuccessfulLink?: boolean;
    withShouldHandleUnsuccessfulLogin?: boolean;
    withShouldntHandleLink?: boolean;
    withShouldntHandleLogin?: boolean;
  }) {
    let redirectResultState: {
      shouldHandleSocialLink?: boolean;
      shouldHandleSocialLogin?: boolean;
      socialLinkSuccessful?: boolean;
      socialLoginSuccessful?: boolean;
    } = {
      shouldHandleSocialLink: false,
      shouldHandleSocialLogin: false,
      socialLinkSuccessful: undefined,
      socialLoginSuccessful: undefined,
    };

    if (options.withShouldHandleSuccessfulLink) {
      redirectResultState = {
        ...redirectResultState,
        shouldHandleSocialLink: true,
        socialLinkSuccessful: true,
      };
    }
    if (options.withShouldHandleSuccessfulLogin) {
      redirectResultState = {
        ...redirectResultState,
        shouldHandleSocialLogin: true,
        socialLoginSuccessful: true,
      };
    }
    if (options.withShouldHandleUnsuccessfulLink) {
      redirectResultState = {
        ...redirectResultState,
        shouldHandleSocialLink: true,
        socialLinkSuccessful: false,
      };
    }
    if (options.withShouldHandleUnsuccessfulLogin) {
      redirectResultState = {
        ...redirectResultState,
        shouldHandleSocialLogin: true,
        socialLoginSuccessful: false,
      };
    }
    if (options.withShouldntHandleLink) {
      redirectResultState = {
        ...redirectResultState,
        shouldHandleSocialLink: false,
        socialLinkSuccessful: undefined,
      };
    }
    if (options.withShouldntHandleLogin) {
      redirectResultState = {
        ...redirectResultState,
        shouldHandleSocialLogin: false,
        socialLoginSuccessful: undefined,
      };
    }

    this.authStore.update((s) => ({
      ...s,
      socialRedirectResult: {
        ...s.socialRedirectResult,
        ...redirectResultState,
      },
    }));
  }

  resetRedirectResultState() {
    this.authStore.update((s) => ({
      ...s,
      socialRedirectResult: {
        ...s.socialRedirectResult,
        shouldHandleSocialLink: false,
        shouldHandleSocialLogin: false,
        socialLinkSuccessful: undefined,
        socialLoginSuccessful: undefined,
      },
    }));
  }

  updateIsHandlingSocialRedirectResult(isHandling: boolean) {
    this.authStore.update((s) => ({
      ...s,
      socialRedirectResult: {
        ...s.socialRedirectResult,
        isHandling,
      },
    }));
  }

  async handleSuccessfulSocialLinkRedirectResult(
    provider: SupportedSocialProviders
  ) {
    this.updateSelectedSocialProvider(provider);
    this.mixpanel.setProfilePropertyAuthType('social');
    this.updateRedirectResultState({
      withShouldHandleSuccessfulLink: true,
    });
  }

  async handleSuccessfulSocialLoginRedirectResult(
    provider: SupportedSocialProviders,
    isNewUser: boolean
  ) {
    this.updateSelectedSocialProvider(provider);
    if (isNewUser) {
      const user = await this.afAuth.currentUser;
      await user?.delete();
      this.authStore.updateError({
        signInWithSocialProviderFailed: true,
        socialProviderClientDoesNotExist: true,
      });
      this.mixpanel.sendEvent(
        new AuthMethodsEvent(
          AuthMethodsEventName.SignInWithSocialPageClientDoesNotExistError,
          {
            authType: provider,
            buildHash: buildMeta.commitHash,
          }
        )
      );
      await this.signOut({
        withRedirect: false,
        withMixpanelReset: false,
        withKeepErrors: true,
        withoutSocialRedirectReset: true,
      });
      this.updateRedirectResultState({
        withShouldHandleUnsuccessfulLogin: true,
      });
    } else {
      this.mixpanel.sendEvent(
        new AuthMethodsEvent(
          AuthMethodsEventName.SignInWithSocialPageSocialLoginSuccessful,
          { authType: provider, buildHash: buildMeta.commitHash }
        )
      );
      this.updateRedirectResultState({
        withShouldHandleSuccessfulLogin: true,
      });
    }
  }

  showLogin() {
    return this.router.navigate(['/login'], {
      queryParams: { continue: appRoutes.myaccount.path },
    });
  }

  resetSignInLinkLastSentTimestamp() {
    this.authStore.update({ signInLinkLastSentTimestamp: undefined });
  }

  /**
   * @note async interface in case we want to switch this implementation
   * with some more advanced, async generator
   */
  private async generateRandomPassword() {
    try {
      const randomString = (
        this.window.crypto as { randomUUID?: () => string }
      )?.randomUUID?.();
      if (randomString) {
        return randomString;
      }
      throw new Error('crypto.randomUUID not returning a value');
    } catch (error) {
      this.logger.warn(
        '`crypto.randomUUID` failed, trying `crypto.getRandomValues`',
        error
      );
    }

    const randomInts = new Uint32Array(10);
    this.window.crypto.getRandomValues(randomInts);
    const randomString = randomInts.join('-').toString();
    return randomString;
  }

  getAuthProviderForProviderId(provider: SupportedSocialProviders) {
    switch (provider) {
      case 'google.com':
        return new GoogleAuthProvider();
      case 'twitter.com':
        return new TwitterAuthProvider();
      case 'facebook.com':
        return new FacebookAuthProvider();
      default:
        throw new Error(
          'failed to get AuthProvider for unsupported social provider'
        );
    }
  }
}
