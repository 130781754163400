import { Injectable } from '@angular/core';
import { Client } from '@expresssteuer/models';
import {
  FirebaseDocumentQuery,
  MultiLoadableQueryMixin,
  selectReader,
} from '@expresssteuer/state-angular';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ClientError,
  ClientLoader,
  ClientState,
  ClientStore,
} from './client.store';

class Base extends FirebaseDocumentQuery<
  Client,
  undefined,
  ClientError,
  ClientLoader
> {}

class MixedQuery extends MultiLoadableQueryMixin(Base) {}

@Injectable({ providedIn: 'root' })
export class ClientQuery extends MixedQuery {
  constructor(protected store: ClientStore) {
    super(store);
  }

  firstname$ = this.selectFromDataWithUnsavedChanges$(
    (client) => client?.firstname
  );
  lastname$ = this.selectFromDataWithUnsavedChanges$(
    (client) => client?.lastname
  );
  birthdate$ = this.selectFromDataWithUnsavedChanges$(
    (client) => client?.birthdate
  );
  currentAddress$ = this.selectFromDataWithUnsavedChanges$(
    (client) => client?.addressClient
  );
  phone$ = this.selectFromDataWithUnsavedChanges$((client) => client?.mobile);
  email$ = this.selectFromDataWithUnsavedChanges$((client) => client?.email);
  iban$ = this.selectFromDataWithUnsavedChanges$(
    (client) => client?.bank?.IBAN
  );
  emailVerified$ = this.selectReader((s) => s.data?.emailVerified);
  status$ = this.selectFromDataWithUnsavedChanges$((client) => client?.status);

  /**
   * @deprecated not used for client, use `select('loaders')` instead as properties can be saved individually
   */
  isSaving$ = this.isLoading$;

  untilIsInitialized(): Promise<boolean> {
    return firstValueFrom(
      this.isLoading$.pipe(filter((isLoading) => !isLoading))
    );
  }

  private selectReader<T>(selector: (state: ClientState) => T) {
    return selectReader(this.store, this, selector);
  }
}
