export type AsyncFunction<T> = () => Promise<T>;

/**
 * Represents a queue for executing async functions sequentially.
 */
export class AsyncQueue {
  /**
   * The queue of async functions to execute.
   */
  private queue: AsyncFunction<unknown>[] = [];

  /**
   * Whether the queue is currently being processed.
   */
  private isProcessing = false;

  /**
   * Adds an async function to the end of the queue and returns a Promise that resolves when the task has completed.
   * @param task The async function to add.
   * @returns A Promise that resolves when the task has been completed.
   */
  enqueue<T>(task: AsyncFunction<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.queue.push(async () => {
        try {
          const res = await task();
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
      if (!this.isProcessing) {
        void this.processQueue();
      }
    });
  }

  /**
   * Processes the queue by executing each async function in turn.
   */
  private async processQueue(): Promise<void> {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const task = this.queue.shift();
      if (task) {
        await task();
      }
    }
    this.isProcessing = false;
  }
}
