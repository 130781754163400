import { Inject, Injectable, InjectionToken, Provider } from '@angular/core';
import { guid, Query, Store } from '@datorama/akita';

/**
 * Bindable helper for a property on a store
 */
export class TwoWayAkitaBinding<TState, TKey extends keyof TState> {
  constructor(
    public readonly key: TKey,
    private store: Store<TState>,
    private query: Query<TState>
  ) {}

  /**
   * Get the `TState[this.key]` value from the store
   */
  get value() {
    return this.store.getValue()[this.key];
  }

  /**
   * Update the store's `TState[this.key]` value
   */
  set value(val: TState[this['key']]) {
    this.setValue(val);
  }

  /**
   * Observable stream from the query's  `TState[this.key]` value
   */
  readonly value$ = this.query.select(this.key);

  /**
   * Update the store's `TState[this.key]` value
   */
  setValue(val: TState[TKey]): void {
    const update: Partial<TState> = {
      [this.key]: val,
    } as unknown as Partial<TState>; // TODO why is casting necessary here?
    this.store.update(update);
  }

  // disable ordering to couple instance operations away from the factory
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static twoWayAkitaBindingFactory<TState, TReturn>(
    store: Store<TState>,
    query: Query<TState>,
    bindingsBuilder: (
      bindingBuilder: (
        key: keyof TState
      ) => TwoWayAkitaBinding<TState, typeof key>
    ) => TReturn
  ) {
    function bindingBuilder(key: keyof TState) {
      return new TwoWayAkitaBinding(key, store, query);
    }
    return bindingsBuilder(bindingBuilder);
  }

  // disable ordering to couple instance operations away from the factory
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static twoWayAkitaBindingFromPropsFactory<
    TState extends DefaultEasyUiState,
    TProps extends keyof TState
  >(store: Store<TState>, query: Query<TState>, props: TProps[]) {
    return TwoWayAkitaBinding.twoWayAkitaBindingFactory(
      store,
      query,
      (bindingBuilder) => {
        const bindings = props.reduce((acc, curr) => {
          (acc as any)[curr] = bindingBuilder(curr);
          return acc;
        }, {} as EasyUiBindings<Partial<TState>>);
        return bindings;
      }
    );
  }
}

/**
 * Map a `Record`'s values to `TwoWayAkitaBinding`s
 */
export type EasyUiBindings<TState extends DefaultEasyUiState> = {
  [k in keyof TState]: TwoWayAkitaBinding<TState, k>;
};

export const EASY_AKITA_UI_BINDINGS = new InjectionToken(
  'EASY_AKITA_UI_BINDINGS'
);
export const EASY_AKITA_UI_STORE = new InjectionToken<
  Store<DefaultEasyUiState>
>('EASY_AKITA_UI_STORE');
export const EASY_AKITA_UI_QUERY = new InjectionToken<
  Query<DefaultEasyUiState>
>('EASY_AKITA_UI_QUERY');
export const EASY_AKITA_UI_STORE_NAME = new InjectionToken<string>(
  'EASY_AKITA_UI_STORE_NAME'
);
export const EASY_AKITA_UI_STORE_INIT_STATE =
  new InjectionToken<DefaultEasyUiState>('EASY_AKITA_UI_STORE_INIT_STATE');

export type DefaultEasyUiState = Partial<
  Record<string | number | symbol, unknown>
>;

export abstract class EasyAkitaUi<TState extends DefaultEasyUiState> {
  abstract readonly providers: Provider[];
  /**
   * @throws as not all implementations implement this with a value.
   * It is basically needed for its return type (@see `StateFrom`).
   */
  abstract readonly initState: TState;

  /**
   * @example
    ```
      const easyAkitaUi = EasyAkitaUi.for<{ enteredEmail: string }>({
        props: ['enteredEmail'],
        providers: [
          {
            provide: EASY_AKITA_UI_STORE,
            useExisting: AuthStore, // assuming the AuthStore has a State with `enteredEmail: string`
          },
          {
            provide: EASY_AKITA_UI_QUERY,
            useExisting: AuthQuery, // assuming the AuthQuery has a State with `enteredEmail: string`
          },
        ],
      });

      \@Component({
        selector: 'app-root',
        template: `
          <input
            type="string"
            [ngModel]="bindings.enteredEmail.value$ | async"
            (ngModelChange)="bindings.enteredEmail.setValue($event)"
          />
          <input type="string" [(ngModel)]="bindings.enteredEmail.value" />
          <pre>
            value:  {{ bindings.enteredEmail.value }}
            value$: {{ bindings.enteredEmail.value$ | async }}
          </pre>
        `,
        providers: [...easyAkitaUi.providers],
      })
      export class AppComponent {
        constructor(
          \@Inject(EASY_AKITA_UI_BINDINGS)
          public bindings: BindingsFrom<typeof easyAkitaUi>
        ) {}
      }
    ```
   */
  public static for<TState extends DefaultEasyUiState>(args: {
    props: (keyof TState)[];
    providers?: Provider[];
  }): EasyAkitaUi<TState>;
  /**
   * @example
    ```
      const easyAkitaUi = EasyAkitaUi.for({
        initState: {
          n: 22,
          b: undefined as string | undefined,
        },
        storeName: 'my-store',
      });

      \@Component({
        selector: 'app-root',
        template: `
          <input
            type="number"
            [ngModel]="bindings.n.value$ | async"
            (ngModelChange)="bindings.n.setValue($event)"
          />
          <input type="number" [(ngModel)]="bindings.n.value" />
          <pre>
          value:  {{ bindings.n.value }}
          value$: {{ bindings.n.value$ | async }}
        </pre
          >
        `,
        providers: [...easyAkitaUi.providers],
      })
      export class AppComponent {
        constructor(
          \@Inject(EASY_AKITA_UI_BINDINGS)
          public bindings: BindingsFrom<typeof easyAkitaUi>
        ) {}
      }
    ```
   */
  public static for<TState extends DefaultEasyUiState>(args: {
    initState: TState;
    storeName?: string;
  }): EasyAkitaUi<TState>;
  public static for<TState extends DefaultEasyUiState>({
    props,
    providers,
    initState,
    storeName,
  }:
    | {
        props: (keyof TState)[];
        providers?: Provider[];
        initState?: undefined;
        storeName?: undefined;
      }
    | {
        initState: TState;
        storeName?: string;
        props?: undefined;
        providers?: undefined;
      }) {
    if (Array.isArray(props)) {
      return EasyAkitaUi.withCustomProvidersBuilder(props, providers);
    }
    if (typeof initState === 'object') {
      return EasyAkitaUi.withDefaultProvidersBuilder(initState, storeName);
    }
    throw new Error('EasyAkitaUi.for called with an unknown signature');
  }

  private static withCustomProvidersBuilder<TState extends DefaultEasyUiState>(
    props: (keyof TState)[],
    providers: Provider[] = []
  ): EasyAkitaUi<TState> {
    return {
      get initState(): TState {
        throw new Error(
          'EasyAkitaUi with custom providers has no access to initState'
        );
      },
      providers: [
        ...providers,
        {
          provide: EASY_AKITA_UI_BINDINGS,
          useFactory: (store: Store<TState>, query: Query<TState>) => {
            return TwoWayAkitaBinding.twoWayAkitaBindingFromPropsFactory(
              store,
              query,
              props
            );
          },
          deps: [EASY_AKITA_UI_STORE, EASY_AKITA_UI_QUERY],
        },
      ],
    };
  }

  private static withDefaultProvidersBuilder<TState extends DefaultEasyUiState>(
    initState: TState,
    storeName?: string
  ): EasyAkitaUi<TState> {
    return {
      providers: EasyAkitaUi.withCustomProvidersBuilder(
        Object.keys(initState),
        [
          { provide: EASY_AKITA_UI_STORE, useClass: EasyUiStore },
          { provide: EASY_AKITA_UI_QUERY, useClass: EasyUiQuery },
          { provide: EASY_AKITA_UI_STORE_INIT_STATE, useValue: initState },
          { provide: EASY_AKITA_UI_STORE_NAME, useValue: storeName ?? guid() },
        ]
      ).providers,
      get initState(): TState {
        return { ...initState };
      },
    };
  }
}

export type StateFrom<T extends { initState: DefaultEasyUiState }> =
  T['initState'];

export type BindingsFrom<T extends { initState: DefaultEasyUiState }> =
  EasyUiBindings<StateFrom<T>>;

@Injectable()
export class EasyUiStore extends Store<DefaultEasyUiState> {
  constructor(
    @Inject(EASY_AKITA_UI_STORE_INIT_STATE) initState: DefaultEasyUiState,
    @Inject(EASY_AKITA_UI_STORE_NAME) storeName: string
  ) {
    super(initState, { name: `easyui-${storeName}`, resettable: true });
  }
}

@Injectable()
export class EasyUiQuery extends Query<DefaultEasyUiState> {
  constructor(@Inject(EASY_AKITA_UI_STORE) store: EasyUiStore) {
    super(store);
  }
}
