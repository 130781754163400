import { Injectable } from '@angular/core';

import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ClientTodoParamsQuery } from './client-todo-params.query';

@Injectable({ providedIn: 'root' })
export class ClientTodoParamsService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  static getUrlNavigationExtrasFor(id?: string): NavigationExtras {
    return {
      queryParams: {
        [ClientTodoParamsQuery.ACTIVE_CLIENT_TODO_PARAM_NAME]: id || undefined,
      },
      preserveFragment: true,
      queryParamsHandling: 'merge',
    };
  }

  clearActiveClientTodo() {
    return this.setActiveClientTodo(undefined);
  }

  setActiveClientTodo(id?: string) {
    if (
      this.activatedRoute.snapshot.queryParamMap.get(
        ClientTodoParamsQuery.ACTIVE_CLIENT_TODO_PARAM_NAME
      ) === id
    ) {
      return Promise.resolve(false);
    }
    return this.router.navigate(
      [],
      ClientTodoParamsService.getUrlNavigationExtrasFor(id)
    );
  }
}
