import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { GoogleMapsApiService } from '@expresssteuer/google-maps-api-loader';
import { FeatureTogglesQuery } from '@expresssteuer/state-angular';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { Subscription, map } from 'rxjs';
import { environment } from '../environments/environment';
import { appRoutes } from './app-routing.module';
import { ABTestConfigQuery } from './state/a-b-test/a-b-test-config.query';
import { HotjarService } from './state/analytics/hotjar.service';
import { LegacyAnalyticsService } from './state/analytics/legacy-analytics.service';
import { MixpanelService } from './state/analytics/mixpanel.service';
import { SentryService } from './state/analytics/sentry.service';
import { AuthQuery } from './state/auth/auth.query';
import { AuthService } from './state/auth/auth.service';
import { BrazeService } from './state/braze/braze.service';
import { CookiebotService } from './state/cookiebot/cookiebot.service';
import { IntercomService } from './state/intercom/intercom.service';
import { VerificationService } from './state/verification/verification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [EsuiLoggerService],
})
export class AppComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  /*
   Show loading spinner only while we haven't decided if we should
   display the new or old signup funnel.
   */
  initialLoading$ = this.abTestConfigQuery.isLoading$.pipe(
    map((isLoading) => {
      if (isLoading) {
        const nonFunnelPaths = Object.values(appRoutes)
          .map((routes) => routes.path)
          .filter((path) => path !== appRoutes.funnel.path);
        const currentPath =
          this.documentRef.defaultView?.location.pathname.split('/')[1] ?? '';
        const isFunnel = !nonFunnelPaths.includes(currentPath);
        return isFunnel;
      } else {
        return false;
      }
    })
  );

  constructor(
    private authService: AuthService,
    public authQuery: AuthQuery,
    private verificationService: VerificationService,
    private analyticsService: LegacyAnalyticsService,
    private afStorage: AngularFireStorage,
    private cookiebotService: CookiebotService,
    private brazeService: BrazeService,
    private mixpanelService: MixpanelService,
    private sentryService: SentryService,
    public featureTogglesQuery: FeatureTogglesQuery,
    public abTestConfig: ABTestConfigQuery,
    private googleMapsApiService: GoogleMapsApiService,
    private logger: EsuiLoggerService,
    private abTestConfigQuery: ABTestConfigQuery,
    private intercomService: IntercomService,
    @Inject(DOCUMENT) private documentRef: Document,
    private hotjarService: HotjarService
  ) {
    this.logger.registerOwner(this);
  }

  ngOnInit(): void {
    void this.intercomService
      .loadIntercom({
        api_base: environment.intercom.api_base,
        app_id: environment.intercom.app_id,
      })
      .catch((_) => {
        this.logger.error('failed to loadIntercom Intercom');
      });
    this.googleMapsApiService.loadGoogleMapsApiScript();
    this.cookiebotService.init();
    this.analyticsService.init();
    this.subs.push(
      this.authService.sync().subscribe(),
      this.analyticsService.syncAll().subscribe(),
      this.brazeService.sync().subscribe(),
      this.mixpanelService.sync().subscribe(),
      this.sentryService.syncUser().subscribe(),
      this.intercomService.syncAll().subscribe(),
      this.hotjarService.syncAll().subscribe()
    );
    this.featureTogglesQuery
      .untilFeatureToggles()
      .then(({ enableEmailVerification }) => {
        if (!enableEmailVerification) {
          return;
        }
        return this.verificationService.tryToVerifyEmailAddress();
      })
      .catch((error) => {
        this.logger.error('failed to tryToVerifyEmailAddress', {
          error,
        });
      });
    if (environment.emulator) {
      this.afStorage.storage.useEmulator('localhost', 9199);
      console.warn('Using storage emulator');
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subs = [];
  }
}
