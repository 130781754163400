<div
  class="date-field"
  [ngClass]="{
    'date-field--disabled': disabled,
    'date-field--error': isInvalid && (isTouched || showErrorsDespitePristine),
    'date-field--code': type === 'code',
    'date-field--form': type === 'form'
  }"
>
  <div class="date-field__textfield-groups">
    <p class="date-field__label">{{ label }}</p>
    <div
      class="date-field__textfield"
      [ngClass]="{
        'date-field__textfield--error': !dayControl.valid && dayControl.dirty
      }"
    >
      <input
        class="date-field__input"
        type="tel"
        [name]="dayName"
        [formControl]="dayControl"
        maxlength="2"
        pattern="^[0-9]*$"
        [placeholder]="dayPlaceholder"
        (blur)="onBlur()"
        [required]="validityRequired"
        #day
      />
    </div>
    <div class="date-field__strike"></div>
    <div
      class="date-field__textfield"
      [ngClass]="{
        'date-field__textfield--error':
          !monthControl.valid && monthControl.dirty
      }"
    >
      <input
        class="date-field__input"
        type="tel"
        [name]="monthName"
        [formControl]="monthControl"
        maxlength="2"
        pattern="^[0-9]*$"
        min="1"
        [placeholder]="monthPlaceholder"
        (blur)="onBlur()"
        [required]="validityRequired"
        #month
      />
    </div>
    <div class="date-field__strike"></div>
    <div
      class="date-field__textfield"
      [ngClass]="{
        'date-field__textfield--error':
          !yearControl.valid && yearControl.touched
      }"
    >
      <input
        class="date-field__input"
        type="tel"
        [name]="yearName"
        [formControl]="yearControl"
        minlength="4"
        maxlength="4"
        pattern="^[0-9]*$"
        [placeholder]="yearPlaceholder"
        (blur)="onBlur()"
        [required]="validityRequired"
        #year
      />
    </div>
  </div>
  <ng-container *ngIf="(isTouched || showErrorsDespitePristine) && isInvalid">
    <div *ngIf="errorText" class="error-text">
      {{ errorText }}
    </div>
    <div *ngIf="renderErrorContent" class="error-text">
      <ng-content select="[errorContent]"></ng-content>
    </div>
  </ng-container>
</div>
