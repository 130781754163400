import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Store, StoreConfig } from '@datorama/akita';
import { DateString } from '@expresssteuer/models';
import { SupportedSocialProviders } from './auth.service';

export interface AuthState {
  firebaseUser?: User | null;

  enteredEmail?: string;
  enteredPassword?: string;
  passwordUpdateSuccessful?: boolean;

  enteredBirthdate?: DateString;

  signInLinkLastSentTimestamp?: number;
  signInLinkWasAutoRequested?: boolean;
  emailAlreadyInUseOnOfferSlide?: boolean;
  birthdateTrialsLeft?: number;

  /**
   * Indicates if a potential redirect after user logged in with social
   * should be handled or not and if they were successful.
   * Locations where redirects are typically handled are LinkSocialPasswordComponent
   * and LoginPage.
   */
  socialRedirectResult?: {
    shouldHandleSocialLink?: boolean;
    shouldHandleSocialLogin?: boolean;
    socialLinkSuccessful?: boolean;
    socialLoginSuccessful?: boolean;
    isHandling?: boolean;
  };
  selectedSocialProvider?: SupportedSocialProviders;
}

const initialState: () => AuthState = () => ({
  // password: '',
  // email: '',
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(initialState());
  }

  updateError(newErrors: Partial<typeof AuthErrors>) {
    const oldErrors = (this.getValue() as { error: typeof AuthErrors })?.error;
    this.setError({ ...oldErrors, ...newErrors });
  }

  clearError(
    type: 'email' | 'password' | 'birthdate' | 'social' | 'all' = 'all'
  ) {
    switch (type) {
      case 'email':
        this.updateError({
          missingEmail: false,
          emailAlreadyInUse: false,
          invalidEmail: false,
          unverifiedEmail: false,
          unableToFindUser: false,
        });
        break;
      case 'password':
        this.updateError({
          missingPassword: false,
          wrongPassword: false,
          weakPassword: false,
        });
        break;
      case 'birthdate':
        this.updateError({
          authBirthdateGenericAuthFailed: false,
          authBirthdateMissingBirthdate: false,
          authBirthdateContactSupport: false,
          authBirthdateAccountLocked: false,
          authBirthdateAuthFailedRetries: false,
          authBirthdateAuthLinkExpired: false,
        });
        break;
      case 'social':
        this.updateError({
          socialProviderAlreadyInUse: false,
          socialProviderClientDoesNotExist: false,
          socialProviderGeneric: false,
        });
        break;
      case 'all':
        this.setError(null);
        break;
    }
  }
}
export const AuthEmailErrors = {
  missingEmail: true,
  emailAlreadyInUse: true,
  invalidEmail: true,
  unverifiedEmail: true,
  unableToFindUser: true,
};

export const AuthPasswordErrors = {
  missingPassword: true,
  wrongPassword: true,
  weakPassword: true,
};

export const AuthBirthdateErrors = {
  authBirthdateGenericAuthFailed: true,
  authBirthdateMissingBirthdate: true,
  authBirthdateContactSupport: true,
  authBirthdateAccountLocked: true,
  authBirthdateAuthFailedRetries: true,
  authBirthdateAuthLinkExpired: true,
};

export const AuthSocialErrors = {
  socialProviderAlreadyInUse: true,
  socialProviderClientDoesNotExist: true,
  socialProviderGeneric: true,
  emailAlreadyInUse: true,
};

export const AuthPhoneErrors = {
  invalidPhoneNumber: true,
  missingPhoneNumber: true,
};
export const AuthOtherErrors = {
  expired: true,
  invalidVerificationCode: true,
};
export const AuthInternalErrors = {
  authInternal: true,
  signOutFailed: true,
  sendSignInLinkFailed: true,
  linkWithCredentialFailed: true,
  syncFailed: true,
  noUserToLinkWithCredential: true,
  noSignedInUserToLinkWithCredential: true,
  signInWithEmailAndPasswordFailed: true,
  signInWithEmailLinkFailed: true,
  signInAnonymouslyFailed: true,
  signInWithBirthdateFailed: true,
  signInWithSocialProviderFailed: true,
};

export const AuthErrors = {
  ...AuthInternalErrors,
  ...AuthEmailErrors,
  ...AuthPasswordErrors,
  ...AuthBirthdateErrors,
  ...AuthSocialErrors,
  ...AuthPhoneErrors,
  ...AuthOtherErrors,
};
