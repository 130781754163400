import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayModule } from '../components/overlay/overlay.module';
import { ContractStyleWrapperModule } from './contract-wrapper/contract-style-wrapper.module';
import { LegalOverlaysComponent } from './legal-overlays.component';

@NgModule({
  declarations: [LegalOverlaysComponent],
  imports: [CommonModule, OverlayModule, ContractStyleWrapperModule],
  exports: [LegalOverlaysComponent],
})
export class LegalOverlaysModule {}
