import { Component } from '@angular/core';
import { buildMeta } from '../../../build-meta/build-meta';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss'],
})
export class MetadataComponent {
  buildMeta = buildMeta;
}
