import { discriminatedUnion, input } from 'zod';
import { EstimateTaxReturnInputV1Zod } from './estimate-tax-return.v1';

export type EstimateTaxReturnInput = input<
  typeof EstimateTaxReturnInputAnyVZod
>;

export interface EstimateTaxReturnOutput {
  /**
   * The "exact" estimation of the tax return. Should have 2 fractions
   */
  estimatedTaxReturn: number;

  /**
   * To show a range of the estimated tax return (no fractions) (lower limit)
   */
  estimatedTaxReturnLowerLimit: number;
  /**
   * To show a range of the estimated tax return (no fractions) (upper limit)
   */
  estimatedTaxReturnUpperLimit: number;
}

export const EstimateTaxReturnInputLatestZod = EstimateTaxReturnInputV1Zod;
export const EstimateTaxReturnInputAnyVZod = discriminatedUnion('version', [
  EstimateTaxReturnInputV1Zod,
]);
