import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { ClientTodoId } from '@expresssteuer/client-todos-api-interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientTodoParamsQuery {
  static ACTIVE_CLIENT_TODO_PARAM_NAME = 'activeClientTodo';

  constructor(private activatedRoute: ActivatedRoute) {}

  public activeClientTodo$: Observable<ClientTodoId | undefined> =
    this.activatedRoute.queryParams.pipe(
      map((params) => {
        return ClientTodoParamsQuery.activeClientTodo(params);
      })
    );

  private static activeClientTodo(
    params: Record<string, unknown>
  ): ClientTodoId | undefined {
    if (!(ClientTodoParamsQuery.ACTIVE_CLIENT_TODO_PARAM_NAME in params)) {
      return undefined;
    }

    const paramValue = params[
      ClientTodoParamsQuery.ACTIVE_CLIENT_TODO_PARAM_NAME
    ] as string;

    return (paramValue || undefined) as ClientTodoId | undefined;
  }

  public getActiveClientTodo() {
    return ClientTodoParamsQuery.activeClientTodo(
      this.activatedRoute?.snapshot?.queryParams
    );
  }
}
