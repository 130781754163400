import { Injectable } from '@angular/core';
import JSONUrl from '@jsonurl/jsonurl';
import { map } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { FeatureToggles } from '@expresssteuer/models';

@Injectable({ providedIn: 'root' })
export class EnforcedFeatureTogglesParamsQuery {
  static IS_TEST_PARAM_NAME = 'featureToggles';
  public featureToggles$ = this.activatedRoute.queryParams.pipe(
    map((params) => {
      return EnforcedFeatureTogglesParamsQuery.featureToggles(params);
    })
  );

  constructor(private activatedRoute: ActivatedRoute) {}

  private static featureToggles(
    params: Record<string, unknown>
  ): Partial<FeatureToggles> | undefined {
    if (!(EnforcedFeatureTogglesParamsQuery.IS_TEST_PARAM_NAME in params)) {
      return undefined;
    }

    const paramValue = params[
      EnforcedFeatureTogglesParamsQuery.IS_TEST_PARAM_NAME
    ] as any;

    return JSONUrl.parse(paramValue) as Partial<FeatureToggles>;
  }

  public getFeatureToggles() {
    return EnforcedFeatureTogglesParamsQuery.featureToggles(
      this.activatedRoute?.snapshot?.queryParams
    );
  }
}
