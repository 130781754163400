import { Injectable } from '@angular/core';
import { AuthPersistedStore } from './auth-persisted.store';

@Injectable({ providedIn: 'root' })
export class AuthPersistedService {
  constructor(private authPersistedStore: AuthPersistedStore) {}

  persistEmailUsedForSignInLink(email: string) {
    this.authPersistedStore.update({ emailForRequestedAuthLink: email });
  }

  reset() {
    this.authPersistedStore.reset();
  }
}
