import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UrlQuery {
  private logger: EsuiLoggerService;
  private windowRef: Window;

  constructor(
    private router: Router,
    logger: EsuiLoggerService,
    @Inject(DOCUMENT) private documentRef: Document
  ) {
    this.logger = logger.getNewInstance(this);
    if (!this.documentRef.defaultView) {
      this.logger.error('Window not found.');
      throw new Error('no window found');
    }
    this.windowRef = this.documentRef.defaultView;
  }

  url$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(this.router),
    map((event) => {
      if (event instanceof NavigationEnd) {
        return event.urlAfterRedirects;
      } else {
        // handle initial load with router (startWith)
        return (event as Router).url;
      }
    }),
    distinctUntilChanged(),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  urlWithoutQueryParams$ = this.url$.pipe(map((url) => url.split('?')[0]));

  getUrl() {
    return (
      this.windowRef.location.href.substring(
        this.windowRef.location.origin.length
      ) ?? this.router.url
    );
  }
}
