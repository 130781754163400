import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { CookiebotStore } from './cookiebot.store';

type CookiebotConsent = {
  statistics: boolean;
  preferences: boolean;
  marketing: boolean;
};
declare const Cookiebot: { consent: CookiebotConsent };

@Injectable({ providedIn: 'root' })
export class CookiebotService {
  private logger: EsuiLoggerService;

  constructor(
    logger: EsuiLoggerService,
    @Inject(DOCUMENT) private document: Document,
    private store: CookiebotStore
  ) {
    this.logger = logger.getNewInstance(this);
  }

  init() {
    if (!this.document.defaultView) {
      throw new Error('Browser does not support window or globalThis');
    }
    const window = this.document.defaultView;
    window.addEventListener('CookiebotOnAccept', () => {
      this.onAccept(Cookiebot.consent);
    });
    window.addEventListener('CookiebotOnDecline', () => {
      this.onDecline(Cookiebot.consent);
    });
  }

  private onAccept(consent: CookiebotConsent) {
    this.logger.debug('CookiebotOnAccept', consent);
    this.store.update({
      hasResponse: true,
      statisticsAllowed: consent.statistics,
      preferencesAllowed: consent.preferences,
      marketingAllowed: consent.marketing,
    });
  }

  private onDecline(consent: CookiebotConsent) {
    this.logger.debug('CookiebotOnDecline', consent);
    this.store.update({
      hasResponse: true,
      statisticsAllowed: false,
      preferencesAllowed: false,
      marketingAllowed: false,
    });
  }
}
