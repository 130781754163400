import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { buildMeta } from './build-meta/build-meta';
import { environment } from './environments/environment';

if (environment.toggles.useSentry) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.name,
    release: buildMeta.commitHash,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: environment.sentry.tracePropagationTargets,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentry.tracesSampleRate,
    ignoreErrors: [
      'Uncaught (in promise): FirebaseError: Firebase: The action code is invalid.', // is already catched by us
      'Uncaught (in promise): FirebaseError: Firebase: The popup has been closed by the user before finalizing the operation. (auth/popup-closed-by-user).', // is already catched by us,
      'Uncaught (in promise): FirebaseError: Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).', // is already catched by us
    ],
    denyUrls: [
      /\/gtm\.js/i, // Ignore Google Tage Manager
    ],
  });
}

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}
const storage = persistState({
  include: ['auth-persisted.emailForRequestedAuthLink', 'signup'],
});
const providers = [{ provide: 'persistStorage', useValue: storage }];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

suppressVerifiedFalsePositiveWarnings();

function suppressVerifiedFalsePositiveWarnings() {
  const CORE_THEME_WARN_PATTERN = /Could not find Angular Material core theme/;
  const DISABLED_ATTRIBUTE_WARN_PATTERN =
    /It looks like you're using the disabled attribute with a reactive form directive./;
  const warn = console.warn;
  Object.defineProperty(console, 'warn', {
    value: (...params: string[]) => {
      if (
        !CORE_THEME_WARN_PATTERN.test(params[0]) &&
        !DISABLED_ATTRIBUTE_WARN_PATTERN.test(params[0])
      ) {
        warn(...params);
      }
    },
  });
}
