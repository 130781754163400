import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'ed-loading',
  templateUrl: './ed-loading.component.html',
  styleUrls: ['./ed-loading.component.scss'],
})
export class EdLoadingComponent implements OnInit, OnDestroy {
  @Input()
  timeout?: number;
  @Output()
  timeoutFired = new EventEmitter<void>();

  timeout$?: Subscription;

  ngOnInit() {
    if (this.timeout) {
      this.timeout$ = timer(this.timeout).subscribe(() => {
        this.timeoutFired.next();
      });
    }
  }

  ngOnDestroy() {
    this.timeout$?.unsubscribe();
  }
}
