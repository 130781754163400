import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  EstimateTaxReturnInput,
  EstimateTaxReturnOutput,
} from '@expresssteuer/signup-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class EstimateTaxReturn {
  constructor(public afFunctions: AngularFireFunctions) {}

  /**
   * Used to roughly estimate the tax return for a given input
   *
   * @api summary Estimate tax return
   * @api input zod:EstimateTaxReturnInputLatestZod:@expresssteuer/signup-api-interfaces
   * @api consumer andorra,mobileapp
   */
  call(input: EstimateTaxReturnInput) {
    console.log('Calling signup-estimateTaxReturn');
    return this.afFunctions.httpsCallable<
      EstimateTaxReturnInput,
      EstimateTaxReturnOutput
    >('signup-estimateTaxReturn')(input);
  }
}
