import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { abTestKeys, ABTests } from '@expresssteuer/models';
import { combineLatest, of } from 'rxjs';
import { catchError, map, startWith, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EnforcedAbTestParamsQuery } from '../router/enforced-ab-test-params.query';

@Injectable({
  providedIn: 'root',
})
export class ABTestConfigQuery {
  abTestConfigOnceLoaded$ = of(void 0).pipe(
    // switchMap to Promise in pipe so Promise hot after subscribe
    switchMap(() => this.untilIsLoaded()),
    take(1),
    switchMap(() => this.abTestConfig$)
  );

  // based on abTestConfigOnceLoaded$ but handles errors as well
  isLoading$ = this.abTestConfigOnceLoaded$.pipe(
    map(() => false), // emit 'loading = false' once ab test config loaded
    catchError(() => {
      return of(false); // emit 'loading = false' if an error occurs while loading remote config
    }),
    startWith(true) // emit 'loading = true' initially as load is triggered by AngularFireRemoteConfig
  );

  private abTestConfigFromRemoteConfig$ = this.remoteConfig.booleans.pipe(
    map((remoteConfig) => {
      const abTestConfig: Record<string, unknown> = {};
      Object.keys(remoteConfig).forEach((key) => {
        if ((abTestKeys as ReadonlyArray<string>).includes(key)) {
          abTestConfig[key] = remoteConfig[key];
        }
      });
      return abTestConfig;
    })
  );

  /**
   * The AB-Test setup for the current user. The values are initialized through
   * `FireRemoteConfig`. For testing values can be overriden via
   * `environment.enforcedABTests` at build time or a query parameter
   * (ie. `?abTests=(enableQuickFunnel:true)`) at runtime.
   */
  abTestConfig$ = combineLatest([
    this.abTestConfigFromRemoteConfig$,
    this.enforcedAbTestParamsQuery.abTests$,
  ]).pipe(
    map(([abTestConfigFromRemoteConfig, abTestsFromParam]) => {
      return {
        // init with RemoteConfig
        ...abTestConfigFromRemoteConfig,
        // override with built time `environment`
        ...environment.enforcedABTests,
        // override with runtime query param
        ...abTestsFromParam,
      } as ABTests;
    })
  );

  // Add ab test flag here START

  // Example:
  // expXXABTestName$ = this.abTestConfig$.pipe(
  //   map((config) => config.expXXABTestName)
  // );

  exp07SocialButtons$ = this.abTestConfig$.pipe(
    map((config) => config.exp07SocialButtons)
  );

  exp09OptimizationInOneTouch$ = this.abTestConfig$.pipe(
    map((config) => config.exp09OptimizationInOneTouch)
  );

  exp10PhoneVerification$ = this.abTestConfig$.pipe(
    map((config) => config.exp10PhoneVerification)
  );

  enableJigglypuff$ = this.abTestConfig$.pipe(
    map((config) => config.enableJigglypuff)
  );

  exp12VmaInOneTouch$ = this.abTestConfig$.pipe(
    map((config) => config.exp12VmaInOneTouch)
  );

  exp13UploadOptimization$ = this.abTestConfig$.pipe(
    map((config) => config.exp13UploadOptimization)
  );

  exp14TrackpadSignature$ = this.abTestConfig$.pipe(
    map((config) => config.exp14TrackpadSignature)
  );

  exp15BlockingWonderlandNotification$ = this.abTestConfig$.pipe(
    map((config) => config.exp15BlockingWonderlandNotification)
  );

  exp16NewHelpOverlayKycUpload$ = this.abTestConfig$.pipe(
    map((config) => config.exp16NewHelpOverlayKycUpload)
  );

  exp17JobSlideRemoveFields$ = this.abTestConfig$.pipe(
    map((config) => config.exp17JobSlideRemoveFields)
  );

  exp18ResultSlideAlwaysShowBulletPoints$ = this.abTestConfig$.pipe(
    map((config) => config.exp18ResultSlideAlwaysShowBulletPoints)
  );

  exp19JobSlideSplit$ = this.abTestConfig$.pipe(
    map((config) => config.exp19JobSlideSplit)
  );

  exp20Poa$ = this.abTestConfig$.pipe(map((config) => config.exp20Poa));

  useSignUpSuccessModal$ = this.abTestConfig$.pipe(
    map((config) => config.useSignUpSuccessModal)
  );

  exp21Referrals$ = this.abTestConfig$.pipe(
    map((config) => config.exp21Referrals)
  );

  // Add ab test flag here END

  constructor(
    private remoteConfig: AngularFireRemoteConfig,
    private enforcedAbTestParamsQuery: EnforcedAbTestParamsQuery
  ) {}

  untilIsLoaded() {
    // AngularFireRemoteConfig Observables complete after fetch is done
    return this.remoteConfig.changes.pipe(map(() => null)).toPromise();
  }
}
