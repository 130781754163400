import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GetUserHash } from '@expresssteuer/intercom-api-angular';
import { FeatureTogglesQuery } from '@expresssteuer/state-angular';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import {
  combineLatest,
  filter,
  firstValueFrom,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { AuthQuery } from '../auth/auth.query';
import { IntercomQuery } from './intercom.query';
import { IntercomStore } from './intercom.store';

@Injectable({ providedIn: 'root' })
export class IntercomService {
  private logger: EsuiLoggerService;

  private get Intercom() {
    return (this.document.defaultView as any)?.Intercom;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    logger: EsuiLoggerService,
    private intercomStore: IntercomStore,
    private intercomQuery: IntercomQuery,
    private authQuery: AuthQuery,
    private router: Router,
    private getUserHash: GetUserHash,
    private featureTogglesQuery: FeatureTogglesQuery
  ) {
    this.logger = logger.getNewInstance(this);
  }

  syncUserHash$ = combineLatest({
    user: this.authQuery.user$,
    enableIntercom: this.featureTogglesQuery.enableIntercom$,
  }).pipe(
    filter(({ enableIntercom }) => enableIntercom),
    switchMap(({ user }) => {
      if (user?.isAnonymous !== false) {
        return Promise.resolve(null);
      }
      return this.getUserHash.call();
    }),
    tap((res) => {
      this.intercomStore.update({ userHash: res?.userHash });
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  syncUser$ = this.intercomQuery.isInitialized$.pipe(
    filter((e) => e),
    switchMap(() => this.authQuery.userId$),
    map(() => {
      // whenever the uid chnages, we need to do a reboot;
      this.reboot();
    }),
    switchMap(() => {
      return combineLatest({
        user: this.authQuery.user$,
        userHash: this.intercomQuery.userHash$,
      }).pipe(
        map(({ user, userHash }) => {
          if (!user?.uid) {
            return;
          }

          if (user.isAnonymous) {
            this.update({
              isAnonymous: user?.isAnonymous,
              uid: user.uid,
            });
          } else {
            this.update({
              isAnonymous: user?.isAnonymous,
              displayName: user?.displayName,
              name: user.displayName,
              email: user?.email,
              emailVerified: user?.emailVerified,
              uid: user.uid,
              user_id: user.uid,
              user_hash: userHash ?? undefined,
            });
          }
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    })
  );

  syncRouter$ = this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .pipe(
      tap((e) => {
        this.update();
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  syncAll() {
    return combineLatest([
      this.syncRouter$,
      this.syncUser$,
      this.syncUserHash$,
    ]);
  }

  private update(
    data?: Record<string, unknown> // TODO interface?
  ) {
    if (typeof this.Intercom !== 'undefined') {
      this.logger.debug('updating', data);
      this.Intercom('update', data);
    }
  }

  private reboot(
    data?: Record<string, unknown> // TODO interface?
  ) {
    if (typeof this.Intercom !== 'undefined') {
      this.logger.debug('rebooting', data);
      this.Intercom('shutdown');
      this.Intercom('boot', data);
    }
  }

  // based on https://raw.githubusercontent.com/CaliStyle/ng-intercom/master/src/app/ng-intercom/intercom/intercom.ts
  private injectIntercomScript(
    conf: { app_id: string },
    afterInjectCallback: (ev: Event) => any
  ): void {
    // Set the window configuration to conf
    (this.document.defaultView as any).intercomSettings = conf;

    // Create the intercom script in document
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${conf.app_id}`;

    if ((s as any).attachEvent) {
      (s as any).attachEvent('onload', afterInjectCallback);
    } else {
      s.addEventListener('load', afterInjectCallback, false);
    }

    this.document.head.appendChild(s);

    this.Intercom('update', conf);
  }

  async loadIntercom(config: { app_id: string; api_base: string }) {
    const { isInitialized, isInitializing } = this.intercomStore.getValue();
    if (isInitialized || isInitializing) {
      this.logger.info('intercom initialization already triggered', {
        isInitialized,
        isInitializing,
      });
      return;
    }

    this.intercomStore.update({ isInitializing: true });

    const useIntercom = await firstValueFrom(
      this.featureTogglesQuery.enableIntercom$
    );

    if (useIntercom !== true) {
      this.logger.debug('intercom deactivated, skipping load');
      this.intercomStore.update({ isInitializing: false });
      return;
    }
    this.logger.debug('intercom activated, start loading');

    // injection magic
    const injectionP = new Promise((resolve) => {
      const w = this.document.defaultView as any;
      const ic = w.Intercom;
      // Set window config for Intercom
      w.intercomSettings = config;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', config);
        resolve(void 0);
      } else {
        const i: any = function (...args: unknown[]) {
          i.c(args);
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        this.injectIntercomScript(config, resolve);
      }
    });

    await injectionP;

    console.log('BOOT', config);
    this.Intercom('boot', config);
    this.Intercom('show');
    this.intercomStore.update({ isInitializing: false, isInitialized: true }); // TODO
  }
}
