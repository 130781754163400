<button
  class="tooltip-trigger"
  (click)="bindings.showTooltip.setValue(true); sendEventOnClick()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  type="button"
>
  <img [src]="'/assets/images/icon/question.png'" />
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="!!(bindings.showTooltip.value$ | async)"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'custom-cdk-overlay-backdrop'"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top'
    }
  ]"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="bindings.showTooltip.setValue(false)"
>
  <div class="tooltip-content">
    {{ content }}
  </div>
</ng-template>
