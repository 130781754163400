import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeatureTogglesQuery } from '@expresssteuer/state-angular';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  @Input()
  menuItems?: { label: string; href: string }[];
  @Input()
  userActions?: (
    | {
        label: string;
        routerLink: string;
        badge?: string | number | null;
      }
    | undefined
  )[];
  @Input()
  userName?: string | null;
  @Input()
  userEmail?: string | null;
  @Input()
  isLoggedIn?: boolean;
  @Output()
  itemClicked = new EventEmitter<void>();

  constructor(public featureTogglesQuery: FeatureTogglesQuery) {}
}
