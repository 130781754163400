import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EdCheckboxComponent } from './ed-checkbox.component';

@NgModule({
  declarations: [EdCheckboxComponent],
  imports: [CommonModule, FormsModule],
  exports: [EdCheckboxComponent],
})
export class EdCheckboxModule {}
