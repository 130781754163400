import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EdDateSeparateComponent } from './ed-date-separate.component';

@NgModule({
  declarations: [EdDateSeparateComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [EdDateSeparateComponent],
})
export class EdDateSeparateModule {}
