import {
  TrackingProductType,
  TrackingSourceType,
} from '@expresssteuer/tracking-types';
import { MixpanelBaseEvent, MixpanelEvent } from '../mixpanel.event';

export enum UserProfileEventName {
  DocumentViewShown = 'up.documentView.shown',
  DocumentViewDocumentSelected = 'up.documentView.documentSelected',
  DocumentViewDocumentUploaded = 'up.documentView.documentUploaded',
  DocumentViewDocumentDeleted = 'up.documentView.documentDeleted',
  DocumentViewDocumentDeletedSuccess = 'up.documentView.documentDeletedSuccess',
  OverviewViewShown = 'up.overviewView.shown',
  OverviewViewTodoActionClicked = 'up.overviewView.wonderlandActionClicked',
  OverviewViewOptimizeActionClicked = 'up.overviewView.optimizeActionClicked',
  ProfileViewShown = 'up.profileView.shown',
  FriendsViewShown = 'up.friendsView.shown',
  TodoViewShown = 'up.todoView.shown',
  TodoViewOptimizeToastShown = 'up.todoView.optimizeToastShown',
  TodoViewOptimizeToastLaterButtonClicked = 'up.todoView.optimizeToastLaterButtonClicked',
  TodoViewOptimizeToastStartButtonClicked = 'up.todoView.optimizeToastStartButtonClicked',
  TodoViewOptimizeToastBackdropCloseClicked = 'up.todoView.optimizeToastBackdropCloseClicked',
  KycToastStartButtonClicked = 'up.kycToast.startButtonClicked',
}

export enum WonderlandEventName {
  TodoListInitialTodosLoaded = 'wl.todoList.initialTodosLoaded',
  TodoListTodoClicked = 'wl.todoList.todoClicked',

  EntrySuccessModalShown = 'wl.entrySuccessModal.shown',
  EntrySuccessModalCloseClicked = 'wl.entrySuccessModal.closeClicked',
  EntrySuccessModalStartButtonClicked = 'wl.entrySuccessModal.startButtonClicked',

  ClientIdentifyingProofShown = 'wl.clientIdentifiyingProof.shown',
  ClientIdentifyingProofOpenInfoModalButtonClicked = 'wl.clientIdentifiyingProof.openInfoModalButtonClicked',
  ClientIdentifyingProofInfoModalClosed = 'wl.clientIdentifiyingProof.infoModalClosed',
  ClientIdentifyingProofFrontSelected = 'wl.clientIdentifiyingProof.frontSelected',
  ClientIdentifyingProofFrontUploaded = 'wl.clientIdentifiyingProof.frontUploaded',
  ClientIdentifyingProofFrontDeleted = 'wl.clientIdentifiyingProof.frontDeleted',
  ClientIdentifyingProofFrontDeletedSuccess = 'wl.clientIdentifiyingProof.frontDeletedSuccess',
  ClientIdentifyingProofBackSelected = 'wl.clientIdentifiyingProof.backSelected',
  ClientIdentifyingProofBackUploaded = 'wl.clientIdentifiyingProof.backUploaded',
  ClientIdentifyingProofBackDeleted = 'wl.clientIdentifiyingProof.backDeleted',
  ClientIdentifyingProofBackDeletedSuccess = 'wl.clientIdentifiyingProof.backDeletedSuccess',
  ClientIdentifyingProofConfirmButtonClicked = 'wl.clientIdentifiyingProof.confirmButtonClicked',
  ClientIdentifyingProofRetryButtonClicked = 'wl.clientIdentifiyingProof.retryButtonClicked',
  ClientIdentifyingProofEscalationButtonClicked = 'wl.clientIdentifiyingProof.escalationButtonClicked',
  ClientIdentifyingProofCloseButtonClicked = 'wl.clientIdentifiyingProof.closeButtonClicked',

  ClientTaxidProofShown = 'wl.clientTaxidProof.shown',
  ClientTaxidProofOpenInfoModalButtonClicked = 'wl.clientTaxidProof.openInfoModalButtonClicked',
  ClientTaxidProofInfoModalClosed = 'wl.clientTaxidProof.infoModalClosed',
  ClientTaxidProofDocumentSelected = 'wl.clientTaxidProof.documentSelected',
  ClientTaxidProofDocumentUploaded = 'wl.clientTaxidProof.documentUploaded',
  ClientTaxidProofDocumentDeleted = 'wl.clientTaxidProof.documentDeleted',
  ClientTaxidProofDocumentDeletedSuccess = 'wl.clientTaxidProof.documentDeletedSuccess',
  ClientTaxidProofConfirmButtonClicked = 'wl.clientTaxidProof.confirmButtonClicked',
  ClientTaxidProofRetryButtonClicked = 'wl.clientTaxidProof.retryButtonClicked',
  ClientTaxidProofEscalationButtonClicked = 'wl.clientTaxidProof.escalationButtonClicked',
  ClientTaxidProofCloseButtonClicked = 'wl.clientTaxidProof.closeButtonClicked',

  ClientIbanShown = 'wl.clientIban.shown',
  ClientIbanUpsert = 'wl.clientIban.upsert',
  ClientIbanConfirmButtonClicked = 'wl.clientIban.confirmButtonClicked',
  ClientIbanCloseButtonClicked = 'wl.clientIban.closeButtonClicked',
  ClientIbanRetryButtonClicked = 'wl.clientIban.retryButtonClicked',

  PartnerTaxidProofShown = 'wl.partnerTaxidProof.shown',
  PartnerTaxidProofOpenInfoModalButtonClicked = 'wl.partnerTaxidProof.openInfoModalButtonClicked',
  PartnerTaxidProofInfoModalClosed = 'wl.partnerTaxidProof.infoModalClosed',
  PartnerTaxidProofDocumentSelected = 'wl.partnerTaxidProof.documentSelected',
  PartnerTaxidProofDocumentUploaded = 'wl.partnerTaxidProof.documentUploaded',
  PartnerTaxidProofDocumentDeleted = 'wl.partnerTaxidProof.documentDeleted',
  PartnerTaxidProofDocumentDeletedSuccess = 'wl.partnerTaxidProof.documentDeletedSuccess',
  PartnerTaxidProofConfirmButtonClicked = 'wl.partnerTaxidProof.confirmButtonClicked',
  PartnerTaxidProofRetryButtonClicked = 'wl.partnerTaxidProof.retryButtonClicked',
  PartnerTaxidProofEscalationButtonClicked = 'wl.partnerTaxidProof.escalationButtonClicked',
  PartnerTaxidProofCloseButtonClicked = 'wl.partnerTaxidProof.closeButtonClicked',

  PartnerIdentifyingProofBackDeleted = 'wl.partnerIdentifiyingProof.backDeleted',
  PartnerIdentifyingProofBackDeletedSuccess = 'wl.partnerIdentifiyingProof.backDeletedSuccess',
  PartnerIdentifyingProofBackSelected = 'wl.partnerIdentifiyingProof.backSelected',
  PartnerIdentifyingProofBackUploaded = 'wl.partnerIdentifiyingProof.backUploaded',
  PartnerIdentifyingProofCloseButtonClicked = 'wl.partnerIdentifiyingProof.closeButtonClicked',
  PartnerIdentifyingProofConfirmButtonClicked = 'wl.partnerIdentifiyingProof.confirmButtonClicked',
  PartnerIdentifyingProofEscalationButtonClicked = 'wl.partnerIdentifiyingProof.escalationButtonClicked',
  PartnerIdentifyingProofFrontDeleted = 'wl.partnerIdentifiyingProof.frontDeleted',
  PartnerIdentifyingProofFrontDeletedSuccess = 'wl.partnerIdentifiyingProof.frontDeletedSuccess',
  PartnerIdentifyingProofFrontSelected = 'wl.partnerIdentifiyingProof.frontSelected',
  PartnerIdentifyingProofFrontUploaded = 'wl.partnerIdentifiyingProof.frontUploaded',
  PartnerIdentifyingProofInfoModalClosed = 'wl.partnerIdentifiyingProof.infoModalClosed',
  PartnerIdentifyingProofOpenInfoModalButtonClicked = 'wl.partnerIdentifiyingProof.openInfoModalButtonClicked',
  PartnerIdentifyingProofRetryButtonClicked = 'wl.partnerIdentifiyingProof.retryButtonClicked',
  PartnerIdentifyingProofShown = 'wl.partnerIdentifiyingProof.shown',
  PartnerSignatureShown = 'wl.partnerSignature.shown',
  PartnerSignatureConfirmButtonClicked = 'wl.partnerSignature.confirmButtonClicked',
  PartnerSignatureCloseButtonClicked = 'wl.partnerSignature.closeButtonClicked',

  VmaProofShown = 'wl.vmaProof.shown',
  VmaProofOpenInfoModalButtonClicked = 'wl.vmaProof.openInfoModalButtonClicked',
  VmaProofInfoModalClosed = 'wl.vmaProof.infoModalClosed',
  VmaProofDocumentSelected = 'wl.vmaProof.documentSelected',
  VmaProofDocumentUploaded = 'wl.vmaProof.documentUploaded',
  VmaProofDocumentDeleted = 'wl.vmaProof.documentDeleted',
  VmaProofDocumentDeletedSuccess = 'wl.vmaProof.documentDeletedSuccess',
  VmaProofConfirmButtonClicked = 'wl.vmaProof.confirmButtonClicked',
  VmaProofRetryButtonClicked = 'wl.vmaProof.retryButtonClicked',
  VmaProofEscalationButtonClicked = 'wl.vmaProof.escalationButtonClicked',
  VmaProofCloseButtonClicked = 'wl.vmaProof.closeButtonClicked',
}

export enum NavbarEventName {
  PhoneButtonClicked = 'app.navbar.phoneButtonClicked',
}

export enum CallFabButtonEventName {
  OpenedOnMobile = 'app.callFabButton.openedOnMobile',
  CallClicked = 'app.callFabButton.callClicked',
}

export enum AuthEventName {
  SignInWithLinkPageShown = 'auth.signInWithLinkPage.shown',
  SendEmailLinkPageShown = 'auth.sendEmailLinkPage.shown',
  ExistingUserPageShown = 'auth.existingUserPage.shown',
  SignInWithPasswordPageShown = 'auth.signInWithPasswordPage.shown',
  SignInWithBirthdatePageShown = 'auth.signInWithBirthdatePage.shown',
  SignInWithSocialPageShown = 'auth.signInWithSocialPage.shown',
  SignInWithLinkPasswordPageShown = 'auth.signInWithLinkPasswordPage.shown',
  SendLoginEmail = 'auth.sendEmailLinkPage.sendEmailButtonClicked',
  AutoSendLoginEmail = 'auth.sendEmailLinkPage.autoSendEmailTriggered',
  SignInWithLinkLoginButtonClicked = 'auth.signInWithLinkPage.loginButtonClicked',
  SignInWithPasswortLoginButtonClicked = 'auth.signInWithPasswordPage.loginButtonClicked',
  SignInWithBirthdateLoginButtonClicked = 'auth.signInWithBirthdatePage.loginButtonClicked',
  SignInWithSocialPageEmailNextButtonClicked = 'auth.signInWithSocialPage.emailNextButtonClicked',
  SignInWithLinkPasswordPageSendLoginEmail = 'auth.signInWithLinkPasswordPage.sendEmailButtonClicked',
  SignInWithLinkPasswordPagePasswordLoginButonClicked = 'auth.signInWithLinkPasswordPage.passwordLoginButtonClicked',
  SignInWithLinkExpiredError = 'auth.signInWithLinkPage.linkExpiredError',
  SignInWithLinkInvalidEmailError = 'auth.signInWithLinkPage.invalidEmailError',
  SignInWithLinkPasswordPageInvalidEmailError = 'auth.signInWithLinkPasswordPage.invalidEmailError',
  SignInWithLinkUnableToFindUserError = 'auth.signInWithLinkPage.unableToFindUserError',
  SignInWithLinkPasswordPageUnableToFindUserError = 'auth.signInWithLinkPasswordPage.unableToFindUserError',
  SignInWithLinkResendLink = 'auth.signInWithLinkPage.resendLinkButtonClicked',
  SignInWithBirthdateError = 'auth.signInWithBirthdatePage.error',
  SignInWithWrongPassword = 'auth.signInWithPasswordPage.wrongPasswordError',
  SignInWithLinkPasswordPageSignInWithWrongPassword = 'auth.signInWithLinkPasswordPage.wrongPasswordError',
  SignInWithBirthdatePageSuccessful = 'auth.signInWithBirthdatePage.successfullyLoggedIn',
  SignInWithLinkPageSuccessful = 'auth.signInWithLinkPage.successfullyLoggedIn',
  SignInWithLinkPasswordPageSuccessful = 'auth.signInWithLinkPasswordPage.successfullyLoggedIn',
}

export enum IbanVerificationEventName {
  ViewShown = 'up.ibanVerificationView.shown',
}

export enum OptimizeEventName {
  IntroViewShown = 'op.introView.shown',
  IntroViewStartClicked = 'op.introView.startButtonClicked',
  IntroViewFunnelClosedButtonClicked = 'op.introView.funnelClosedButtonClicked',
  UploadViewShown = 'op.uploadView.shown',
  UploadViewDocumentSelected = 'op.uploadView.documentSelected',
  UploadViewDocumentUploaded = 'op.uploadView.documentUploaded',
  UploadViewDocumentDeleted = 'op.uploadView.documentDeleted',
  UploadViewDocumentDeletedSuccess = 'op.uploadView.documentDeletedSuccess',
  UploadViewOpenInfoModalButtonClicked = 'op.uploadView.openInfoModalButtonClicked',
  UploadViewInfoModalClosed = 'op.uploadView.infoModalClosed',
  UploadViewConfirmButtonClicked = 'op.uploadView.confirmButtonClicked',
  UploadViewFunnelClosedButtonClicked = 'op.uploadView.funnelClosedButtonClicked',
  SuccessViewShown = 'op.successView.shown',
  SuccessViewNextUploadButtonClicked = 'op.successView.nextDocumentUploadButtonClicked',
  SuccessViewFinishButtonClicked = 'op.uploadView.finishButtonClicked',
  SuccessViewFunnelClosedButtonClicked = 'op.uploadView.funnelClosedButtonClicked',
}

type StandardEventNameType = `${
  | TrackingProductType.UserProfile
  | TrackingProductType.Wonderland
  | TrackingProductType.App
  | TrackingProductType.Auth
  | TrackingProductType.Optimize}.${string}.${string}`;

export class StandardEvent implements MixpanelEvent {
  sourceType = TrackingSourceType.Website;
  eventProperties: MixpanelBaseEvent;

  constructor(
    public eventName: StandardEventNameType | NavbarEventName,
    eventProperties: Omit<MixpanelBaseEvent, 'sourceType'>
  ) {
    this.eventProperties = {
      sourceType: this.sourceType,
      ...eventProperties,
    };
  }
}
