<app-overlay
  *ngIf="legalOverlaysQuery.showImprintOverlay$ | async"
  class="imprint-content"
  headline="Impressum"
  [content]="legalDocumentsQuery.imprintDocument$ | async"
  (closeClicked)="legalOverlaysService.hideImprintOverlay()"
></app-overlay>
<app-overlay
  *ngIf="legalOverlaysQuery.showConditionsOverlay$ | async"
  class="conditions-content"
  headline="AGB & Leistungsvereinbarung"
  [content]="legalDocumentsQuery.conditionsDocument$ | async"
  (closeClicked)="legalOverlaysService.hideConditionsOverlay()"
></app-overlay>
<app-overlay
  *ngIf="legalOverlaysQuery.showDataProtectionOverlay$ | async"
  class="data-protection-content"
  headline="Datenschutzhinweise"
  [content]="legalDocumentsQuery.dataProtectionDocument$ | async"
  (closeClicked)="legalOverlaysService.hideDataProtectionOverlay()"
></app-overlay>
<app-overlay
  *ngIf="legalOverlaysQuery.showDataProcessingOverlay$ | async"
  headline="Datenverarbeitung"
  [content]="legalDocumentsQuery.dataProcessingDocument$ | async"
  (closeClicked)="legalOverlaysService.hideDataProcessingOverlay()"
></app-overlay>
<app-overlay
  *ngIf="legalOverlaysQuery.showReferralOverlay$ | async"
  headline="Teilnahmebedingungen für das Online-Kunden werben Kunden-Programm"
  [content]="legalDocumentsQuery.referralDocument$ | async"
  (closeClicked)="legalOverlaysService.hideReferralOverlay()"
></app-overlay>
<app-overlay
  *ngIf="legalOverlaysQuery.showSignableContract$ | async"
  class="signable-contracts-content"
  [loading]="this.signableContractQuery.loading$ | async"
  [content]="
    (featureTogglesQuery.enableCustomContractStyles$ | async)
      ? undefined
      : (this.signableContractQuery.contractHtml$ | async)
  "
  (closeClicked)="legalOverlaysService.hideLoadedContract()"
>
  <app-contract-style-wrapper
    *ngIf="featureTogglesQuery.enableCustomContractStyles$ | async"
    [content]="this.signableContractQuery.contractHtml$ | async"
  ></app-contract-style-wrapper>
</app-overlay>
