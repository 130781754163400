import { ZodIssue } from 'zod';
import {
  SignUpV1,
  SignUpV1Zod,
  SignUpV1ZodEffects,
  WayToSpecifyDistanceToWorkV1,
} from './sign-up.api-interface.v1';
import {
  fromPrevious,
  SignUpV3,
  SignUpV3Zod,
  SignUpV3ZodEffects,
} from './sign-up.api-interface.v3';

export type Version = { version: '1' | '3' };

export type AnySignUpV = Version & (SignUpV1 | SignUpV3); // (SignUpV1 | SignUpV2 | ...)
export type AnySignUpVZod = typeof SignUpV1Zod; // | typeof SignUpV2Zod | ...
export type AnySignUpVZodEffects =
  | typeof SignUpV1ZodEffects
  | typeof SignUpV3ZodEffects; // | typeof SignUpV2ZodEffects | ...
export type LatestSignUpV = SignUpV3;
export const LatestSignUpVZod = SignUpV3Zod;
export const LatestSignUpVZodEffects = SignUpV3ZodEffects;
export const WayToSpecifyDistanceToWork = WayToSpecifyDistanceToWorkV1;

export function parseAnySignUpVInput(
  data: unknown
): false | { data?: AnySignUpV; error?: ZodIssue[] } {
  if (
    (data as Version)?.version &&
    typeof (data as Version)?.version === 'string'
  ) {
    const version = (data as Version)?.version;
    switch (version) {
      case '1':
        return parseSpecificV(data, SignUpV1ZodEffects);
      case '3':
        return parseSpecificV(data, SignUpV3ZodEffects);
      default:
        return false;
    }
  } else {
    return false;
  }
}

function parseSpecificV(
  data: unknown,
  anySignUpVZodEffects: AnySignUpVZodEffects
) {
  const safeParsedData = anySignUpVZodEffects.safeParse(data);
  if (safeParsedData.success) {
    return { data: safeParsedData.data };
  } else {
    return { error: safeParsedData.error.errors };
  }
}

export function migrateAnySignUpVtoLatest(
  anyJigglyPuff: AnySignUpV
): LatestSignUpV {
  const latestVersion: LatestSignUpV['version'] = '3';

  switch (anyJigglyPuff.version) {
    case '1':
      return migrateAnySignUpVtoLatest(fromPrevious(anyJigglyPuff));
    case latestVersion:
      return anyJigglyPuff as LatestSignUpV;
    default:
      throw new Error('could not migrate to latest signup version');
  }
}
