import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CallFabComponent } from './call-fab.component';

@NgModule({
  declarations: [CallFabComponent],
  imports: [CommonModule, TranslateModule, MatIconModule],
  exports: [CallFabComponent],
})
export class CallFabModule {}
