import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { appRoutes } from '../app-routing.module';
import { FunnelQueryParam } from './funnel-view/funnel-query-param';

@Injectable()
export class HandleVerificationLinksGuard implements CanActivate {
  private logger: EsuiLoggerService;

  constructor(private router: Router, logger: EsuiLoggerService) {
    this.logger = logger.getNewInstance(this);
  }

  async canActivate(next: ActivatedRouteSnapshot) {
    const taxFormId = next.params[appRoutes.oldVerification.params.taxFormId];
    const verificationType = next.params[appRoutes.oldVerification.params.type];
    if (verificationType === 'retention') {
      const commands = [appRoutes.retention.path];
      this.logger.debug(
        'Converting old retention link to new format using taxFormId',
        taxFormId,
        commands,
        verificationType
      );
      return this.router.createUrlTree(commands, {
        queryParams: {
          ...next.queryParams,
          [FunnelQueryParam.taxFormId]: taxFormId,
        },
      });
    } else if (verificationType === 'release-tax-declaration') {
      const command = [appRoutes.approval.path + '/' + taxFormId];
      this.logger.debug(
        'Converting old approval link to new format using taxFormId',
        taxFormId,
        command
      );
      return this.router.createUrlTree(command, {
        queryParams: {
          ...next.queryParams,
        },
      });
    }
    return false;
  }
}
