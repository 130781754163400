<div class="checkbox">
  <input
    [indeterminate]="indeterminate"
    [id]="name"
    [name]="name"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    type="checkbox"
    class="input"
    [ngClass]="{
      'input--error': isInvalid && (isTouched || showErrorsDespitePristine)
    }"
    (blur)="onBlur()"
  />
</div>
<label [for]="name" class="label">
  <ng-content></ng-content>
</label>
<div
  *ngIf="isInvalid && errorText && (isTouched || showErrorsDespitePristine)"
  class="error-text"
>
  {{ errorText }}
</div>
