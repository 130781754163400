import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ed-icon-button], a[ed-icon-button]',
  templateUrl: './ed-icon-button.component.html',
  styleUrls: ['./ed-icon-button.component.scss'],
})
export class EdIconButtonComponent {
  @Input()
  @HostBinding()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @HostBinding('class')
  get classes() {
    return {
      button: true,
      'button--disabled': this.disabled,
      'button--m': this.size === 'm',
      'button--s': this.size === 's',
    };
  }

  @Input() icon = '';

  @Input() size: 'm' | 's' = 'm';
}
