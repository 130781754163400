import { Component, Inject } from '@angular/core';
import {
  BindingsFrom,
  EASY_AKITA_UI_BINDINGS,
  FeatureTogglesQuery,
} from '@expresssteuer/state-angular';
import { AuthQuery } from '../../state/auth/auth.query';
import { TodosQuery } from '../../state/todos/todos.query';
import { UserQuery } from '../../state/user/user.query';
import { myAccountRoutes } from '../../views/myaccount-view/myaccount-view-routing.module';
import { navigationEAU } from './navigation.ui-state';
import { ClientTaxCasesQuery } from '../../state/client-tax-cases/client-tax-cases.query';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  isLoggedIn$ = this.authQuery.isLoggedIn$;
  myAccountRoutes = myAccountRoutes;
  constructor(
    @Inject(EASY_AKITA_UI_BINDINGS)
    public bindings: BindingsFrom<typeof navigationEAU>,
    public authQuery: AuthQuery,
    public userQuery: UserQuery,
    public todosQuery: TodosQuery,
    public clientTaxCasesQuery: ClientTaxCasesQuery,
    public featureTogglesQuery: FeatureTogglesQuery
  ) {}
}
