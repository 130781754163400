import { Constructor } from '@datorama/akita';

export type InstanceTypeOfConstructor<TConstructor extends Constructor> =
  TConstructor extends new (...args: any[]) => infer T ? T : never;

/**
 * Should we think about an alternative approach to
 * apply Mixins like the following?
 */
// export function WithMixins<
//   TBase extends Constructor,
//   TMixins extends ((base: Constructor) => Constructor)[]
// >(base: TBase, ...constructors: TMixins): TBase & ReturnType<TMixins[number]> {
//   const mixed = constructors.reduce((acc, curr) => {
//     return class extends curr(acc) {};
//   }, base as Constructor);
//   return mixed as TBase & ReturnType<TMixins[number]>;
// }
