import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  StartEmailVerification,
  VerifyEmail,
} from '@expresssteuer/client-api-angular';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthQuery } from '../auth/auth.query';
import { ClientQuery } from '../client/client.query';
import { VerificationStore } from './verification.store';

@Injectable({ providedIn: 'root' })
export class VerificationService {
  private logger: EsuiLoggerService;

  constructor(
    private store: VerificationStore,
    private authQuery: AuthQuery,
    private clientQuery: ClientQuery,
    private activeRoute: ActivatedRoute,
    private verifyEmail: VerifyEmail,
    private startEmailVerification: StartEmailVerification,
    logger: EsuiLoggerService
  ) {
    this.logger = logger.getNewInstance(this);
  }

  async tryToVerifyEmailAddress() {
    try {
      this.store.reset();
      this.store.setLoading(true);
      await this.authQuery.untilUserId();
      await this.waitUntilNoMoreContinueUrlParamExists();

      // return if already verified
      await this.clientQuery.untilIsInitialized();
      const isAlreadyVerified = this.clientQuery.emailVerified$.getSnapshot();
      if (isAlreadyVerified) {
        this.logger.debug('client is already verified');
        this.store.setLoading(false);
        return;
      }

      // return if no verification code exists
      const emailVerificationCode = (await this.getCurrentQueryParams())
        .emailVerificationCode;
      if (!emailVerificationCode) {
        this.logger.debug(
          'no emailVerificationCode provided, skipping email verification'
        );
        this.store.update({ noCodeProvided: true });
        this.store.setLoading(false);
        return;
      }

      // verify email by verification code
      await firstValueFrom(this.verifyEmail.call({ emailVerificationCode }));
      this.store.update({ requestSucceeded: true });
    } catch (e) {
      this.logger.error('failed to tryToVerifyEmailAddress', e);
      this.store.update({ requestFailed: true });
    } finally {
      this.store.setLoading(false);
    }
  }

  async restartEmailVerificationFlow() {
    this.store.reset();
    this.store.setLoading(true);
    try {
      await firstValueFrom(this.startEmailVerification.call({}));
      this.store.update({ restartSucceeded: true });
    } catch (e) {
      this.logger.error('failed to restartEmailVerificationFlow', e);
      this.store.update({ restartFailed: true });
    }
    this.store.setLoading(false);
  }

  private getCurrentQueryParams() {
    return firstValueFrom(this.activeRoute.queryParams);
  }

  private async waitUntilNoMoreContinueUrlParamExists() {
    /*
    Verifying an email address requires the user to be logged in. If this is already the case,
    everything is fine. Otherwise he will be redirected to the log-in page. After logging in, he
    will be redirected back to where came from (using a "continue" url parameter).

    There is this little moment where the user just logged in (uid exists), but the redirect did
    not finish. In that moment we can not read to "emailVerificationCode" from the url params,
    because it is nested in the "continue" param. This is why we wait until the target destination
    is reached.
     */
    return await this.activeRoute.queryParams
      .pipe(
        filter((params) => !params.continue),
        take(1)
      )
      .toPromise();
  }
}
