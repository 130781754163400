import { Constructor } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { InstanceTypeOfConstructor } from './mixin-helper';

type ILoading = any;

export interface IMultiLoadableStore<TLoading extends ILoading> {
  updateLoading(newLoaders: Partial<TLoading>): void;
  clearLoading(): void;
}

export interface IMultiLoadableQuery {
  isLoading$: Observable<boolean>;
  selectLoading(): Observable<boolean>;
}

type WithLoaders = { loaders?: ILoading | null };

interface IStore<TState extends WithLoaders> {
  getValue(): TState;
  update(change: WithLoaders): void;
  setLoading(input: any): void;
}

interface IQuery<TState extends WithLoaders> {
  select(): Observable<TState>;
  selectLoading(): Observable<boolean>;
}

export function MultiLoadableStoreMixin<
  TState extends WithLoaders,
  TBase extends Constructor<IStore<TState>>,
  TLoader extends NonNullable<
    ReturnType<InstanceTypeOfConstructor<TBase>['getValue']>['loaders']
  >
>(Base: TBase): TBase & Constructor<IMultiLoadableStore<TLoader>> {
  return class ErrorUpdatable
    extends Base
    implements IMultiLoadableStore<TLoader>
  {
    updateLoading(newErrors: Partial<TLoader>) {
      const oldErrors = this.getValue()?.loaders;
      const mergedLoaders = { ...oldErrors, ...newErrors };
      const truthyEntries = Object.entries(mergedLoaders).filter(
        ([_key, val]) => val
      );
      const mergedWithoutFalse =
        truthyEntries.length > 0
          ? (Object.fromEntries(truthyEntries) as TLoader)
          : null;
      this.update({ loaders: mergedWithoutFalse });
    }

    clearLoading() {
      this.update({ loaders: null });
    }

    /**
     * MultiLoadableMixin applied, use `updateLoading` and/or `clearLoading` instead of `setLoading`
     * @deprecated
     */
    override setLoading(loading?: boolean): void {
      return super.setLoading(loading);
      // throw new Error(
      //   'MultiLoadableMixin applied, use `updateLoading` and/or `clearLoading` instead of `setLoading`'
      // );
    }
  };
}

export function MultiLoadableQueryMixin<
  TState extends WithLoaders,
  TBase extends Constructor<IQuery<TState>>
>(Base: TBase): TBase & Constructor<IMultiLoadableQuery> {
  return class ErrorUpdatable extends Base implements IMultiLoadableQuery {
    isLoading$: Observable<boolean> = combineLatest([
      this.selectOriginalLoading().pipe(distinctUntilChanged()),
      this.select().pipe(distinctUntilChanged()),
    ]).pipe(
      map(([originalLoading, { loaders }]) => {
        return originalLoading || !!loaders;
      }),
      shareReplay(1)
    );

    private selectOriginalLoading() {
      return super.selectLoading();
    }

    override selectLoading() {
      return this.isLoading$;
    }
  };
}
