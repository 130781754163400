import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EdButtonGroupItemComponent } from './ed-button-group-item.component';

@NgModule({
  declarations: [EdButtonGroupItemComponent],
  imports: [CommonModule],
  exports: [EdButtonGroupItemComponent],
})
export class EdButtonGroupItemModule {}
