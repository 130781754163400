import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  GetUserHashInput,
  GetUserHashOutput,
} from '@expresssteuer/intercom-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class GetUserHash {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: GetUserHashInput) {
    console.log('Calling intercom-getUserHash');
    return this.afFunctions.httpsCallable<GetUserHashInput, GetUserHashOutput>(
      'intercom-getUserHash'
    )(input);
  }
}
