import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import {
  SignableContractState,
  SignableContractStore,
} from './signable-contract.store';

@Injectable({
  providedIn: 'root',
})
export class SignableContractQuery extends Query<SignableContractState> {
  constructor(protected store: SignableContractStore) {
    super(store);
  }
  contractHtml$ = this.select('contract').pipe(
    map((contract) => contract?.html)
  );
  loading$ = this.selectLoading();
}
