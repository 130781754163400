import { Constructor } from '@datorama/akita';
import { Observable } from 'rxjs';
import {
  FormattedGetterKey,
  formattedGetterKey,
  FormattedGetterObservableKey,
  formattedGetterObservableKey,
} from '../namings/namings';

export interface IPaginationStore<TPaginationName extends string> {
  pagination: {
    [k in TPaginationName]: {
      updatePage: (change: Partial<PaginationPage>) => void;
      setNext: () => void;
      setPrevious: () => void;
      setStart: () => void;
    };
  };
}

export interface IPaginationQuery<TPaginationName extends string> {
  pagination: {
    [k in FormattedGetterObservableKey<TPaginationName>]: Observable<
      PaginationPage | undefined
    >;
  };
}

export type WithPagination<TPaginationName extends string> = {
  pagination: {
    [k in FormattedGetterKey<TPaginationName>]: PaginationPage;
  };
  ids?: any[];
};

interface IStore<
  TPaginationName extends string,
  TState extends WithPagination<TPaginationName>
> {
  getValue(): TState;
  update(change: Partial<TState>): void;
}

interface IQuery<
  TPaginationName extends string,
  TState extends WithPagination<TPaginationName>
> {
  select<T>(callback: (state: TState) => T): Observable<T>;
}

export function PaginationStoreMixin<
  TPaginationName extends string,
  TState extends WithPagination<TPaginationName>,
  TBase extends Constructor<IStore<TPaginationName, TState>>
>(
  Base: TBase,
  paginationName: TPaginationName,
  template?: PaginationPage
): TBase & Constructor<IPaginationStore<TPaginationName>> {
  return class Pagination
    extends Base
    implements IPaginationStore<TPaginationName>
  {
    pagination!: {
      [k in TPaginationName]: {
        updatePage: (change: Partial<PaginationPage>) => void;
        setNext: () => void;
        setPrevious: () => void;
        setStart: () => void;
      };
    };

    private formattedNameGetter = formattedGetterKey(paginationName);

    constructor(...args: any[]) {
      super(...args);
      if (!this.pagination) {
        this.pagination = {} as NonNullable<
          IPaginationStore<TPaginationName>['pagination']
        >;
      }

      const currentValue =
        this.getValue().pagination?.[this.formattedNameGetter];
      if (template && !currentValue) {
        this.#updatePage(template);
      }

      this.pagination[paginationName] = {
        updatePage: (change: Partial<PaginationPage>) =>
          this.#updatePage(change),
        setNext: () => this.#setNext(),
        setPrevious: () => this.#setPrevious(),
        setStart: () => this.#setStart(),
      };
    }

    #setNext() {
      const ids = this.getValue().ids;
      const lastId = ids && ids[ids.length - 1];
      if (!lastId) {
        return;
      }
      this.#updatePage({
        cursor: {
          startAfter: lastId,
        },
      });
    }

    #setPrevious() {
      const ids = this.getValue().ids;
      const firstId = ids && ids[0];
      if (!firstId) {
        return;
      }
      this.#updatePage({
        cursor: {
          endBefore: firstId,
        },
      });
    }

    #setStart() {
      this.#updatePage({
        cursor: undefined,
      });
    }

    #updatePage(change: Partial<PaginationPage>) {
      const currentValue = this.getValue();

      this.update({
        pagination: {
          ...currentValue.pagination,
          [this.formattedNameGetter]: {
            ...currentValue.pagination?.[this.formattedNameGetter],
            ...change,
          },
        },
      } as any);
    }
  };
}

export function PaginationQueryMixin<
  TPaginationName extends string,
  TState extends WithPagination<TPaginationName>,
  TBase extends Constructor<IQuery<TPaginationName, TState>>
>(
  Base: TBase,
  paginationName: TPaginationName
): TBase & Constructor<IPaginationQuery<TPaginationName>> {
  return class Pagination
    extends Base
    implements IPaginationQuery<TPaginationName>
  {
    pagination!: {
      [k in FormattedGetterObservableKey<TPaginationName>]: Observable<
        PaginationPage | undefined
      >;
    };

    constructor(...args: any[]) {
      super(...args);
      if (!this.pagination) {
        this.pagination = {} as NonNullable<
          IPaginationQuery<TPaginationName>['pagination']
        >;
      }
      const formattedNameGetter = formattedGetterKey(paginationName);
      const formattedNameObservableGetter =
        formattedGetterObservableKey(paginationName);

      this.pagination[formattedNameObservableGetter] = this.select(
        (s) => s.pagination?.[formattedNameGetter]
      );
    }
  };
}

export interface PaginationPage {
  cursor?: PaginationCursor;
  pageSize?: number;
}

export type PaginationCursor =
  | PaginationCursorEndBefore
  | PaginationCursorStartAfter;

export interface PaginationCursorEndBefore {
  endBefore: string;
}
export function isPaginationCursorEndBefore(
  input: any
): input is PaginationCursorEndBefore {
  return input?.endBefore && typeof input.endBefore === 'string';
}

export interface PaginationCursorStartAfter {
  startAfter: string;
}
export function isPaginationCursorStartAfter(
  input: any
): input is PaginationCursorStartAfter {
  return input?.startAfter && typeof input.startAfter === 'string';
}
