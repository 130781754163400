<ng-container
  *ngIf="{
    currentLanguage: languageQuery.currentLanguage$ | async,
    showTranslationDropdown: bindings.showTranslationDropdown.value$ | async
  } as data"
>
  <!-- Alternate styling with flag (1)
  <div class="user-action --with-dropdown --translate">
  -->
  <div class="user-action --with-dropdown">
    <button
      class="user-action__dropdown-trigger"
      type="button"
      (click)="
        bindings.showTranslationDropdown.setValue(!data.showTranslationDropdown)
      "
    >
      <span class="show-sr-only"> Translation </span>

      <img
        class="user-action__icon --translate"
        [src]="'assets/images/flags/' + data.currentLanguage + '.svg'"
      />
    </button>
    <app-popup
      *ngIf="data.showTranslationDropdown"
      anchor="right"
      [mobileFullWidth]="false"
      (closeClick)="bindings.showTranslationDropdown.setValue(false)"
    >
      <div
        class="user-action-dropdown-list"
        role="menu"
        aria-orientation="vertical"
      >
        <button
          type="button"
          role="menuitem"
          *ngFor="let language of SUPPORTED_LANGUAGES | keyvalue"
          class="user-action-dropdown-list__item"
          [class]="{
            '--active': language.key === data.currentLanguage
          }"
          (click)="chooseLanguage(language.key)"
        >
          <img
            class="user-action-dropdown-list__item-icon"
            [src]="'assets/images/flags/' + language.key + '.svg'"
            [alt]="language.value"
          />
          <span> {{ language.value }} </span>
        </button>
      </div>
    </app-popup>
  </div>
</ng-container>
