import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import {
  DetailEvent,
  DocumentUploadEvent,
  EventBuildData,
  IdentifyEvent,
  UrlEvent,
} from '@expresssteuer/models';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subject, combineLatest } from 'rxjs';
import { shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthQuery } from '../auth/auth.query';
import { LanguageQuery } from '../language/language.query';
import { LegacyAnalyticsStore } from './legacy-analytics.store';

/**
 * Google Authenticator loaded by the GoogleTagManager
 */
declare let ga: { getAll: () => Array<{ get(key: string): string }> };

/*
 * Is kept for legacy reasons
 */
@Injectable({
  providedIn: 'root',
})
export class LegacyAnalyticsService {
  /**
   * Helper to observe the latest setter value to `globalThis.ga`
   */
  private googleAnalytics$ = new Subject<typeof ga>();

  private logger: EsuiLoggerService;

  #syncGaClientId$ = this.googleAnalytics$.pipe(
    switchMap(async () => {
      this.store.setLoading(true);
      const gaClientId = await this.getGAClientId();

      if (gaClientId) {
        this.store.update((s) => {
          return {
            ...s,
            gaClientId,
          };
        });
      }
      this.store.setLoading(false);
    }),
    shareReplay(1)
  );

  #syncUser$ = this.authQuery.user$.pipe(
    tap((user) => {
      if (user && user.isAnonymous === false) {
        this.sendIdentifyEvent(user);
      }
    }),
    shareReplay(1)
  );

  /**
   * @note For now kept for legacy reasons, so the analytics is not changed
   */
  private static getHashCode(aString: string) {
    let hash = 0;

    if (aString.length === 0) {
      return hash;
    }
    for (let i = 0; i < aString.length; i++) {
      const char = aString.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      hash = (hash << 5) - hash + char;
      // eslint-disable-next-line no-bitwise
      hash = hash & hash;
    }
    return hash;
  }

  constructor(
    public http: HttpClient,
    private store: LegacyAnalyticsStore,
    private gtmService: GoogleTagManagerService,
    private languageQuery: LanguageQuery,
    logger: EsuiLoggerService,
    private authQuery: AuthQuery
  ) {
    this.logger = logger.getNewInstance(this);
    this.store.update({ analyticsIsActive: environment.toggles.useGtm });

    // spy on the setter of `globalThis.ga` and notify `this.googleAnalytics$` on set
    const googleAnalytics$ = this.googleAnalytics$;
    let proxyGa: typeof ga;
    Object.defineProperty(globalThis, 'ga', {
      set: function (val) {
        proxyGa = val;
        googleAnalytics$.next(proxyGa);
      },
      get: function () {
        return proxyGa;
      },
    });
  }

  public init() {
    if (!environment.toggles.useGtm) {
      return;
    }

    this.store.setLoading(true);
    this.gtmService
      .addGtmToDom()
      .then(() => {
        // no await needed, as it will assure `this.googleAnalytics$` will emit
      })
      .catch((e: unknown) => {
        this.logger.warn('failed to add GTM to DOM', e);
        this.store.setError({ failedToAddGtmToDom: true });
      });
  }

  public syncAll() {
    return combineLatest([this.#syncGaClientId$, this.#syncUser$]);
  }

  /**
   * @returns the google analytics client id
   * @throws when no id found
   */
  private async getGAClientId(): Promise<string | undefined> {
    return this.googleAnalytics$
      .pipe(take(1))
      .toPromise()
      .then(
        () =>
          new Promise((resolve) => {
            const trackers = ga.getAll();
            let i;
            let len;

            for (i = 0, len = trackers.length; i < len; i += 1) {
              const foundId = trackers[i].get('clientId');
              if (foundId) {
                this.logger.debug('Found GAId', foundId);
                return resolve(foundId);
              }
            }
            resolve(undefined);
          })
      );
  }

  public checkoutComplete({
    clientEmail,
    clientFirstname,
    clientMobile,
    taxFormId,
    taxRefund,
    isTest,
  }: {
    clientEmail: string;
    clientFirstname: string;
    clientMobile: string;
    taxFormId: string;
    taxRefund: number;
    isTest: boolean;
  }) {
    this.sendDetailEvent({
      taxFormId,
      taxRefund,
      isTest,
      aEventName: 'checkoutComplete',
    });
    this.sendFBEvent({
      clientEmail,
      clientFirstname,
      clientMobile,
      taxRefund,
      aEventName: 'checkoutComplete',
    }).catch((err) => this.logger.warn('sendFBEvent.error', err));
  }

  public sendFunnelNavigationEvent(
    args: EventBuildData,
    options: { product: string }
  ) {
    try {
      const aLanguage = this.languageQuery.getCurrentLanguage();

      const event: UrlEvent = {
        event: 'dynamicPageview',
        variant: true,
        product: options.product,
        source: 'andorra',
        dynamicURL: aLanguage + args.aSite,
      };

      if (args.taxRefund) {
        event.offerValue = Math.round(args.taxRefund);
      }
      if (args.taxFormId) {
        event.caseId = args.taxFormId;
      }
      if (args.isTest) {
        event.isTest = args.isTest;
      }

      if (
        !environment.toggles.useGoogleAnalytics ||
        !environment.toggles.useGtm
      ) {
        this.logger.debug(`sendFunnelNavigationEvent supressed`, {
          event: event,
        });
        return;
      }

      this.pushToTagManager(event);
    } catch (e) {
      this.logger.error(e);
    }
  }

  public sendClickEvent(eventName: string) {
    if (
      !environment.toggles.useGoogleAnalytics ||
      !environment.toggles.useGtm
    ) {
      this.logger.info(`sendClickEvent supressed`, {
        event: eventName,
      });
      return;
    }
    try {
      this.pushToTagManager({ event: eventName });
    } catch (e) {
      this.logger.error(e);
    }
  }

  public sendDetailEvent(args: EventBuildData) {
    try {
      if (!args.aEventName) {
        throw Error('Event name needed to send DetailEvent');
      }

      const event: DetailEvent = {
        event: args.aEventName,
      };

      if (args.taxRefund) {
        event.offerValue = Math.round(args.taxRefund);
      }
      if (args.taxFormId) {
        event.caseId = args.taxFormId;
      }
      if (args.isTest) {
        event.isTest = args.isTest;
      }

      if (
        !environment.toggles.useGoogleAnalytics ||
        !environment.toggles.useGtm
      ) {
        this.logger.info(`sendDetailEvent supressed`, {
          event: event,
        });
        return;
      }

      this.pushToTagManager(event);
    } catch (e) {
      this.logger.error(e);
    }
  }

  public sendDocumentUploadEvent(event: DocumentUploadEvent) {
    this.pushToTagManager(event);
  }

  public sendIdentifyEvent(user: User) {
    this.pushToTagManager({
      event: 'user_identified',
      userId: user.uid,
      email: user.email,
    });
  }

  public async sendFBEvent(args: {
    clientEmail: string;
    clientFirstname: string;
    clientMobile: string;
    taxRefund: number;
    aEventName: string;
  }) {
    if (!environment.toggles.useFacebookAnalytics) {
      this.logger.info(`sendFBEvent supressed`, args);
      return;
    }
    try {
      const params = new HttpParams()
        .set('id', environment.facebook.account)
        .set('ev', args.aEventName)
        .set(
          'ud[em]',
          LegacyAnalyticsService.getHashCode(args.clientEmail).toString()
        )
        .set(
          'ud[em]',
          LegacyAnalyticsService.getHashCode(args.clientMobile).toString()
        )
        .set(
          'ud[fn]',
          LegacyAnalyticsService.getHashCode(args.clientFirstname).toString()
        )
        .set(
          'cd[value]',
          LegacyAnalyticsService.getHashCode(
            args.taxRefund.toString()
          ).toString()
        )
        .set('cd[currency]', 'EUR');

      await this.http
        .get('https://www.facebook.com/tr', { params })
        .toPromise();
    } catch (error) {
      this.logger.error(error);
    }
  }

  private pushToTagManager(
    item: Record<string, unknown> | UrlEvent | DetailEvent | IdentifyEvent
  ) {
    if (
      !environment.toggles.useGoogleAnalytics ||
      !environment.toggles.useGtm
    ) {
      this.logger.info(`pushToTagManager supressed`, item);
      return;
    }
    this.gtmService
      .pushTag(item)
      .catch((err) => this.logger.warn('gtmService.pushTag.error', err));
  }
}
