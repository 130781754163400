<ng-container>
  <svg
    *ngIf="provider === 'google'"
    class="social-button__icon"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.64 12.2046C20.64 11.5664 20.5827 10.9527 20.4764 10.3636H12V13.845H16.8436C16.635 14.97 16.0009 15.9232 15.0477 16.5614V18.8196H17.9564C19.6582 17.2527 20.64 14.9455 20.64 12.2046Z"
      fill="#4285F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 20.9998C14.43 20.9998 16.4673 20.1939 17.9564 18.8194L15.0477 16.5612C14.2418 17.1012 13.2109 17.4203 12 17.4203C9.65591 17.4203 7.67182 15.8371 6.96409 13.7098H3.95728V16.0417C5.43818 18.983 8.48182 20.9998 12 20.9998Z"
      fill="#34A853"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.96409 13.71C6.78409 13.17 6.68182 12.5932 6.68182 12C6.68182 11.4068 6.78409 10.83 6.96409 10.29V7.95819H3.95727C3.34773 9.17319 3 10.5477 3 12C3 13.4523 3.34773 14.8268 3.95727 16.0418L6.96409 13.71Z"
      fill="#FBBC05"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6.57955C13.3214 6.57955 14.5077 7.03364 15.4405 7.92545L18.0218 5.34409C16.4632 3.89182 14.4259 3 12 3C8.48182 3 5.43818 5.01682 3.95728 7.95818L6.96409 10.29C7.67182 8.16273 9.65591 6.57955 12 6.57955Z"
      fill="#EA4335"
    />
  </svg>
  <svg
    *ngIf="provider === 'twitter'"
    class="social-button__icon"
    fill="currentColor"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.919 24.94c-2.548 0-4.921-.747-6.919-2.032a9.049 9.049 0 0 0 6.681-1.867 4.512 4.512 0 0 1-4.215-3.137c.276.054.559.082.848.082.412 0 .812-.056 1.193-.156a4.519 4.519 0 0 1-3.622-4.425v-.059a4.478 4.478 0 0 0 2.042.564 4.507 4.507 0 0 1-2.008-3.758c0-.824.225-1.602.612-2.268a12.811 12.811 0 0 0 9.303 4.715 4.517 4.517 0 0 1 7.692-4.115 9.107 9.107 0 0 0 2.866-1.094 4.542 4.542 0 0 1-1.983 2.498 9.08 9.08 0 0 0 2.592-.71 9.283 9.283 0 0 1-2.252 2.337c.008.193.014.388.014.583-.001 5.962-4.542 12.843-12.844 12.842"
    />
  </svg>
  <svg
    *ngIf="provider === 'facebook'"
    class="social-button__icon"
    fill="currentColor"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z"
    />
  </svg>
  <svg
    *ngIf="provider === 'password'"
    class="social-button__icon"
    fill="currentColor"
    viewBox="-2 -2 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z"
      clip-rule="evenodd"
    />
  </svg>

  <span class="social-button__label">{{ text }}</span>
</ng-container>
