import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ed-button], a[ed-button]',
  templateUrl: './ed-button.component.html',
  styleUrls: ['./ed-button.component.scss'],
})
export class EdButtonComponent {
  @Input()
  @HostBinding()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @HostBinding('class')
  get classes() {
    return {
      button: true,
      'button--primary': this.edType === 'primary',
      'button--tile': this.edType === 'tile',
      'button--secondary': this.edType === 'secondary',
      'button--big': this.edType === 'big',
      'button--narrow': this.edType === 'narrow',
      'button--wide': this.edType === 'wide',
      'button--disabled': this.disabled,
      'button--loading': this.loading,
      'button--error': this.edStyle === 'error',
      'button--normal': this.edStyle === 'normal',
      'button--outlined': this.edType === 'outlined',
    };
  }

  @Input()
  edType:
    | 'primary'
    | 'tile'
    | 'secondary'
    | 'narrow'
    | 'big'
    | 'wide'
    | 'error'
    | 'outlined' = 'primary';

  @Input()
  edStyle: 'normal' | 'error' = 'normal';

  @Input()
  text = '';

  @Input()
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: BooleanInput) {
    this._loading = coerceBooleanProperty(value);
  }
  private _loading = false;
}
