<select
  class="select"
  [ngClass]="{
    'select--error': isInvalid && (isTouched || showErrorsDespitePristine)
  }"
  [name]="name"
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  (blur)="onBlur()"
  [compareWith]="compareWith"
>
  <option *ngIf="placeholderText" [value]="placeholderValue" disabled selected>
    {{ placeholderText }}
  </option>
  <ng-container *ngFor="let option of options">
    <option *ngIf="option" [ngValue]="option.value">
      {{ option.text }} {{ option.badge ? '[' + option.badge + ']' : '' }}
    </option>
  </ng-container>
</select>
<div
  *ngIf="isInvalid && errorText && (isTouched || showErrorsDespitePristine)"
  class="error-text"
>
  {{ errorText }}
</div>
