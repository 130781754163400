import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthQuery } from '../state/auth/auth.query';
import { ClientService } from '../state/client/client.service';

@Injectable()
export class ClientSyncGuard implements CanActivate, CanDeactivate<undefined> {
  subs: Subscription[] = [];

  constructor(
    private clientService: ClientService,
    private authQuery: AuthQuery
  ) {}

  canActivate() {
    this.unsubscribe();
    this.subs.push(
      this.clientService.syncDocument().subscribe(),
      this.authQuery.userId$.subscribe((userId) => {
        this.clientService.setActiveClient(userId);
      })
    );
    return true;
  }

  canDeactivate() {
    this.unsubscribe();
    return true;
  }

  private unsubscribe() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subs = [];
  }
}
