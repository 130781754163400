<div class="popup__backdrop" (click)="closeClick.next()"></div>
<div
  class="popup"
  [class]="{
    '--center': anchor === 'center',
    '--mobile-full-width': mobileFullWidth
  }"
  role="dialog"
  aria-modal="true"
>
  <div class="popup__content">
    <div
      *ngIf="content; else showNgContent"
      class="popup__html-content"
      [innerHTML]="content"
    ></div>
    <ng-template #showNgContent>
      <ng-content></ng-content>
    </ng-template>
  </div>
</div>
