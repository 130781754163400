import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  AuthenticateByDataInput,
  AuthenticateByDataOutput,
} from '@expresssteuer/authentication-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateByData {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: AuthenticateByDataInput) {
    console.log('Calling authentication-authenticateByData');
    return this.afFunctions.httpsCallable<
      AuthenticateByDataInput,
      AuthenticateByDataOutput
    >('authentication-authenticateByData')(input);
  }
}
