<div
  class="inline-alert"
  [class]="{
    'inline-alert--primary': type === 'primary',
    'inline-alert--warning': type === 'warning',
    'inline-alert--error': type === 'error',
    'inline-alert--shadow': shadow
  }"
>
  <div class="inline-alert__container">
    <mat-icon class="inline-alert__icon" [svgIcon]="icon"></mat-icon>
    <div class="inline-alert__text-content">
      <div *ngIf="title" class="inline-alert__title">{{ title }}</div>
      <div *ngIf="text" class="inline-alert__text" [innerHtml]="text"></div>
      <div *ngIf="primaryLabel" class="inline-alert__buttons">
        <button
          *ngIf="primaryLabel"
          type="button"
          class="inline-alert__primary-button"
          (click)="primaryClicked.next()"
        >
          {{ primaryLabel }}
        </button>
        <button
          *ngIf="secondaryLabel"
          type="button"
          class="inline-alert__primary-button"
          (click)="secondaryClicked.next()"
        >
          {{ secondaryLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
