import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { appRoutes } from '../app-routing.module';
import { AuthService } from '../state/auth/auth.service';

@Injectable()
export class HandleSignOutGuard implements CanActivate {
  private logger: EsuiLoggerService;

  constructor(
    private router: Router,
    logger: EsuiLoggerService,
    private authService: AuthService
  ) {
    this.logger = logger.getNewInstance(this);
  }

  async canActivate(next: ActivatedRouteSnapshot) {
    this.logger.debug('SignOut by url');
    await this.authService.signOut({
      withRedirect: false,
      withMixpanelReset: true,
      withKeepErrors: false,
      withoutSocialRedirectReset: false,
    });

    return this.router.createUrlTree([appRoutes.funnel.path], {
      queryParams: {
        ...next.queryParams,
      },
    });
  }
}
