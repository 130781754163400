import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ClientTaxCase, WithId } from '@expresssteuer/models';

export interface ClientTaxCasesState
  extends EntityState<WithId<ClientTaxCase>, string> {
  clientId?: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'client-tax-cases', idKey: 'id', resettable: true })
export class ClientTaxCasesStore extends EntityStore<ClientTaxCasesState> {
  constructor() {
    super();
  }
}
