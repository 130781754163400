import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, take } from 'rxjs/operators';
import { MixpanelState, MixpanelStore } from './mixpanel.store';

@Injectable({ providedIn: 'root' })
export class MixpanelQuery extends Query<MixpanelState> {
  constructor(protected store: MixpanelStore) {
    super(store);
  }

  isInitialized$ = this.select((s) => s.isInitialized);
  untilIsInitialized$ = this.isInitialized$.pipe(
    filter((e) => e),
    take(1)
  );
}
