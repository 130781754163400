import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./form-step.component.scss'],
})
export class FormStepComponent {
  constructor(private location: Location) {}

  @Input()
  headline?: string;

  @Input()
  tooltip?: string;

  @Input()
  iconButtonIcon?: string;

  @Input()
  jigglypuffMarker = false;

  @Input()
  get submitOnNext(): boolean {
    return this._submitOnNext;
  }
  set submitOnNext(value: BooleanInput) {
    this._submitOnNext = coerceBooleanProperty(value);
  }
  private _submitOnNext = false;

  @Input()
  get hideNext(): boolean {
    return this._hideNext;
  }
  set hideNext(value: BooleanInput) {
    this._hideNext = coerceBooleanProperty(value);
  }
  private _hideNext = false;

  @Input()
  get nextIsLoading(): boolean {
    return this._nextIsLoading;
  }
  set nextIsLoading(value: BooleanInput) {
    this._nextIsLoading = coerceBooleanProperty(value);
  }
  private _nextIsLoading = false;

  @Input()
  get hidePrevious(): boolean {
    return this._hidePrevious;
  }
  set hidePrevious(value: BooleanInput) {
    this._hidePrevious = coerceBooleanProperty(value);
  }
  private _hidePrevious = false;

  @Input()
  get nextDisabled(): boolean {
    return this._nextDisabled;
  }
  set nextDisabled(value: BooleanInput) {
    this._nextDisabled = coerceBooleanProperty(value);
  }
  private _nextDisabled = false;

  @Input()
  get nextWide(): boolean {
    return this._nextWide;
  }
  set nextWide(value: BooleanInput) {
    this._nextWide = coerceBooleanProperty(value);
  }
  private _nextWide = false;

  @Input()
  get mobileButtonsWide(): boolean {
    return this._mobileButtonsWide;
  }
  set mobileButtonsWide(value: BooleanInput) {
    this._mobileButtonsWide = coerceBooleanProperty(value);
  }
  private _mobileButtonsWide = false;

  @Input()
  get hideSecondary(): boolean {
    return this._hideSecondary;
  }
  set hideSecondary(value: BooleanInput) {
    this._hideSecondary = coerceBooleanProperty(value);
  }
  private _hideSecondary = true;

  @Input()
  get showSecondary(): boolean {
    return !this.hideSecondary;
  }
  set showSecondary(value: BooleanInput) {
    this.hideSecondary = !coerceBooleanProperty(value);
  }

  @Input()
  get secondaryDisabled(): boolean {
    return this._secondaryDisabled;
  }
  set secondaryDisabled(value: BooleanInput) {
    this._secondaryDisabled = coerceBooleanProperty(value);
  }
  private _secondaryDisabled = false;

  @Input()
  get secondaryIsLoading(): boolean {
    return this._secondaryIsLoading;
  }
  set secondaryIsLoading(value: BooleanInput) {
    this._secondaryIsLoading = coerceBooleanProperty(value);
  }
  private _secondaryIsLoading = false;

  @Input()
  get submitOnSecondary(): boolean {
    return this._submitOnSecondary;
  }
  set submitOnSecondary(value: BooleanInput) {
    this._submitOnSecondary = coerceBooleanProperty(value);
  }
  private _submitOnSecondary = false;

  @Input()
  secondaryText = '';

  @Input()
  primaryText?: string;

  @Output()
  nextClicked = new EventEmitter<void>();

  @Output()
  previousClicked = new EventEmitter<void>();

  @Output()
  secondaryClicked = new EventEmitter<void>();

  @Output()
  iconButtonClicked = new EventEmitter<void>();

  nextClickHandler() {
    this.nextClicked.emit();
  }

  previousClickHandler() {
    this.previousClicked.emit();
    this.location.back();
  }

  secondaryClickHandler() {
    this.secondaryClicked.emit();
  }
}
