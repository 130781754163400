import { Directive, HostListener } from '@angular/core';
import { AuthService } from '../../../state/auth/auth.service';

@Directive({
  selector: '[appLogoutButton]',
  exportAs: 'appLogoutButton',
})
export class LogoutButtonDirective {
  constructor(private authService: AuthService) {}

  @HostListener('click') onClick() {
    void this.authService.signOut();
  }
}
