import {
  combineLatest,
  defer,
  filter,
  isObservable,
  map,
  Observable,
  shareReplay,
  startWith,
} from 'rxjs';

export function withSync<T>(
  sync$: Observable<unknown> | (() => Observable<unknown>),
  callback: () => Observable<T>
): Observable<T> {
  return defer(() =>
    combineLatest({
      callbackResult: callback(),
      syncer: (isObservable(sync$) ? sync$ : sync$()).pipe(
        // emit once but stay hot:
        filter(() => false),
        startWith(void 0)
      ),
    })
      .pipe(map(({ callbackResult }) => callbackResult)) // we only care for the callbackResult
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }))
  );
}
