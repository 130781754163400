import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs';
import { JobsDataState, JobsDataStore } from './jobs-data.store';

@Injectable({ providedIn: 'root' })
export class JobsDataQuery extends Query<JobsDataState> {
  constructor(protected store: JobsDataStore) {
    super(store);
  }

  jobItemIds$ = this.select((s) => s.jobItemIds);
  jobsData$ = this.select((s) => s.jobsData);

  for = (jobItemId: string) => {
    const jobsData$ = this.jobsData$.pipe(
      map((jobsDataById) => {
        if (jobsDataById[jobItemId]) {
          return jobsDataById[jobItemId];
        } else {
          return null;
        }
      })
    );

    return {
      jobsData$,
    };
  };
}
