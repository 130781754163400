<ng-container *ngIf="{ open: callFabQuery.isOpen$ | async } as data">
  <div
    class="call-fab__backdrop"
    *ngIf="data.open"
    (click)="callFabService.setOpenState(false)"
  ></div>
  <div
    class="call-fab"
    [class]="{
      'call-fab--open': data.open
    }"
  >
    <div
      class="call-fab__container call-fab__container--closed"
      (click)="handleOpenFabButtonClick()"
    >
      <mat-icon class="call-fab__icon" svgIcon="phone-outline"></mat-icon>
    </div>
    <a
      class="call-fab__container call-fab__container--open"
      href="tel:040 87409647"
      (click)="handleCallClick()"
    >
      <mat-icon class="call-fab__icon" svgIcon="phone-outline"></mat-icon>
      <div class="call-fab__content">
        <p>{{ 'general.callFab.askHelp' | translate }}</p>
        <p>{{ 'general.callFab.number' | translate }}</p>
      </div>
    </a>
  </div>
</ng-container>
