import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  CollectionReference,
  doc,
  Firestore,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClientTodoEscalation,
  getStatus,
  TodoStatus,
} from '@expresssteuer/client-todos-api-angular';
import {
  Answer,
  AnswerFeedback,
  ClientTodo,
  ClientTodoId,
} from '@expresssteuer/client-todos-api-interfaces';
import { DeepPartial } from '@expresssteuer/deep-object-helper';
import { toDotObjectForFirebaseWithoutArrays } from '@expresssteuer/firebase-helper';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { AuthQuery } from '../auth/auth.query';
import { ClientTodoParamsQuery } from '../router/client-todo-params.query';
import { ClientTodoParamsService } from '../router/client-todo-params.service';
import { TodosQuery } from './todos.query';
import {
  ClientTodoWithStatus,
  TodoSessionState,
  TodosStore,
} from './todos.store';

@Injectable({ providedIn: 'root' })
export class TodosService {
  #logger: EsuiLoggerService;

  constructor(
    private todosStore: TodosStore,
    private todosQuery: TodosQuery,
    private authQuery: AuthQuery,
    private firestore: Firestore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clientTodoParamsService: ClientTodoParamsService,
    private clientTodoParamsQuery: ClientTodoParamsQuery,
    private clientTodoEscalation: ClientTodoEscalation,
    logger: EsuiLoggerService
  ) {
    this.#logger = logger.getNewInstance(this);
  }

  syncActiveAnswers$ = combineLatest({
    activeTodoIds: combineLatest([this.todosQuery.activeTodoId$]).pipe(
      map((ids) => ids.filter((id): id is string => !!id))
    ),
    clientId: this.authQuery.userId$,
  }).pipe(
    distinctUntilChanged((b, a) => {
      return JSON.stringify(b) === JSON.stringify(a);
    }),
    switchMap(({ clientId, activeTodoIds }) => {
      if (!clientId) {
        return of({
          noClientId: true,
        });
      }
      if (!activeTodoIds) {
        return of({
          noActiveTodoIds: true,
        });
      }

      const answerCols = activeTodoIds.map(
        (activeTodoId) =>
          collection(
            this.firestore,
            ['clients', clientId, 'clientTodos', activeTodoId, 'answers'].join(
              '/'
            )
          ) as CollectionReference<Answer>
      );

      const answerQues = answerCols.map((answerCol) =>
        query<Answer>(answerCol, orderBy('createdAt', 'desc'))
      );

      this.#logger.debug('requesting answers', { ques: answerQues });
      return combineLatest(
        answerQues.map(
          (answerQue) =>
            collectionData<Answer>(answerQue, {
              idField: 'id',
            }) as Observable<Answer[]>
        )
      ).pipe(
        map((res) => {
          return res.reduce((acc, curr, index) => {
            const id = activeTodoIds[index];
            acc[id] = Object.fromEntries(curr.map((c) => [c.id, c]));
            return acc;
          }, {} as Record<ClientTodoId, Record<string, Answer>>);
        })
      );
    }),
    tap((res) => {
      this.todosStore.update((s) => {
        const {
          noClientId,
          //  noActiveTodoIds
        } = res as {
          noClientId?: boolean;
          noActiveTodoIds?: boolean;
        };

        const resRec = res as Record<ClientTodoId, Record<string, Answer>>;

        return {
          ...s,
          answers: noClientId // || noActiveTodoIds // for now, lets keep previously needed answers in memory
            ? undefined
            : {
                ...s.answers, // for now, lets keep previously needed answers in memory TODO merge recursively?
                ...resRec,
              },
        };
      });
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  syncActiveFeedback$ = combineLatest({
    activeTodoIds: combineLatest([this.todosQuery.activeTodoId$]).pipe(
      map((ids) => ids.filter((id): id is string => !!id))
    ),
    answers: this.todosQuery.answers$,
    clientId: this.authQuery.userId$,
  }).pipe(
    distinctUntilChanged((b, a) => {
      return JSON.stringify(b) === JSON.stringify(a);
    }),
    switchMap(({ clientId, activeTodoIds, answers: answersRec }) => {
      if (!clientId) {
        return of({
          noClientId: true,
        });
      }
      if (!activeTodoIds) {
        return of({
          noActiveTodoIds: true,
        });
      }
      if (!answersRec) {
        return of({
          noAnswers: true,
        });
      }

      const combi: {
        path: string;
        todoId: ClientTodoId;
        answerId: string;
      }[] = [];
      activeTodoIds.forEach((todoId) => {
        const answers = answersRec[todoId];

        Object.keys(answers ?? {}).forEach((answerId) => {
          const path = [
            'clients',
            clientId,
            'clientTodos',
            todoId,
            'answers',
            answerId,
            'feedback',
          ].join('/');
          combi.push({
            path,
            todoId,
            answerId,
          });
        });
      });

      const feedbackCols = combi.map(
        ({ path }) =>
          collection(
            this.firestore,
            path
          ) as CollectionReference<AnswerFeedback>
      );

      const feedbackQues = feedbackCols.map((feedbackCol) =>
        query<AnswerFeedback>(feedbackCol, orderBy('createdAt', 'desc'))
      );

      this.#logger.debug('requesting answers', { ques: feedbackQues });
      return combineLatest(
        feedbackQues.map(
          (feedbackQue) =>
            collectionData<AnswerFeedback>(feedbackQue, {
              idField: 'id',
            }) as Observable<(AnswerFeedback & { id: string })[]>
        )
      ).pipe(
        map((res) => {
          return res.reduce((acc, curr, index) => {
            const feedbackRec = Object.fromEntries(
              curr.map((feedback) => [feedback.id, feedback])
            );
            const relatedCombi = combi[index];

            return {
              ...acc,
              [relatedCombi.todoId]: {
                ...acc[relatedCombi.todoId],
                [relatedCombi.answerId]: feedbackRec,
              },
            };
          }, {} as Record<ClientTodoId, Record<string, Record<string, AnswerFeedback>>>);
        })
      );
    }),
    tap((res) => {
      this.todosStore.update((s) => {
        const {
          noClientId,
          // noActiveTodoIds,
          // noAnswers,
        } = res as {
          noClientId?: boolean;
          noActiveTodoIds?: boolean;
          noAnswers?: boolean;
        };

        const resRec = res as Record<
          ClientTodoId,
          Record<string, Record<string, AnswerFeedback>>
        >;
        return {
          ...s,
          feedback: noClientId // || noActiveTodoIds || noAnswers // for now, lets keep previously needed feedback in memory
            ? undefined
            : { ...s.feedback, ...resRec }, // for now, lets keep previously needed feedback in memory TODO merge recursively?
        };
      });
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  syncTodos$ = this.authQuery.userId$.pipe(
    distinctUntilChanged(),
    switchMap((clientId) => {
      if (!clientId) {
        return of({
          noClientId: true,
        });
      }

      const todoCol = collection(
        this.firestore,
        ['clients', clientId, 'clientTodos'].join('/')
      ) as CollectionReference<ClientTodo>;

      const todoQue = query<ClientTodo>(todoCol);

      this.#logger.debug('requesting todos', { que: todoQue });
      return (
        collectionData<ClientTodo>(todoQue, {
          idField: 'id',
        }) as Observable<ClientTodo[]>
      ).pipe(
        map((todos) =>
          Object.fromEntries(
            todos.map((todo) => [todo.id, { ...todo, status: getStatus(todo) }])
          )
        )
      );
    }),
    tap((res) => {
      this.todosStore.update((s) => {
        const todos = (res as { noClientId?: boolean }).noClientId
          ? undefined
          : (res as Record<ClientTodoId, ClientTodoWithStatus>);

        return {
          ...s,
          todos,
          session: {
            ...s.session,
            ...this.buildSessionStateForTodosInFinalState(
              Object.values(todos ?? [])
            ),
          },
        };
      });
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  private urlParamsSyncer = {
    storeToParams$: this.todosQuery.activeTodoId$.pipe(
      distinctUntilChanged(),
      filter((activeTodoId): activeTodoId is ClientTodoId => !!activeTodoId),
      switchMap(async (activeTodoId) => {
        return {
          event: 'active changed in store',
          action: 'setting activeTodoId in url',
          response: await this.clientTodoParamsService.setActiveClientTodo(
            activeTodoId
          ),
        };
      })
    ),
    paramsToStore$: this.clientTodoParamsQuery.activeClientTodo$
      .pipe(distinctUntilChanged())
      .pipe(
        distinctUntilChanged(),
        switchMap(async (activeTodoIdFromUrl) => {
          return {
            event: 'activeTodoIdFromUrl changed',
            action: 'setting active in store',
            response: this.setActiveTodoId(activeTodoIdFromUrl),
          };
        })
      ),
  };

  public syncUrlParams$ = merge(
    this.urlParamsSyncer.storeToParams$,
    this.urlParamsSyncer.paramsToStore$
  ).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  syncAll$ = combineLatest([
    this.syncActiveAnswers$,
    this.syncTodos$,
    this.syncActiveFeedback$,
  ]);

  private buildSessionStateForTodosInFinalState(
    todos: ClientTodoWithStatus[]
  ): Record<string, TodoSessionState> {
    return Object.fromEntries(
      todos
        .filter(
          (todo) =>
            todo.status === TodoStatus.erroneous ||
            todo.status === TodoStatus.completed
        )
        .map((todo) => [todo.id, {}])
    );
  }

  setActiveTodoId(id?: ClientTodoId) {
    this.todosStore.update({
      activeTodoId: id,
    });
  }

  async escalateActiveTodo() {
    const todoId = this.todosQuery.activeTodoId$.getSnapshot();
    if (!todoId) {
      const clientId = this.authQuery.getUserId();
      this.#logger.error('Initiating escalation without todoId, skipping', {
        todoId,
        clientId,
      });
      return;
    }

    this.todosStore.updateSession({
      todoId,
      reduce: (_s) => ({
        initiatedEscalation: true,
      }),
    });

    return lastValueFrom(this.clientTodoEscalation.call({ todoId }))
      .then(() => {
        this.todosStore.updateSession({
          todoId,
          reduce: (s) => ({
            ...s,
            succeededToEscalateTodo: true,
          }),
        });
      })
      .catch((err) => {
        const clientId = this.authQuery.getUserId();
        this.#logger.error(
          'Failed to escalate todo',
          {
            todoId,
            clientId,
          },
          err
        );
        this.todosStore.updateSession({
          todoId,
          reduce: (s) => ({
            ...s,
            failedToEscalateTodo: true,
          }),
        });
      });
  }

  async upsertAnswerToActiveTodo(answerChange: DeepPartial<Answer>) {
    const clientId = this.authQuery.getUserId();
    if (!clientId) {
      this.#logger.error('no clientId to upsertAnswerToActiveTodo');
      return;
    }

    const { answer: currentAnswer, todo } =
      this.todosQuery.activeGroup$.getSnapshot() ?? {};

    if (!todo?.id) {
      this.#logger.error('no todo id found');
      return;
    }

    // DeepPartial makes ready optional. Set ready to false if undefined or null
    if (answerChange.ready == null) {
      answerChange.ready = false;
    }

    if (answerChange.ready) {
      this.todosStore.updateSession({
        todoId: todo.id,
        reduce: (_s) => ({
          initiatedCompletion: true,
        }),
      });
    } else {
      this.todosStore.updateSession({
        todoId: todo.id,
        reduce: (_s) => ({
          initiatedDraftUpsert: true,
        }),
      });
    }

    // upsert the answer
    await runTransaction(this.firestore, async (transaction) => {
      if (currentAnswer && !currentAnswer.ready) {
        // update answer a it looks like the answer is still in draft mode
        const answerDocRef = doc(
          this.firestore,
          [
            'clients',
            clientId,
            'clientTodos',
            todo.id,
            'answers',
            currentAnswer.id,
          ].join('/')
        );

        const answerSnap = await transaction.get(answerDocRef);
        const dbAnswer = answerSnap.data() as Answer;
        // verify if its really still in draft mode
        if (!dbAnswer.ready) {
          return transaction.update(
            answerDocRef,
            toDotObjectForFirebaseWithoutArrays(answerChange)
          );
        }
      }

      // create a new answer as there is no answer in draft mode
      const answerColRef = collection(
        this.firestore,
        ['clients', clientId, 'clientTodos', todo.id, 'answers'].join('/')
      );

      const newAnswer = {
        createdAt: serverTimestamp(),
        ...answerChange,
      } as Answer;
      return addDoc(answerColRef, newAnswer);
    })
      .then((_docRef) => {
        this.todosStore.updateSession({
          todoId: todo.id,
          reduce: (s) => ({
            ...s,
            succeededToWriteAnswer: true,
          }),
        });
      })
      .catch((err) => {
        this.#logger.error(
          `failed to complete todo`,
          { todo, clientId, answerChange },
          err
        );
        this.todosStore.updateSession({
          todoId: todo.id,
          reduce: (s) => ({
            ...s,
            failedToWriteAnswer: true,
          }),
        });
      });
  }

  updateSessionStateForActiveTodo(newSessionState: Partial<TodoSessionState>) {
    const { todo } = this.todosQuery.activeGroup$.getSnapshot() ?? {};
    if (todo?.id) {
      this.todosStore.updateSession({
        todoId: todo.id,
        reduce: (s) => ({
          ...s,
          ...newSessionState,
        }),
      });
    }
  }
}
