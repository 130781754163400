import {
  DateStringZod,
  ExpsAddressZod,
  HOMEOFFICE,
  MARITALSTATUS,
  OFFSITE,
} from '@expresssteuer/models';
import {
  boolean,
  input,
  literal,
  nativeEnum,
  number,
  object,
  string,
} from 'zod';

export enum WayToSpecifyDistanceToWorkV1 {
  'estimation' = 'estimation',
  'address_calculation' = 'address-calculation',
}
export enum Generator {
  'andorraSignup' = 'andorraSignup',
  'mobileAppAndroidSignup' = 'mobileAppAndroidSignup',
  'mobileAppIOSSignup' = 'mobileAppIOSSignup',
}
export const SignUpV1MetadataZod = object({
  isTest: boolean().optional(),
  friendId: string().optional(),
  campaignId: string().optional(),
  promoCode: string().optional(),
  referralCode: string().optional(),
  voluumId: string().optional(),
  awinId: string().optional(),
  gaClientId: string().optional(),
  initialQueryParams: string().optional(),
  referrer: string().optional(),
  userAgent: string().optional(),
  generator: nativeEnum(Generator).optional(),
  contractPackageVersion: string().optional(),
});
export const SignUpV1Zod = object({
  version: literal('1'),
  taxYear: number(),
  maritalStatus: nativeEnum(MARITALSTATUS),
  marriedSince: DateStringZod.optional(),
  divorcedSince: DateStringZod.optional(),
  monthlySalary: number(),
  workingMonths: number().lte(12),
  isSelfEmployed: boolean(),
  isSoliderOrCivilServantOrPensioner: boolean(),
  hasHomeOffice: boolean(),
  homeOfficeDays: nativeEnum(HOMEOFFICE).optional(),
  distanceToWork: number(),
  homeAddress: ExpsAddressZod.optional(),
  workAddress: ExpsAddressZod.optional(),
  wayToSpecifyDistanceToWork: nativeEnum(WayToSpecifyDistanceToWorkV1),
  hasOffsite: boolean(),
  offsiteDays: nativeEnum(OFFSITE).optional(),
  acceptTerms: boolean(),
  clientFirstName: string(),
  clientLastName: string(),
  clientBirthdate: DateStringZod,
  clientCurrentAddress: ExpsAddressZod,
  clientEmail: string().email(),
  clientPhone: string(),
  clientSignature: string().optional(), // soon required
  partnerFirstName: string().optional(),
  partnerLastName: string().optional(),
  partnerBirthdate: DateStringZod.optional(),
  partnerSignature: string().optional(),
  partnerSignLater: boolean().optional(),
  partnerAcceptTerms: boolean().optional(),
  languageKey: string().optional(),
  metadata: SignUpV1MetadataZod.optional(),
});

export const SignUpV1ZodEffects = SignUpV1Zod.refine(
  (data) =>
    data.maritalStatus === MARITALSTATUS.married ? data.marriedSince : true,
  {
    message: 'marriedSince must be filled if married',
  }
)
  .refine(
    (data) =>
      data.maritalStatus === MARITALSTATUS.married
        ? data.partnerFirstName && data.partnerLastName && data.partnerBirthdate
        : true,
    {
      message: 'partner data must be filled if married',
    }
  )
  .refine(
    (data) =>
      data.maritalStatus === MARITALSTATUS.divorced ? data.divorcedSince : true,
    {
      message: 'divorcedSince must be filled if divorced',
    }
  )
  .refine(
    (data) => (data.hasHomeOffice ? data.homeOfficeDays !== undefined : true),
    {
      message: 'homeOfficeDays must be filled if hasHomeOffice',
    }
  )
  .refine((data) => (data.hasOffsite ? data.offsiteDays !== undefined : true), {
    message: 'offsiteDays must be filled if hasOffsite',
  })
  .refine(
    (data) =>
      data.wayToSpecifyDistanceToWork ===
      WayToSpecifyDistanceToWorkV1.address_calculation
        ? data.homeAddress !== undefined && data.workAddress !== undefined
        : true,
    {
      message:
        'home and work address must be filled if distance calculation was selected',
    }
  );
export type SignUpV1 = input<typeof SignUpV1Zod>;
export type SignUpV1Metadata = input<typeof SignUpV1MetadataZod>;
export const SignUpV1Defaults: Partial<SignUpV1> = {
  workingMonths: 12,
  isSelfEmployed: false,
  isSoliderOrCivilServantOrPensioner: false,
  distanceToWork: 15000,
  wayToSpecifyDistanceToWork: WayToSpecifyDistanceToWorkV1.estimation,
};
