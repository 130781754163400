import { Component } from '@angular/core';
import { buildMeta } from '../../../../build-meta/build-meta';
import {
  NavbarEventName,
  StandardEvent,
} from '../../../state/analytics/event-types/standard.event';
import { MixpanelService } from '../../../state/analytics/mixpanel.service';

@Component({
  selector: 'app-action-hotline',
  templateUrl: './action-hotline.component.html',
  styleUrls: ['../navigation-user-actions.scss'],
})
export class ActionHotlineComponent {
  constructor(private mixpanelService: MixpanelService) {}

  phoneButtonClicked() {
    this.mixpanelService.sendEvent(
      new StandardEvent(NavbarEventName.PhoneButtonClicked, {
        buildHash: buildMeta.commitHash,
      })
    );
  }
}
