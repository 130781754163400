import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-menu-button',
  templateUrl: './mobile-menu-button.component.html',
  styleUrls: ['./mobile-menu-button.component.scss'],
})
export class MobileMenuButtonComponent {
  @Input()
  get showMobileMenu() {
    return this._showMobileMenu;
  }
  set showMobileMenu(value: BooleanInput) {
    this._showMobileMenu = coerceBooleanProperty(value);
  }
  private _showMobileMenu?: boolean;

  @Input() badgeContent?: string | number | null;
  @Input() showBadge = false;

  @Output()
  clicked = new EventEmitter<void>();
}
