import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  forwardRef,
  InjectFlags,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { Timestamp } from '@expresssteuer/models';
import { UnkownFunction } from '../helper/helper';

@Component({
  selector: 'ed-date',
  templateUrl: './ed-date.component.html',
  styleUrls: ['./ed-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EdDateComponent),
      multi: true,
    },
  ],
})
export class EdDateComponent implements ControlValueAccessor, OnInit {
  private static nextUniqueId = 0;

  @Input()
  placeholder? = '';

  @Input()
  label = '';

  @Input()
  type: 'date' | 'birthdate' = 'date';

  @Input()
  name = `ed-date-${EdDateComponent.nextUniqueId++}`;

  @Input()
  get showErrorsDespitePristine() {
    return this._showErrorsDespitePristine;
  }
  set showErrorsDespitePristine(value: BooleanInput) {
    this._showErrorsDespitePristine = coerceBooleanProperty(value);
  }
  private _showErrorsDespitePristine = false;

  @Input()
  errorText?: string | null;

  @Input()
  renderErrorContent?: boolean;

  @Input()
  min?: string;
  DEFAULT_BIRTHDAY_MIN_VALUE = '1900-01-01';

  @Input()
  max?: string;
  DEFAULT_MAX_VALUE = '9999-12-31'; // limit to 4-digits year per default
  DEFAULT_BIRTHDAY_MAX_VALUE = this.convertTimestampToDateInputString(
    Timestamp.fromDate(new Date())
  );

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  value = '';
  isBlurred = false;

  private ngControl?: NgControl | null;

  get isInvalid() {
    return this.ngControl?.invalid;
  }
  get isTouched() {
    return this.ngControl?.touched;
  }
  get isDirty() {
    return this.ngControl?.dirty;
  }
  get isValid() {
    return this.ngControl?.valid;
  }
  get isUntouched() {
    return this.ngControl?.untouched;
  }
  get isPristine() {
    return this.ngControl?.pristine;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: Timestamp | null) => {};
  registerOnChange(fn: UnkownFunction) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};
  registerOnTouched(fn: UnkownFunction) {
    this.onTouch = fn;
  }

  writeValue(timestamp: Timestamp) {
    this.value = this.convertTimestampToDateInputString(timestamp);
  }

  blurTriggered() {
    this.onTouch();
    this.isBlurred = true;
  }

  inputChanged(dateString: string) {
    if (!dateString) {
      this.onChange(null);
      return;
    }
    const date = new Date(dateString);
    this.onChange(Timestamp.fromDate(date));
  }

  // {seconds: , nanoseconds: } -> 2022-05-22
  private convertTimestampToDateInputString(rawTimestamp: Timestamp): string {
    if (!rawTimestamp?.seconds) {
      return '';
    }
    const timestamp = new Timestamp(
      rawTimestamp?.seconds,
      rawTimestamp?.nanoseconds
    );
    const day = String(timestamp?.toDate().getUTCDate()).padStart(2, '0');
    const month = String(timestamp?.toDate().getUTCMonth() + 1).padStart(
      2,
      '0'
    );
    const year = String(timestamp?.toDate().getUTCFullYear());
    return `${year}-${month}-${day}`;
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl, null, InjectFlags.Optional);
  }

  constructor(private injector: Injector) {}
}
