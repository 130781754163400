import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EdIconButtonComponent } from './ed-icon-button.component';

@NgModule({
  declarations: [EdIconButtonComponent],
  imports: [CommonModule, MatIconModule],
  exports: [EdIconButtonComponent],
})
export class EdIconButtonModule {}
