import { LoggingLevel } from '@expresssteuer/ui-components';

export const environment = {
  name: 'dev',
  production: false,
  emulator: false,
  level: LoggingLevel.Debug,

  firebase: {
    apiKey: 'AIzaSyA7aHq45T9jOnO6Gsc8_mxNSx7UEKJZPMc',
    authDomain: 'app-dev.expresssteuer.de',
    databaseURL:
      'https://expresssteuer-development-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'expresssteuer-development',
    storageBucket: 'expresssteuer-development.appspot.com',
    storageClientUploadBucket: 'expresssteuer-dev-client-upload',
    messagingSenderId: '849191636990',
    appId: '1:849191636990:web:c48cfc39beff4c6f87baaf',
    measurementId: 'G-ZY2M1Y7PGT',
  },
  googleMaps: {
    apiKey: 'AIzaSyA_6-IH14ugWxj6UIOaBq5swwN7Ta7dOAQ',
  },
  googleAnalytics: {
    account: '',
  },
  facebook: {
    account: '',
  },
  googleTagManager: {
    id: 'GTM-PHG6XZ9',
  },
  braze: {
    apiKey: '24c23451-b9f4-4e6c-b6eb-bee2cfc1e346',
    endpoint: 'sdk.fra-02.braze.eu',
  },
  mixpanel: {
    token: 'eadc954bd6da8ad7bdada938f50a03ae',
    apiHost: 'https://api-eu.mixpanel.com',
    debug: true,
    crossSubdomainCookie: true,
  },
  awin: {
    awinAdvertiserId: 37000,
    enforceAwinTest: true,
  },
  sentry: {
    dsn: 'https://f8635624183f47fda54779315c566f28@o4504361035497472.ingest.sentry.io/4504361039233024',
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      'europe-west3-expresssteuer-development.cloudfunctions.net',
      'firestore.googleapis.com',
    ],
    level: LoggingLevel.Errors,
  },
  intercom: {
    app_id: 'ctsu0zz9',
    api_base: 'https://api-iam.eu.intercom.io',
  },
  toggles: {
    redirectToInitialSlide: true,
    useBraze: true,
    useBrazePushPermissionButton: true,
    useGoogleAnalytics: false,
    useGtm: true,
    useFacebookAnalytics: false,
    useMixpanel: true,
    useAwin: true,
    useSentry: false,
    sendHotjarEvents: false,
  },
  enforcedABTests: {},
  data: {
    supportedTaxYears: [2022, 2021, 2020, 2019],
  },
  friendsInviteBaseURL: 'https://app-dev.expresssteuer.de/info?friendid=',
  noServiceAvailableEmailAddress: 'rbs@expresssteuer.de',
};
