import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  ManualTriggerInput,
  ManualTriggerOutput,
} from '@expresssteuer/client-todos-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ManualTrigger {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: ManualTriggerInput) {
    console.log('Calling clientTodos-manualTrigger');
    return this.afFunctions.httpsCallable<
      ManualTriggerInput,
      ManualTriggerOutput
    >('clientTodos-manualTrigger')(input);
  }
}
