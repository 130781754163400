import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  ClientTaxCase,
  TAXCASESTATUS,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
  WithId,
} from '@expresssteuer/models';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { StaticDataQuery } from '../static-data/static-data.query';
import {
  ClientTaxCasesState,
  ClientTaxCasesStore,
} from './client-tax-cases.store';

@Injectable({ providedIn: 'root' })
export class ClientTaxCasesQuery extends QueryEntity<ClientTaxCasesState> {
  constructor(
    protected store: ClientTaxCasesStore,
    private staticDataQuery: StaticDataQuery
  ) {
    super(store);
  }

  clientId$ = this.select((s) => s.clientId);
  taxCases$ = this.select((s) => (s.entities ? Object.values(s.entities) : []));
  taxCasesFilteredDuplicatesByYear$ = this.taxCases$.pipe(
    map((taxCases) =>
      taxCases.reduce((filteredTaxCases, currentTaxCase) => {
        const duplicatedTaxCaseIndex = filteredTaxCases.findIndex(
          (filteredTaxCase) =>
            filteredTaxCase.taxYear === currentTaxCase.taxYear
        );
        if (duplicatedTaxCaseIndex >= 0) {
          if (
            filteredTaxCases[duplicatedTaxCaseIndex].status?.current ===
              TAXCASESTATUS.REJECT &&
            filteredTaxCases[duplicatedTaxCaseIndex].status?.substatus ===
              TAXCASESTATUS_REJECT.DOUBLE
          ) {
            filteredTaxCases[duplicatedTaxCaseIndex] = currentTaxCase;
          }
          return filteredTaxCases;
        }
        return [...filteredTaxCases, currentTaxCase];
      }, [] as WithId<ClientTaxCase>[])
    )
  );
  taxCasesReadyForClientEdit$ = this.taxCases$.pipe(
    map((taxCases) =>
      taxCases.filter(
        (taxCase) =>
          taxCase.status?.current === TAXCASESTATUS.INPROGRESS &&
          (taxCase.status?.substatus ===
            TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL ||
            taxCase.status?.substatus ===
              TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_EDITS)
      )
    )
  );
  yearsWithExistingTaxCase$ = this.taxCasesFilteredDuplicatesByYear$.pipe(
    map((taxCases) => taxCases.map((taxCase) => parseInt(taxCase.taxYear)))
  );
  taxCasesWaitingForClientApproval$ =
    this.taxCasesFilteredDuplicatesByYear$.pipe(
      map((taxCases) =>
        taxCases.filter(
          (taxCase) =>
            taxCase.status?.current === TAXCASESTATUS.INPROGRESS &&
            taxCase.status?.substatus ===
              TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL
        )
      )
    );
  taxCasesWaitingForClientEdits$ = this.taxCasesFilteredDuplicatesByYear$.pipe(
    map((taxCases) =>
      taxCases.filter(
        (taxCase) =>
          taxCase.status?.current === TAXCASESTATUS.INPROGRESS &&
          taxCase.status?.substatus ===
            TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_EDITS
      )
    )
  );
  taxCasesWaitingForClientApprovalAndClientEdits$ =
    this.taxCasesFilteredDuplicatesByYear$.pipe(
      map((taxCases) =>
        taxCases.filter(
          (taxCase) =>
            taxCase.status?.current === TAXCASESTATUS.INPROGRESS &&
            (taxCase.status?.substatus ===
              TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_EDITS ||
              taxCase.status?.substatus ===
                TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL)
        )
      )
    );
  taxCasesWaitingForClientApprovalAndClientEditsCount$ =
    this.taxCasesWaitingForClientApprovalAndClientEdits$.pipe(
      map((taxCases) => taxCases?.length || 0)
    );
  availableTaxYears$ = combineLatest([
    this.staticDataQuery.offeredYears$,
    this.yearsWithExistingTaxCase$,
  ]).pipe(
    map(([offeredYears, yearsWithExistingTaxCase]) => {
      const offeredYearsValues = offeredYears.map((e) => e.value);
      return [
        ...offeredYearsValues,
        ...yearsWithExistingTaxCase.filter(
          (year) => !offeredYearsValues.includes(year)
        ),
      ].sort((a, b) => b - a);
    })
  );
  taxCasesLoading$ = this.selectLoading();
}
