import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EdNumbersOnlyDirective } from './ed-numbers-only/ed-numbers-only.directive';
import { EdTextfieldComponent } from './ed-textfield.component';

@NgModule({
  declarations: [EdTextfieldComponent, EdNumbersOnlyDirective],
  imports: [CommonModule, FormsModule],
  exports: [EdTextfieldComponent],
})
export class EdTextfieldModule {}
