import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { selectReader } from '../reader/reader';
import { UrlStore } from './url-store';

@Injectable()
export class UrlQuery<TState = unknown> {
  constructor(protected store: UrlStore<TState>) {}

  select<TValue>(selector: (state: TState) => TValue): Observable<TValue> {
    return this.store.store$.pipe(map(selector));
  }

  selectReader<TValue>(selector: (state: TState) => TValue) {
    return selectReader(this.store, this, selector);
  }
}
