import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { GenerateFrontendContractHtmlOutput } from '@expresssteuer/document-api-interfaces';

export interface SignableContractState {
  contract?: GenerateFrontendContractHtmlOutput;
}

export function createInitialState(): SignableContractState {
  return {};
}
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'authority-for-tax-office' })
export class SignableContractStore extends Store<SignableContractState> {
  constructor() {
    super(createInitialState());
  }
}
