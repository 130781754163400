import { MARITALSTATUS } from '@expresssteuer/models';
import { boolean, input, literal, number, object } from 'zod';
import {
  SignUpV1,
  SignUpV1Zod,
  WayToSpecifyDistanceToWorkV1,
} from './sign-up.api-interface.v1';

/**
 * V3 is basically V1 but has more optional fields which are not required for taxcase creation
 * namely [monthlySalary,workingMonths,hasHomeOffice,hasOffsite,distanceToWork]
 */
export const SignUpV3Zod = SignUpV1Zod.merge(
  object({
    version: literal('3'),
    monthlySalary: number().optional(),
    workingMonths: number().lte(12).optional(),
    hasHomeOffice: boolean().optional(),
    hasOffsite: boolean().optional(),
    distanceToWork: number().optional(),
  })
);
export const SignUpV3ZodEffects = SignUpV3Zod.refine(
  (data) =>
    data.maritalStatus === MARITALSTATUS.married ? data.marriedSince : true,
  {
    message: 'marriedSince must be filled if married',
  }
)
  .refine(
    (data) =>
      data.maritalStatus === MARITALSTATUS.married
        ? data.partnerFirstName && data.partnerLastName && data.partnerBirthdate
        : true,
    {
      message: 'partner data must be filled if married',
    }
  )
  .refine(
    (data) =>
      data.maritalStatus === MARITALSTATUS.divorced ? data.divorcedSince : true,
    {
      message: 'divorcedSince must be filled if divorced',
    }
  )
  .refine(
    (data) => (data.hasHomeOffice ? data.homeOfficeDays !== undefined : true),
    {
      message: 'homeOfficeDays must be filled if hasHomeOffice',
    }
  )
  .refine((data) => (data.hasOffsite ? data.offsiteDays !== undefined : true), {
    message: 'offsiteDays must be filled if hasOffsite',
  })
  .refine(
    (data) =>
      data.wayToSpecifyDistanceToWork ===
      WayToSpecifyDistanceToWorkV1.address_calculation
        ? data.homeAddress !== undefined && data.workAddress !== undefined
        : true,
    {
      message:
        'home and work address must be filled if distance calculation was selected',
    }
  );
export type SignUpV3 = input<typeof SignUpV3Zod>;
export function fromPrevious(previous: SignUpV1): SignUpV3 {
  return {
    ...previous,
    version: '3',
  };
}
