export abstract class GetSignInLinkInput {
  public static guard(input: unknown): input is GetSignInLinkInput {
    if (typeof input !== 'object' || input === null) {
      return false;
    }

    const { route } = input as GetSignInLinkInput;

    if (typeof route !== 'string' || route.trim().length === 0) {
      return false;
    }

    return true;
  }
}

export interface GetSignInLinkInput {
  /**
   * a route to be redirected to in the webapp after successful login
   */
  route: string;
}

export interface GetSignInLinkOutput {
  /**
   * a link that redirects to the correct route on the webapp
   */
  link: string;
}
