import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EdSocialButtonComponent } from './ed-social-button.component';

@NgModule({
  declarations: [EdSocialButtonComponent],
  imports: [CommonModule, MatIconModule],
  exports: [EdSocialButtonComponent],
})
export class EdSocialButtonModule {}
