import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SignUpV1 } from '@expresssteuer/signup-api-interfaces';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignupState extends Partial<SignUpV1> {}

export function createInitialState(): SignupState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'signup', resettable: true })
export class SignupStore extends Store<SignupState> {
  constructor() {
    super(createInitialState());
  }
}
