import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { firstValueFrom, of } from 'rxjs';
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators';
import { GoogleMapsApiService } from './google-maps-api.service';
import {
  GoogleMapsApiState,
  GoogleMapsApiStore,
} from './google-maps-api.store';

@Injectable({ providedIn: 'root' })
export class GoogleMapsApiQuery extends Query<GoogleMapsApiState> {
  constructor(
    protected store: GoogleMapsApiStore,
    private googleMapsApiService: GoogleMapsApiService
  ) {
    super(store);
  }

  googleMapsApiIsLoaded$ = of(void 0).pipe(
    tap(() => {
      this.googleMapsApiService.loadGoogleMapsApiScript();
    }),
    switchMap(() => this.select((s) => s.googleMapsApiLoaded)),
    shareReplay(1)
  );
  waitUntilGoogleMapsApiIsLoaded() {
    return firstValueFrom(
      this.googleMapsApiIsLoaded$.pipe(filter((e: boolean) => e))
    );
  }
  getGoogleMapsApiIsLoaded() {
    return this.store.getValue().googleMapsApiLoaded;
  }
}
