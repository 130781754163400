import { Injectable } from '@angular/core';
import { AuthQuery } from '../auth/auth.query';
import { ClientQuery } from '../client/client.query';

/*
  Use abstraction query for user information from client and auth.
 */
@Injectable({ providedIn: 'root' })
export class UserQuery {
  constructor(private authQuery: AuthQuery, private clientQuery: ClientQuery) {}

  firstname$ = this.clientQuery.firstname$;
  firebaseEmail$ = this.authQuery.firebaseEmail$;
  isLoggedIn$ = this.authQuery.isLoggedIn$;
}
