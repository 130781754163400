import { Injectable } from '@angular/core';
import { CallFabStore } from './call-fab.store';

@Injectable({ providedIn: 'root' })
export class CallFabService {
  constructor(private store: CallFabStore) {}

  setOpenState(open: boolean) {
    this.store.update({ open });
  }
}
