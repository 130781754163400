import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { appRoutes } from '../../app-routing.module';

export const queryParams = {
  mode: {
    /**
     * from https://github.com/firebase/firebaseui-web/blob/832134e0432a31802cf542e48e241fc762a7302d/README.md#available-callbacks
     */
    signIn: 'signIn',
  },
};

@Injectable({ providedIn: 'root' })
export class LoginParamsQuery {
  private window;

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private documentRef: Document
  ) {
    if (!this.documentRef.defaultView) {
      throw new Error('no window????');
    }
    this.window = this.documentRef.defaultView;
  }

  private continueDefault = appRoutes.myaccount.path;

  private continueFromUrl$ = this.activatedRoute.queryParams.pipe(
    map<{ continue?: string | unknown }, string | undefined>((params) => {
      const continueString = params['continue'];
      if (!continueString || typeof continueString !== 'string') {
        return undefined;
      }
      return this.window?.decodeURIComponent(continueString);
    })
  );

  continueUrl$ = this.continueFromUrl$.pipe(
    map((e) => {
      if (e) {
        return e;
      }
      return this.continueDefault;
    })
  );

  hasOneTouchContinueUrl$ = this.continueFromUrl$.pipe(
    map((e) => e?.includes('taxdataedit') ?? false)
  );

  public isSignInLink$ = this.activatedRoute.queryParams.pipe(
    map((params) => {
      return params.mode === queryParams.mode.signIn;
    })
  );

  public getIsSignInLink() {
    return (
      this.activatedRoute?.snapshot?.queryParams?.mode ===
      queryParams.mode.signIn
    );
  }
}
