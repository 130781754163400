export const SUPPORTED_LANGUAGES = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  pl: 'Polskie',
  ru: 'Pусский',
  ro: 'Română',
  tr: 'Türk',
};

export const SUPPORTED_LANGUAGE_KEYS = Object.keys(
  SUPPORTED_LANGUAGES
) as (keyof typeof SUPPORTED_LANGUAGES)[];

export type SupportedLanguageKey = typeof SUPPORTED_LANGUAGE_KEYS[number];

export function supportedLanguageKeyGuard(
  value?: string
): value is SupportedLanguageKey {
  return (
    !!value &&
    SUPPORTED_LANGUAGE_KEYS.includes(value as keyof typeof SUPPORTED_LANGUAGES)
  );
}
