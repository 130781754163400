import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IbanUpdate } from '@expresssteuer/client-api-angular';
import { Client } from '@expresssteuer/models';
import { FirebaseDocumentService } from '@expresssteuer/state-angular';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { ClientQuery } from './client.query';
import { ClientError, ClientLoader, ClientStore } from './client.store';

@Injectable({ providedIn: 'root' })
export class ClientService extends FirebaseDocumentService<
  Client,
  undefined,
  ClientError,
  ClientLoader
> {
  #logger: EsuiLoggerService;
  constructor(
    private clientStore: ClientStore,
    private clientQuery: ClientQuery,
    afs: AngularFirestore,
    private ibanUpdate: IbanUpdate,
    logger: EsuiLoggerService
  ) {
    super(clientStore, clientQuery, afs, logger);
    this.#logger = logger.getNewInstance(this);
  }
  public setActiveClient(clientId?: string) {
    this.reset();
    if (clientId) {
      this.setActiveDocumentReference(`clients/${clientId}`);
    }
  }

  public setIban(iban: string) {
    this.updateLocalChanges({ bank: { IBAN: iban } });
  }

  public async saveIban() {
    console.log('saveIban');
    const bank =
      this.clientStore.getCurrentUnsavedChangesAsDeepPartial()?.bank ?? {};

    const hasChanges = 'IBAN' in bank;
    if (!hasChanges) {
      this.#logger.debug('skpping saveIban as there are no iban changes');
      return;
    }
    const iban = bank.IBAN;
    const clientId = this.clientStore.getValue().data?.id;

    if (!clientId) {
      this.#logger.error('no clientId to update iban');
      this.clientStore.updateError({ failedToUpdateIban: true });
      return;
    }

    this.clientStore.updateLoading({ updatingIban: true });
    this.clientStore.updateError({ failedToUpdateIban: false });

    await this.ibanUpdate
      .call({
        clientId,
        iban: iban || null,
      })
      .toPromise()
      .then(() => {
        this.clientStore.deleteLocalChangedDataFor(['bank']);
        return;
      })
      .catch((e) => {
        this.#logger.error('failed to update Iban', e);
        this.clientStore.updateError({ failedToUpdateIban: true });
        return;
      });

    this.clientStore.updateLoading({ updatingIban: false });
  }

  override async save(): Promise<void> {
    const saveOperationPs = [this.saveIban()];

    await Promise.all(saveOperationPs);
  }
}
