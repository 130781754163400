import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureTogglesQuery } from '@expresssteuer/state-angular';
import { Subscription, combineLatest, of, switchMap } from 'rxjs';
import { buildMeta } from '../../../build-meta/build-meta';
import { ABTestConfigQuery } from '../../state/a-b-test/a-b-test-config.query';
import {
  StandardEvent,
  UserProfileEventName,
} from '../../state/analytics/event-types/standard.event';
import { MixpanelService } from '../../state/analytics/mixpanel.service';
import { AuthQuery } from '../../state/auth/auth.query';
import { ClientTaxCasesQuery } from '../../state/client-tax-cases/client-tax-cases.query';
import { ClientTaxCasesService } from '../../state/client-tax-cases/client-tax-cases.service';
import { TodosQuery } from '../../state/todos/todos.query';
import { UrlQuery } from '../../state/url/url.query';
import { UserQuery } from '../../state/user/user.query';
import { myAccountRoutes } from './myaccount-view-routing.module';

@Component({
  selector: 'app-myaccount-view',
  templateUrl: './myaccount-view.component.html',
  styleUrls: ['./myaccount-view.component.scss'],
})
export class MyaccountViewComponent implements OnInit, OnDestroy {
  myAccountRoutes = myAccountRoutes;
  subs: Subscription[] = [];

  showKycToastNotification$ = combineLatest({
    exp15BlockingWonderlandNotification:
      this.abTestQuery.exp15BlockingWonderlandNotification$,
    url: this.urlQuery.urlWithoutQueryParams$,
  }).pipe(
    switchMap(({ url, exp15BlockingWonderlandNotification }) => {
      if (!exp15BlockingWonderlandNotification) {
        return of(false);
      }
      if (!url.includes(myAccountRoutes.wonderland.fullPath)) {
        return this.todosQuery.hasPendingKycTodos$;
      } else {
        return of(false);
      }
    })
  );

  constructor(
    public router: Router,
    private clientTaxCasesService: ClientTaxCasesService,
    private authQuery: AuthQuery,
    public userQuery: UserQuery,
    public todosQuery: TodosQuery,
    public clientTaxCasesQuery: ClientTaxCasesQuery,
    public featureTogglesQuery: FeatureTogglesQuery,
    private urlQuery: UrlQuery,
    private abTestQuery: ABTestConfigQuery,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.authQuery.userId$.subscribe((userId) => {
        this.clientTaxCasesService.setClientId(userId);
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subs = [];
  }

  toastGoToWonderland() {
    this.mixpanel.sendEvent(
      new StandardEvent(UserProfileEventName.KycToastStartButtonClicked, {
        buildHash: buildMeta.commitHash,
      })
    );
    void this.router.navigateByUrl(myAccountRoutes.wonderland.fullPath);
  }
}
