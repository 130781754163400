import { ClientTodoType } from '@expresssteuer/client-todos-api-interfaces';
import { TodoStatus } from './todo-status';

export const todoIcons: Record<ClientTodoType, string> = {
  [ClientTodoType.ClientIdentifyingDocument]: 'identification',
  [ClientTodoType.PartnerIdentifyingDocument]: 'identification',
  [ClientTodoType.ClientTaxId]: 'hashtag',
  [ClientTodoType.PartnerTaxId]: 'hashtag',
  [ClientTodoType.ClientPayoutIBAN]: 'bank',
  [ClientTodoType.TaxYearVerificationAndSend]: 'info',
  [ClientTodoType.OneTouch]: 'edit-one',
  [ClientTodoType.VmaProof]: 'doc',
  [ClientTodoType.PartnerSignature]: 'edit-one',
};

export const todoStatusIcons: Record<TodoStatus, string | undefined> = {
  [TodoStatus.new]: 'arrow-right-1',
  [TodoStatus.processing]: 'loading',
  [TodoStatus.erroneous]: 'alert',
  [TodoStatus.successfully_processed]: 'loading',
  [TodoStatus.completed]: undefined,
  [TodoStatus.escalated]: 'magnifying-glass',
  [TodoStatus.draft]: 'arrow-right-1',
  [TodoStatus.not_needed]: undefined,
};
