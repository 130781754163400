import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  EdButtonModule,
  EdIconButtonModule,
} from '@expresssteuer/design-system';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '../../modules/components/tooltip/tooltip.module';
import { FormStepComponent } from './form-step.component';

@NgModule({
  declarations: [FormStepComponent],
  imports: [
    CommonModule,
    EdButtonModule,
    TranslateModule,
    TooltipModule,
    EdIconButtonModule,
  ],
  exports: [FormStepComponent],
})
export class FormStepModule {}
