import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface IntercomState {
  isInitialized: boolean;
  isInitializing: boolean;
  userHash?: string | null;
}

const initialState: () => IntercomState = () => ({
  isInitialized: false,
  isInitializing: false,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'intercom', resettable: true })
export class IntercomStore extends Store<IntercomState> {
  constructor() {
    super(initialState());
  }
}
