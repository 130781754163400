<div
  class="form-step"
  [ngClass]="{ 'form-step--jigglypuff-marker': jigglypuffMarker }"
>
  <div *ngIf="headline" class="headline__container">
    <div class="headline__text-tooltip-group">
      <h2 [innerHTML]="headline" class="headline__text"></h2>
      <app-tooltip
        class="headline__tooltip"
        *ngIf="tooltip"
        [content]="tooltip"
      ></app-tooltip>
    </div>
    <button
      *ngIf="iconButtonIcon"
      ed-icon-button
      class="headline__iconbutton"
      [icon]="iconButtonIcon"
      type="button"
      (click)="iconButtonClicked.next()"
    ></button>
  </div>
  <ng-content></ng-content>
  <div class="footer" *ngIf="!hidePrevious || !hideNext || !hideSecondary">
    <button
      *ngIf="!hidePrevious"
      ed-button
      class="back-button"
      [class]="{ 'mobile-buttons-wide': mobileButtonsWide }"
      edType="outlined"
      [text]="'general.button.back' | translate"
      type="button"
      (click)="previousClickHandler()"
    ></button>
    <span class="flex-spacer"></span>
    <button
      *ngIf="!hideNext"
      ed-button
      class="next-button"
      [class]="{
        'next-button--wide': nextWide,
        'mobile-buttons-wide': mobileButtonsWide
      }"
      [text]="primaryText ? primaryText : ('general.button.next' | translate)"
      [disabled]="nextDisabled"
      (click)="nextClickHandler()"
      [type]="submitOnNext ? 'submit' : 'button'"
      [loading]="nextIsLoading"
    ></button>
    <span *ngIf="!hideNext" class="flex-break"></span>
    <button
      *ngIf="!hideSecondary"
      ed-button
      [edType]="hideNext ? 'outlined' : 'secondary'"
      [class]="{
        'mobile-buttons-wide': mobileButtonsWide,
        'secondary-button': !hideNext
      }"
      [text]="secondaryText"
      [disabled]="secondaryDisabled"
      (click)="secondaryClickHandler()"
      [type]="submitOnSecondary ? 'submit' : 'button'"
      [loading]="secondaryIsLoading"
    ></button>
  </div>
</div>
