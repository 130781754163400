import { Query } from '@datorama/akita';
import { DeepPartial } from '@expresssteuer/deep-object-helper';
import { assignDotObjectToObject } from '@expresssteuer/firebase-helper';
import { StoreStateIndicatorModel } from '@expresssteuer/ui-components';
import { get as getByPath } from 'dot-prop';
import { cloneDeep, isEqual } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import {
  FirebaseDocumentState,
  FirebaseDocumentStore,
} from './firebase-document.store';

export class FirebaseDocumentQuery<
  TData,
  TMeta = undefined,
  TError = any,
  TLoading = any
> extends Query<FirebaseDocumentState<TData, TMeta, TError, TLoading>> {
  constructor(
    protected store: FirebaseDocumentStore<TData, TMeta, TError, TLoading>
  ) {
    super(store);
  }

  originalDocument$ = this.select('data');
  localChangedData$ = this.select('localChangedData');
  localChangedDataAsDeepPartial$ = this.localChangedData$.pipe(
    map((localChangedData) => {
      const res = {} as DeepPartial<TData>;
      assignDotObjectToObject(localChangedData ?? {}, res);
      return res;
    }),
    shareReplay(1)
  );
  pendingSaveData$ = this.select('pendingSaveData');
  pendingSaveDataMerged$ = this.pendingSaveData$.pipe(
    map((pendingSaveData) => {
      if (!pendingSaveData) {
        return pendingSaveData;
      }

      return mergePendingSaveData(pendingSaveData);
    })
  );
  dataWithUnsavedChanges$ = combineLatest({
    data: this.originalDocument$,
    localChangedData: this.localChangedData$,
    pendingSaveDataMerged: this.pendingSaveDataMerged$,
  }).pipe(
    map(({ data, localChangedData, pendingSaveDataMerged }) => {
      const baseData: TData | undefined = data
        ? data
        : this.store.getTemplate();

      return mergeDataWithChanges(baseData, {
        ...pendingSaveDataMerged,
        ...localChangedData,
      });
    }),
    shareReplay(1)
  );
  refPath$ = this.select('refPath');
  hasPendingSaveProcesses$ = this.pendingSaveData$.pipe(
    map((rec) => !!rec && Object.keys(rec).length > 0)
  );
  /**
   * alias for `hasPendingSaveProcesses$`
   */
  isSaving$ = this.hasPendingSaveProcesses$;
  hasChanges$ = this.localChangedData$.pipe(
    map((e) => !!e),
    shareReplay(1)
  );
  errors$ = this.selectError<TError>();
  hasError$ = this.errors$.pipe(
    map((e) => FirebaseDocumentQuery.calculateHasError(e)),
    shareReplay(1)
  );
  hasOnlineChangedAfterLocalChanged$ = this.select(
    'hasOnlineChangedAfterLocalChanged'
  );
  isLoading$ = this.selectLoading();

  storeStateIndicator$: Observable<StoreStateIndicatorModel> = combineLatest([
    this.hasError$,
    this.hasChanges$,
    this.hasOnlineChangedAfterLocalChanged$,
    this.isSaving$,
    this.isLoading$,
  ]).pipe(
    map(
      ([
        hasErrors,
        hasChanges,
        hasOnlineChangedAfterLocalChanged,
        isSaving,
        isLoading,
      ]) => ({
        hasErrors,
        hasChanges,
        hasOnlineChangedAfterLocalChanged,
        isSaving,
        isLoading,
      })
    ),
    shareReplay(1)
  );

  private static calculateHasError(error?: any | null) {
    return (
      !!error && Object.keys(error).some((errorKey) => (error as any)[errorKey])
    );
  }

  hasError() {
    return FirebaseDocumentQuery.calculateHasError(this.store.getValue().error);
  }

  public selectFromDataWithUnsavedChanges$<T>(
    selector: (data?: TData | null) => T
  ) {
    return this.dataWithUnsavedChanges$.pipe(
      map(selector),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }
}

export function mergeDataWithChanges<TData>(
  data: TData,
  changes: Record<string, unknown> | undefined
) {
  if (!data) {
    return null;
  }
  if (!changes) {
    return data;
  }
  const merged = cloneDeep(data);
  assignDotObjectToObject(changes, merged as any);
  return merged as TData;
}

export function removeFromDotObjectIfAlreadyInObject(
  dotObject: Record<string, any> | undefined,
  obj: Record<string, any>
) {
  if (!dotObject) {
    return dotObject;
  }
  const realChanges = Object.fromEntries(
    Object.entries(dotObject).filter(([dotPath, value]) => {
      const valueAtPathInStore = getByPath<Record<string, any>>(obj, dotPath);
      return !isEqual(valueAtPathInStore, value);
    })
  );
  return realChanges;
}

/**
 * Combine the input's 2nd level objects into a single
 * object. In case of duplicate entries, the entry with the
 * larger first level key wins.
 * 
 * @example
```
const res = mergePendingSaveData({
  100: {
    a: 'x',
    b: 'y',
  },
  200: {
    b: 'q',
    c: 'z',
  },
});

expect(res).toEqual({
  a: 'x',
  b: 'q',
  c: 'z',
});
```
 */
export function mergePendingSaveData<T extends Record<string, unknown>>(
  pendingSaveData: Record<number | string, T>
): T {
  return Object.keys(pendingSaveData)
    .sort()
    .reduce((acc, curr) => {
      return {
        ...acc,
        ...pendingSaveData?.[curr as any],
      };
    }, {}) as T;
}
