<textarea
  class="textarea"
  [ngClass]="{
    'textarea--error': isInvalid && (isTouched || showErrorsDespitePristine)
  }"
  [name]="name"
  [rows]="rows"
  [cols]="cols"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  (blur)="onBlur()"
  [disabled]="disabled"
></textarea>
<div
  *ngIf="isInvalid && errorText && (isTouched || showErrorsDespitePristine)"
  class="error-text"
>
  {{ errorText }}
</div>
