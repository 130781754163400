<div
  class="overlay"
  [ngClass]="{
    'overlay--no-bottom-padding': noBottomPadding
  }"
  role="dialog"
  aria-modal="true"
>
  <div class="overlay__backdrop" (click)="closeClicked.next()"></div>
  <div class="overlay__page-wrapper">
    <div class="overlay__position">
      <div class="overlay__container">
        <div class="overlay__header">
          <div class="header__bar">
            <button
              type="button"
              class="header__close-button"
              (click)="closeClicked.next()"
            >
              <span class="show-sr-only">Close panel</span>
              <mat-icon
                svgIcon="x-outline"
                class="header__close-icon"
              ></mat-icon>
            </button>
          </div>
          <h1 class="header__headline">{{ headline }}</h1>
        </div>
        <div class="overlay__scroll">
          <div class="overlay__content">
            <div *ngIf="loading; else showContent" class="loading__container">
              <ed-loading></ed-loading>
            </div>
            <ng-template #showContent>
              <div
                *ngIf="content; else renderChildren"
                [class]="{
                  'overlay__content-text': true,
                  'prose-typography': proseFormat
                }"
                [innerHTML]="content"
              ></div>
              <ng-template #renderChildren>
                <ng-content></ng-content>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
