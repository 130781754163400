<div class="items">
  <!--      <a-->
  <!--        href="/"-->
  <!--        class="item &#45;&#45;active"-->
  <!--        >Start</a-->
  <!--      >-->
  <a
    *ngFor="let item of menuItems"
    [href]="item.href"
    (click)="itemClicked.next()"
    target="_blank"
    rel="noopener"
    class="item"
  >
    {{ item.label }}
  </a>
</div>
<div class="user-actions">
  <div class="user-actions__header">
    <app-action-login
      *ngIf="!isLoggedIn"
      class="user-actions__login"
    ></app-action-login>
    <div class="user-info" *ngIf="isLoggedIn">
      <mat-icon
        svgIcon="user-circle-outline"
        class="user-info__icon"
      ></mat-icon>
      <div class="user-info__data">
        <div class="user-info__name">{{ userName }}</div>
        <div class="user-info__email">{{ userEmail }}</div>
      </div>
    </div>
    <app-action-translation class="user-info__action"></app-action-translation>
    <app-action-hotline
      *ngIf="featureTogglesQuery.displayCallNavButton$ | async"
      class="user-info__action"
    ></app-action-hotline>
  </div>
  <div *ngIf="isLoggedIn" class="user-action-items">
    <ng-container *ngFor="let action of userActions">
      <a
        *ngIf="action"
        [routerLink]="action.routerLink"
        (click)="itemClicked.next()"
        class="user-action-item"
        role="menuitem"
      >
        <span>
          {{ action.label }}
          <span
            *ngIf="action.badge && action.badge > 0"
            class="user-action-item__badge"
            >{{ action.badge }}</span
          >
        </span>
      </a>
    </ng-container>
    <button
      type="button"
      appLogoutButton
      (click)="itemClicked.next()"
      class="user-action-item"
      role="menuitem"
    >
      <mat-icon
        svgIcon="logout-outline"
        class="user-action-item__icon"
      ></mat-icon>
      <span> {{ 'navigation.logout' | translate }} </span>
    </button>
  </div>
</div>
