import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { EdButtonModule } from '../ed-button/ed-button.module';
import { EdProgressbarModule } from '../ed-progressbar/ed-progressbar.module';
import { EsuiFileUploaderComponent } from './esui-file-uploader.component';

@NgModule({
  declarations: [EsuiFileUploaderComponent],
  imports: [
    CommonModule,
    NgxDropzoneModule,
    MatIconModule,
    EdProgressbarModule,
    EdButtonModule,
  ],
  exports: [EsuiFileUploaderComponent],
})
export class EsuiFileUploaderModule {}
