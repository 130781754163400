import { Component, Inject } from '@angular/core';
import {
  BindingsFrom,
  EASY_AKITA_UI_BINDINGS,
} from '@expresssteuer/state-angular';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { firstValueFrom } from 'rxjs';
import { ABTestConfigQuery } from '../../../state/a-b-test/a-b-test-config.query';
import { SUPPORTED_LANGUAGES } from '../../../state/language/language.model';
import { LanguageQuery } from '../../../state/language/language.query';
import { LanguageService } from '../../../state/language/language.service';
import { SignupService } from '../../../state/signup/signup.service';
import { navigationEAU } from '../navigation.ui-state';

@Component({
  selector: 'app-action-translation',
  templateUrl: './action-translation.component.html',
  styleUrls: ['../navigation-user-actions.scss'],
  providers: [EsuiLoggerService],
})
export class ActionTranslationComponent {
  SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES;

  constructor(
    @Inject(EASY_AKITA_UI_BINDINGS)
    public bindings: BindingsFrom<typeof navigationEAU>,
    private languageService: LanguageService,
    public languageQuery: LanguageQuery,
    private logger: EsuiLoggerService,
    private abTestConfigQuery: ABTestConfigQuery,
    private signupService: SignupService
  ) {
    this.logger.registerOwner(this);
  }

  async chooseLanguage(languageKey: string) {
    this.languageService.useLanguage(languageKey)?.catch((error) => {
      this.logger.error('failed to useLanguage', { languageKey, error });
    });
    this.bindings.showTranslationDropdown.setValue(false);
    const config = await firstValueFrom(
      this.abTestConfigQuery.abTestConfigOnceLoaded$
    );
    if (config.enableJigglypuff) {
      this.signupService.set({ languageKey: languageKey });
    }
  }
}
