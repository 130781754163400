import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  GetSignInLinkInput,
  GetSignInLinkOutput,
} from '@expresssteuer/authentication-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class GetSignInLink {
  constructor(public afFunctions: AngularFireFunctions) {}

  /**
   * Enables a logged in user to get another signedIn link to open the webapp. Currently used for the mobile app to redirect the user to the webapp.
   *
   * @api summary Get signedIn link
   * @api consumer mobileapp
   */
  call(input: GetSignInLinkInput) {
    console.log('Calling authentication-getSignInLink');
    return this.afFunctions.httpsCallable<
      GetSignInLinkInput,
      GetSignInLinkOutput
    >('authentication-getSignInLink')(input);
  }
}
