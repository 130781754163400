<ng-container
  *ngIf="{
    username: userQuery.firstname$ | async,
    menuOptions: [
      {
        value: myAccountRoutes.overview.fullPath,
        text: 'profile.overview' | translate,
        badge:
          clientTaxCasesQuery.taxCasesWaitingForClientApprovalAndClientEditsCount$
          | async
      },
      {
        value: myAccountRoutes.friendsinvite.fullPath,
        text: 'profile.friendsInvite' | translate
      },
      {
        value: myAccountRoutes.profile.fullPath,
        text: 'profile.profile' | translate
      },
      (featureTogglesQuery.enableWonderland$ | async)
        ? {
            value: myAccountRoutes.wonderland.fullPath,
            text: 'profile.todos' | translate,
            badge: todosQuery.openTodosCount$ | async
          }
        : {
            value: myAccountRoutes.documents.fullPath,
            text: 'profile.documents' | translate
          }
    ],
    showKycToastNotification: showKycToastNotification$ | async
  } as data"
>
  <ed-dropdown
    class="mobile-tabs"
    [ngModel]="router.url"
    (ngModelChange)="
      router.navigate([$event], { queryParamsHandling: 'merge' })
    "
    [options]="data.menuOptions"
  ></ed-dropdown>
  <div class="page">
    <h1 class="headline">
      {{ 'profile.greeting' | translate : { firstname: data.username ?? '' } }}
    </h1>
    <div class="navigation">
      <div class="navigation__wrapper">
        <nav class="navigation__layout">
          <ng-container *ngFor="let option of data.menuOptions">
            <a
              *ngIf="option"
              [routerLink]="option.value"
              routerLinkActive="--active"
              class="navigation__link"
            >
              {{ option.text }}
              <div
                *ngIf="option.badge && option.badge > 0"
                class="navigation__badge"
              >
                {{ option.badge }}
              </div>
            </a>
          </ng-container>
        </nav>
      </div>
    </div>
    <div class="content">
      <div class="content__route">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
  <div *ngIf="data.showKycToastNotification" class="toast-notification">
    <div class="toast-notification__backdrop"></div>
    <ed-alert
      class="toast-notification__alert"
      [title]="'profile.kycToastNotification.title' | translate"
      [text]="'profile.kycToastNotification.text' | translate"
      [primaryLabel]="
        'profile.kycToastNotification.actionButtonLabel' | translate
      "
      (primaryClicked)="toastGoToWonderland()"
      icon="info"
      [shadow]="true"
    ></ed-alert>
  </div>
</ng-container>
