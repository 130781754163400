import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CookiebotState, CookiebotStore } from './cookiebot.store';

@Injectable({ providedIn: 'root' })
export class CookiebotQuery extends Query<CookiebotState> {
  constructor(protected store: CookiebotStore) {
    super(store);
  }

  isPreferencesAllowed$ = this.select((s) => s.preferencesAllowed);
  isStatisticsAllowed$ = this.select((s) => s.statisticsAllowed);
  isMarketingAllowed = this.select((s) => s.marketingAllowed);
  hasResponse$ = this.select((s) => s.hasResponse);
}
