import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LegalOverlaysState, LegalOverlaysStore } from './legal-overlays.store';

@Injectable({
  providedIn: 'root',
})
export class LegalOverlaysQuery extends Query<LegalOverlaysState> {
  showImprintOverlay$ = this.select('showImprintOverlay');
  showConditionsOverlay$ = this.select('showConditionsOverlay');
  showDataProtectionOverlay$ = this.select('showDataProtectionOverlay');
  showDataProcessingOverlay$ = this.select('showDataProcessingOverlay');
  showSignableContract$ = this.select('showSignableContract');
  showReferralOverlay$ = this.select('showReferralOverlay');

  constructor(protected store: LegalOverlaysStore) {
    super(store);
  }
}
