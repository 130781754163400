import { EasyAkitaUi } from '@expresssteuer/state-angular';

export const navigationEAU = EasyAkitaUi.for({
  initState: {
    showTranslationDropdown: false,
    showUserDropdown: false,
    showMobileMenu: false,
  },
  storeName: 'navigation',
});
