import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  forwardRef,
  InjectFlags,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UnkownFunction } from '../helper/helper';

@Component({
  selector: 'ed-checkbox',
  templateUrl: './ed-checkbox.component.html',
  styleUrls: ['./ed-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EdCheckboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EdCheckboxComponent,
      multi: true,
    },
  ],
})
export class EdCheckboxComponent
  implements ControlValueAccessor, Validator, OnInit
{
  private static nextUniqueId = 0;

  @Input()
  label = '';

  @Input()
  indeterminate = false;

  @Input()
  name = `ed-checkbox-${EdCheckboxComponent.nextUniqueId++}`;

  @Input()
  errorText?: string | null;

  @Input()
  get showErrorsDespitePristine() {
    return this._showErrorsDespitePristine;
  }
  set showErrorsDespitePristine(value: BooleanInput) {
    this._showErrorsDespitePristine = coerceBooleanProperty(value);
  }
  private _showErrorsDespitePristine = false;

  @Input()
  get required() {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  private _required = false;

  value?: boolean;

  private ngControl?: NgControl | null;

  get isInvalid() {
    return this.ngControl?.invalid;
  }
  get isTouched() {
    return this.ngControl?.touched;
  }
  get isDirty() {
    return this.ngControl?.dirty;
  }
  get isValid() {
    return this.ngControl?.valid;
  }
  get isUntouched() {
    return this.ngControl?.untouched;
  }
  get isPristine() {
    return this.ngControl?.pristine;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: string) => {};
  registerOnChange(fn: UnkownFunction) {
    this.onChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};
  registerOnTouched(fn: UnkownFunction) {
    this.onTouch = fn;
  }

  onBlur() {
    this.onTouch();
  }

  writeValue(value: boolean) {
    this.value = value;
  }

  validate(): ValidationErrors | null {
    if (!this.required || this.value === true) {
      return null;
    }
    return { required: true };
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl, null, InjectFlags.Optional);
  }

  constructor(private injector: Injector) {}
}
