import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  SupportedLanguageKey,
  supportedLanguageKeyGuard,
} from './language.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(public translateService: TranslateService) {}

  public useLanguage(language: SupportedLanguageKey | string) {
    if (!supportedLanguageKeyGuard(language)) {
      console.warn('language not supported', { language });
      return;
    }
    if (this.translateService.currentLang === language) {
      return;
    }
    return this.translateService.use(language).toPromise();
  }

  public async useBrowserLang() {
    const browserLang = this.translateService.getBrowserLang();
    if (!supportedLanguageKeyGuard(browserLang)) {
      console.warn('browser language not supported', { browserLang });
      return;
    }
    return this.useLanguage(browserLang);
  }
}
