import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ed-social-button], a[ed-social-button]',
  templateUrl: './ed-social-button.component.html',
  styleUrls: ['./ed-social-button.component.scss'],
})
export class EdSocialButtonComponent {
  @Input()
  @HostBinding()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @HostBinding('class')
  get classes() {
    return {
      'social-button': true,
      'provider--google': this.provider === 'google',
      'provider--facebook': this.provider === 'facebook',
      'provider--twitter': this.provider === 'twitter',
      'provider--password': this.provider === 'password',
      'social-button--primary': this.edType === 'primary',
      'social-button--outline': this.edType === 'outline',
    };
  }

  @Input()
  provider: 'google' | 'facebook' | 'twitter' | 'password' = 'password';

  @Input()
  edType: 'primary' | 'outline' = 'primary';

  @Input()
  text = '';
}
