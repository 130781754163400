import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ClientTaxCase } from '@expresssteuer/models';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { ClientTaxCasesQuery } from './client-tax-cases.query';
import { ClientTaxCasesStore } from './client-tax-cases.store';

@Injectable({ providedIn: 'root' })
export class ClientTaxCasesService {
  private logger: EsuiLoggerService;
  private _syncCollection$?: Observable<unknown>;

  constructor(
    private afs: AngularFirestore,
    private clientTaxCasesStore: ClientTaxCasesStore,
    private clientTaxCasesQuery: ClientTaxCasesQuery,
    logger: EsuiLoggerService
  ) {
    this.logger = logger.getNewInstance(this);
  }

  public setClientId(clientId?: string) {
    if (!clientId) {
      this.clientTaxCasesStore.reset();
      return;
    }
    this.clientTaxCasesStore.update({ clientId });
  }

  public syncCollection() {
    if (!this._syncCollection$) {
      this._syncCollection$ = this.clientTaxCasesQuery.clientId$.pipe(
        switchMap((clientId?: string) => {
          if (!clientId) {
            return of();
          }
          this.clientTaxCasesStore.setLoading(true);

          const collectionPath = `clients/${clientId}/customerTaxCases`;
          const query = this.afs.collection<ClientTaxCase>(collectionPath);

          return query.valueChanges({ idField: 'id' }).pipe(
            tap({
              next: (clientTaxCases) => {
                this.clientTaxCasesStore.set(clientTaxCases ?? []);
                this.clientTaxCasesStore.setError(null);
                this.clientTaxCasesStore.setLoading(false);
              },
              error: (error) => {
                const viewErrorKey = 'LoadFailed';
                this.logger.error(viewErrorKey, error);
                this.clientTaxCasesStore.reset();
                this.clientTaxCasesStore.setError({ [viewErrorKey]: true });
                this.clientTaxCasesStore.setLoading(false);
              },
            })
          );
        }),
        shareReplay(1)
      );
    }
    return this._syncCollection$;
  }
}
