import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface MixpanelState {
  isInitialized: boolean;
}

const initialState: () => MixpanelState = () => ({
  isInitialized: false,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'mixpanel', resettable: true })
export class MixpanelStore extends Store<MixpanelState> {
  constructor() {
    super(initialState());
  }
}
