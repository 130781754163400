import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CallFabState, CallFabStore } from './call-fab.store';

@Injectable({ providedIn: 'root' })
export class CallFabQuery extends Query<CallFabState> {
  constructor(protected store: CallFabStore) {
    super(store);
  }

  isOpen$ = this.select((s) => s.open);
}
