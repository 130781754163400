import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EdDateComponent } from './ed-date.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [EdDateComponent],
  imports: [CommonModule, FormsModule],
  exports: [EdDateComponent],
})
export class EdDateModule {}
