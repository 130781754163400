import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  SubmitManualValidationResultInput,
  SubmitManualValidationResultOutput,
} from '@expresssteuer/client-todos-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class SubmitManualValidationResult {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: SubmitManualValidationResultInput) {
    console.log('Calling clientTodos-submitManualValidationResult');
    return this.afFunctions.httpsCallable<
      SubmitManualValidationResultInput,
      SubmitManualValidationResultOutput
    >('clientTodos-submitManualValidationResult')(input);
  }
}
