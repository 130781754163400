import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, take } from 'rxjs/operators';
import { BrazeState, BrazeStore } from './braze.store';

@Injectable({ providedIn: 'root' })
export class BrazeQuery extends Query<BrazeState> {
  constructor(protected store: BrazeStore) {
    super(store);
  }

  isInitialized$ = this.select((s) => s.isInitialized);
  isInitializing$ = this.select((s) => s.isInitializing);
  untilIsInitialized$ = this.isInitialized$.pipe(
    filter((e) => e),
    take(1)
  );
}
