export enum TrackingProductType {
  App = 'app',
  Retention = 'retention',
  CustomerApproval = 'ca',
  EmailVerification = 'ev',
  PhoneVerification = 'pv',
  IbanVerification = 'iv',
  UserProfile = 'up',
  Client = 'client',
  Taxcase = 'taxcase',
  Auth = 'auth',
  Wonderland = 'wl',
  Optimize = 'op',
}
