import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[edNumbersOnly]',
})
export class EdNumbersOnlyDirective {
  #active = false;

  @Input('edNumbersOnly')
  set active(val: BooleanInput) {
    this.#active = coerceBooleanProperty(val);
  }

  @Output() valueChange = new EventEmitter();
  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event?: Event) {
    if (this.#active === false) {
      return;
    }
    // based on https://stackblitz.com/edit/angular-numbers-only-directive-xttsje
    const initalValue = this._el.nativeElement.value;
    const newValue = initalValue.replace(/[^0-9]*/g, '');
    this._el.nativeElement.value = newValue;
    let newValueAsInt: number | null = null;
    const newValueParsed = parseInt(newValue);
    if (!Number.isNaN(newValueParsed)) {
      newValueAsInt = newValueParsed;
    }
    this.valueChange.emit(newValueAsInt);
    if (initalValue !== this._el.nativeElement.value) {
      event?.stopPropagation();
    }
  }
}
