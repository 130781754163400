import {
  AfterViewInit,
  Component,
  ContentChildren,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { EdCollapsibleItemComponent } from '../ed-collapsible-item/ed-collapsible-item.component';

@Component({
  selector: 'ed-collapsible',
  templateUrl: './ed-collapsible.component.html',
  styleUrls: ['./ed-collapsible.component.scss'],
})
export class EdCollapsibleComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(EdCollapsibleItemComponent)
  childComponents!: QueryList<EdCollapsibleItemComponent>;

  subs: Subscription[] = [];

  ngAfterViewInit() {
    this.childComponents.forEach(
      (childComponent: EdCollapsibleItemComponent) => {
        this.subs.push(
          childComponent.opened.subscribe((id) => this.closeAllChildsExcept(id))
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }

  closeAllChildsExcept(id: string) {
    this.childComponents.forEach(
      (childComponent: EdCollapsibleItemComponent) => {
        if (childComponent.id !== id) {
          childComponent.setClose();
        }
      }
    );
  }
}
