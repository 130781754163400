import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { HotjarState, HotjarStore } from './hotjar.store';

@Injectable({ providedIn: 'root' })
export class HotjarQuery extends Query<HotjarState> {
  constructor(protected store: HotjarStore) {
    super(store);
  }

  hotjarIsLoaded$ = this.select((s) => s.hotjarLoaded);
}
