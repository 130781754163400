import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EdSelectComponent } from './ed-select.component';

@NgModule({
  declarations: [EdSelectComponent],
  imports: [CommonModule, FormsModule],
  exports: [EdSelectComponent],
})
export class EdSelectModule {}
