import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { JobsGetDataOutput } from '@expresssteuer/jobs-api-interfaces';

export type JobsData = Record<string, JobsGetDataOutput>;

export interface JobsDataState {
  jobItemIds: string[];
  jobsData: JobsData;
}

const initialState: () => JobsDataState = () => ({
  jobItemIds: [],
  jobsData: {},
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'jobs-data', resettable: true })
export class JobsDataStore extends Store<JobsDataState> {
  constructor() {
    super(initialState());
  }
}
