import { Injectable } from '@angular/core';
import { Client } from '@expresssteuer/models';
import {
  ErrorUpdatableStoreMixin,
  FirebaseDocumentState,
  FirebaseDocumentStore,
  MultiLoadableStoreMixin,
} from '@expresssteuer/state-angular';

export interface ClientError {
  failedToUpdateIban: boolean;
}

export interface ClientLoader {
  updatingIban: boolean;
}

export type ClientState = FirebaseDocumentState<
  Client,
  undefined,
  ClientError,
  ClientLoader
>;

class Base extends FirebaseDocumentStore<
  Client,
  undefined,
  ClientError,
  ClientLoader
> {}

class MixedStore extends MultiLoadableStoreMixin(
  ErrorUpdatableStoreMixin(Base)
) {}

@Injectable({ providedIn: 'root' })
export class ClientStore extends MixedStore {
  constructor() {
    super({ name: 'client', resettable: true });
  }
}
