import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  ClientTodoEscalationInput,
  ClientTodoEscalationOutput,
} from '@expresssteuer/client-todos-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ClientTodoEscalation {
  constructor(public afFunctions: AngularFireFunctions) {}

  /**
   * Escalates a todo if some precondions are met.
   * Errors could be already-exists (already escalated), not-found (no todo found), failed-precondition (todo not active), or more (unknown)
   *
   * @api summary Escalate Client Todo
   * @api consumer mobileapp,andorra
   */
  call(input: ClientTodoEscalationInput) {
    console.log('Calling clientTodos-clientTodoEscalation');
    return this.afFunctions.httpsCallable<
      ClientTodoEscalationInput,
      ClientTodoEscalationOutput
    >('clientTodos-clientTodoEscalation')(input);
  }
}
