import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface CookiebotState {
  hasResponse: boolean;
  preferencesAllowed: boolean | null;
  statisticsAllowed: boolean | null;
  marketingAllowed: boolean | null;
}

const initialState: () => CookiebotState = () => ({
  hasResponse: false,
  preferencesAllowed: null,
  statisticsAllowed: null,
  marketingAllowed: null,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cookiebot', resettable: true })
export class CookiebotStore extends Store<CookiebotState> {
  constructor() {
    super(initialState());
  }
}
