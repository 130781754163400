import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EdAlertComponent } from './ed-alert.component';

@NgModule({
  declarations: [EdAlertComponent],
  imports: [CommonModule, FormsModule, MatIconModule],
  exports: [EdAlertComponent],
})
export class EdAlertModule {}
