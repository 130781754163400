<div
  class="collapsible-item"
  [ngClass]="{
    'collapsible-item--open': open
  }"
>
  <button
    type="button"
    class="collapsible-item__button"
    (click)="handleButtonClick()"
  >
    <span class="collapsible-item__name">{{ name }}</span>
    <mat-icon
      class="collapsible-item__icon"
      [svgIcon]="open ? 'chevron-down' : 'chevron-up'"
    ></mat-icon>
  </button>
  <div class="collapsible-item__content">
    <ng-content></ng-content>
  </div>
</div>
