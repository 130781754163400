import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { selectReader } from '@expresssteuer/state-angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { combineLatest, firstValueFrom, merge, Observable, of } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import {
  AuthBirthdateErrors,
  AuthEmailErrors,
  AuthErrors,
  AuthPasswordErrors,
  AuthSocialErrors,
  AuthState,
  AuthStore,
} from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  constructor(protected store: AuthStore, public translate: TranslateService) {
    super(store);
  }

  isLoading$ = this.selectLoading();
  user$ = this.select((s) => s.firebaseUser).pipe(
    // allow null but not undefined
    filter((e) => e !== undefined)
  );
  //we need to filter out undefined users therefore use user$ and not select
  userId$ = this.user$.pipe(
    map((s) => s?.uid),
    distinctUntilChanged()
  );
  isLoggedIn$ = this.user$.pipe(
    map((user) => !!user && user.isAnonymous === false)
  );

  firebaseEmail$ = this.selectReader((s) => s.firebaseUser?.email);
  enteredEmail$ = this.selectReader((s) => s.enteredEmail);
  mergedEmail$ = combineLatest([this.firebaseEmail$, this.enteredEmail$]).pipe(
    map(([firebaseEmail, enteredEmail]) => {
      if (typeof enteredEmail === 'string') {
        return enteredEmail;
      }
      return firebaseEmail || '';
    })
  );

  enteredPassword$ = this.selectReader((s) => s.enteredPassword);
  enteredBirthdate$ = this.selectReader((s) => s.enteredBirthdate);
  selectedSocialProvider$ = this.selectReader((s) => s.selectedSocialProvider);

  shouldHandleSocialLinkRedirectResult$ = this.selectReader(
    (s) => s.socialRedirectResult?.shouldHandleSocialLink
  );
  shouldHandleSocialLoginRedirectResult$ = this.selectReader(
    (s) => s.socialRedirectResult?.shouldHandleSocialLogin
  );
  passwordUpdateSuccessful$ = this.selectReader(
    (s) => s.passwordUpdateSuccessful
  );
  socialLinkSuccessful$ = this.selectReader(
    (s) => s.socialRedirectResult?.socialLinkSuccessful
  );
  socialLoginSuccessful$ = this.selectReader(
    (s) => s.socialRedirectResult?.socialLoginSuccessful
  );
  isHandlingSocialRedirectResult$ = this.selectReader(
    (s) => s.socialRedirectResult?.isHandling
  );

  errors$ = this.selectError() as Observable<Partial<typeof AuthErrors>>;
  linkIsExpired$ = this.errors$.pipe(
    map((errors) => {
      return errors && errors.expired;
    })
  );
  emailErrors$ = this.errors$.pipe(
    map((errors) => {
      return this.reduceErrors(errors, AuthEmailErrors);
    })
  );
  birthdateErrors$ = this.errors$.pipe(
    map((errors) => {
      return this.reduceErrors(errors, AuthBirthdateErrors);
    })
  );
  emailAlreadyInUseError$ = this.errors$.pipe(
    map((errors) => {
      return errors && errors.emailAlreadyInUse;
    })
  );
  emailAlreadyInUseOnOfferSlide$ = this.selectReader(
    (s) => s.emailAlreadyInUseOnOfferSlide
  );
  hasEmailErrors$ = this.emailErrors$.pipe(
    map((errors) => {
      return errors.length > 0;
    })
  );
  firstEmailErrorTranslated$ = this.emailErrors$.pipe(
    switchMap((errors) => {
      return this.translateFirstErrorCodes('auth.errors.email', errors);
    })
  );
  firstBirthdateErrorTranslated$ = this.birthdateErrors$.pipe(
    switchMap((errors) => {
      return this.translateFirstErrorCodes('auth.errors.birthdate', errors);
    })
  );
  birthdateTrialsLeft$ = this.selectReader((s) => s.birthdateTrialsLeft);
  birthdateTrialsLeftErrorTranslated$ = this.birthdateTrialsLeft$.pipe(
    switchMap((trialsLeft) => {
      if (trialsLeft) {
        if (trialsLeft > 0) {
          return this.translate.get('auth.errors.birthdate.trialsLeft', {
            trialsLeft,
          }) as Observable<string>;
        } else {
          return this.translate.get(
            'auth.errors.birthdate.authBirthdateAccountLocked',
            {
              trialsLeft,
            }
          ) as Observable<string>;
        }
      }
      return this.firstBirthdateErrorTranslated$;
    })
  );
  birthdateErrorTranslated$ = this.birthdateErrors$.pipe(
    switchMap((birthdateErrors) => {
      if (birthdateErrors.includes('authBirthdateAuthFailedRetries')) {
        return this.birthdateTrialsLeftErrorTranslated$;
      } else {
        return this.firstBirthdateErrorTranslated$;
      }
    })
  );
  birthdateIsCorrectDate$ = this.enteredBirthdate$.pipe(
    map((birthdate) => {
      if (!birthdate) return true;
      if (!moment(birthdate, 'YYYY-MM-DD').isValid()) {
        return false;
      }
      const age = moment().diff(moment(birthdate, 'YYYY-MM-DD'), 'years');
      if (age === 0 || age > 150) {
        return false;
      }
      return true;
    })
  );

  passwordErrors$ = this.errors$.pipe(
    map((errors) => {
      return this.reduceErrors(errors, AuthPasswordErrors);
    })
  );
  hasPasswordErrors$ = this.passwordErrors$.pipe(
    map((errors) => {
      return errors.length > 0;
    })
  );
  firstPasswordErrorTranslated$ = this.passwordErrors$.pipe(
    switchMap((errors) => {
      return this.translateFirstErrorCodes('auth.errors.password', errors);
    })
  );
  passwordTooWeakError$ = this.enteredPassword$.pipe(
    map((password) => {
      if (!password) {
        return false;
      }
      const hasMinimumLength = password.length >= 8;
      const hasLowerCase = /[a-z]/.test(password);
      const hasUpperCase = /[A-Z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasSpecialCharacter = /[!@#$%^]/.test(password);

      return (
        !hasMinimumLength ||
        !hasLowerCase ||
        !hasUpperCase ||
        !hasNumber ||
        !hasSpecialCharacter
      );
    })
  );
  passwordTooWeakErrorTranslated$ = this.passwordTooWeakError$.pipe(
    switchMap((passwordTooWeak) => {
      if (passwordTooWeak) {
        return this.translate.get(
          'auth.errors.password.weakPassword'
        ) as Observable<string>;
      } else {
        return of(undefined);
      }
    })
  );

  socialProviderErrors$ = this.errors$.pipe(
    map((errors) => {
      return this.reduceErrors(errors, AuthSocialErrors);
    })
  );
  firstSocialProviderErrorTranslated$ = this.socialProviderErrors$.pipe(
    switchMap((errors) => {
      return this.translateFirstErrorCodes('auth.errors.social', errors);
    })
  );
  socialProviderAlreadyExistsError$ = this.errors$.pipe(
    map(
      (errors) =>
        !!errors?.socialProviderAlreadyInUse || !!errors?.emailAlreadyInUse
    )
  );

  signInLinkLastSent$ = this.select((e) => e.signInLinkLastSentTimestamp).pipe(
    map((timestamp) => (timestamp ? new Date(timestamp) : undefined))
  );

  signInLinkSentWithinLast20Seconds$ = this.signInLinkLastSent$.pipe(
    switchMap((timestamp) => {
      if (!timestamp) {
        return Promise.resolve(false);
      }
      const thresholdInMs = 20000;
      const diffInMs = +new Date() - +timestamp;

      if (diffInMs > thresholdInMs) {
        return Promise.resolve(false);
      }

      return merge(
        Promise.resolve(true),
        of(false).pipe(delay(thresholdInMs - diffInMs))
      );
    }),
    shareReplay(1)
  );

  signInLinkSent$ = this.select((e) => e.signInLinkLastSentTimestamp).pipe(
    map((timestamp) => !!timestamp)
  );

  signInLinkWasAutoRequested$ = this.selectReader(
    (e) => e.signInLinkWasAutoRequested
  );

  noUserToLinkWithCredentialError$ = this.errors$.pipe(
    map((errors) => !!errors?.noUserToLinkWithCredential)
  );

  noSignedInUserToLinkWithCredentialError$ = this.errors$.pipe(
    map((errors) => !!errors?.noSignedInUserToLinkWithCredential)
  );

  getEnteredEmail() {
    return this.store.getValue().enteredEmail;
  }

  getUserId() {
    return this.store.getValue().firebaseUser?.uid;
  }

  untilUserId(): Promise<string> {
    return firstValueFrom(
      this.userId$.pipe(
        filter((userId: string | undefined): userId is string =>
          Boolean(userId)
        )
      )
    );
  }

  private reduceErrors(
    errors: Record<string, boolean>,
    errorKind: Record<string, boolean>
  ): string[] {
    if (!errors) {
      return [];
    }
    return Object.entries(errors).reduce((acc, [key, value]) => {
      if (value && key in errorKind) acc.push(key);
      return acc;
    }, [] as string[]);
  }

  translateFirstErrorCodes(
    prefix: string,
    errors: string[]
  ): Observable<string> {
    return errors[0] ? this.translate.get(`${prefix}.${errors[0]}`) : of('');
  }

  private selectReader<T>(selector: (state: AuthState) => T) {
    return selectReader(this.store, this, selector);
  }
}
