import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input()
  headline = '';
  @Input()
  content?: string | null;
  @Input()
  anchor?: 'right' | 'center' = 'center';
  @Input()
  mobileFullWidth = true;

  @Output()
  closeClick = new EventEmitter<void>();
}
