import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoginButtonDirective } from './login-button.directive';
import { LogoutButtonDirective } from './logout-button.directive';

@NgModule({
  declarations: [LoginButtonDirective, LogoutButtonDirective],
  exports: [LoginButtonDirective, LogoutButtonDirective],
  imports: [CommonModule],
})
export class UserButtonModule {}
