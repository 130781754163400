import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { SETTINGS } from '@angular/fire/compat/firestore';
import {
  REGION,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/compat/functions';
import {
  connectFirestoreEmulator,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import {
  LOGGER_CONFIG,
  LOGGER_LEVEL,
  SENTRY,
  providersForDedicatedLogger,
} from '@expresssteuer/ui-components';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ErrorHandler } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { ESUI_IBAN_VALIDATOR } from '@expresssteuer/angular-validators';
import {
  EdButtonModule,
  EdLoadingModule,
  EdSelectModule,
} from '@expresssteuer/design-system';
import { GOOGLE_AUTOCOMPLETE_API_KEY } from '@expresssteuer/google-maps-api-loader';
import { ValidateIban } from '@expresssteuer/iban-api-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { FormStepModule } from './layouts/form-step/form-step.module';
import { CallFabModule } from './modules/components/call-fab/call-fab.module';
import { IconModule } from './modules/icon/icon.module';
import { LegalOverlaysModule } from './modules/legal-overlays/legal-overlays.module';
import { MetadataModule } from './modules/metadata/metadata.module';
import { NavigationModule } from './modules/navigation/navigation.module';

export class Controler {
  public static HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/');
  }
}
registerLocaleData(localeDe);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    GoogleTagManagerModule.forRoot({
      id: (() => {
        if (environment.toggles.useGtm && !environment.googleTagManager.id) {
          throw new Error(
            '`environment.googleTagManager.id` is required when using `environment.toggles.useGtm`'
          );
        }
        return environment.googleTagManager.id;
      })(),
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.emulator) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      return firestore;
    }),
    provideAuth(() => getAuth()),

    environment.production ? [] : AkitaNgDevtools.forRoot({ logTrace: false }),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: Controler.HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IconModule,
    EdButtonModule,
    EdLoadingModule,
    FormStepModule,
    EdSelectModule,
    LegalOverlaysModule,
    MetadataModule,
    CallFabModule,
    // global overlay
    NavigationModule,
  ],
  providers: [
    ...providersForDedicatedLogger({ scopeLabel: AppModule.name }),
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.emulator ? ['http://localhost:9099'] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.emulator ? ['localhost', 8080] : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.emulator ? ['localhost', 9000] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.emulator ? ['localhost', 5001] : undefined,
    },
    {
      provide: SETTINGS,
      useFactory: () =>
        environment.production ? {} : { minimumFetchIntervalMillis: 1000 },
    },
    { provide: REGION, useValue: 'europe-west3' },

    { provide: LOCALE_ID, useValue: 'de' },

    { provide: ESUI_IBAN_VALIDATOR, useExisting: ValidateIban },
    {
      provide: GOOGLE_AUTOCOMPLETE_API_KEY,
      useValue: environment.googleMaps.apiKey,
    },
    {
      provide: LOGGER_LEVEL,
      useValue: environment.level,
    },
    {
      provide: LOGGER_CONFIG,
      useValue: {
        showScopeLabels: true,
        showOwnerNames: true,
        showTimeStamps: false,
        sentryLoggingLevel: environment.sentry.level,
      },
    },
    environment.toggles.useSentry
      ? [
          {
            provide: SENTRY,
            useValue: Sentry,
          },
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
        ]
      : [],
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // do nothing
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
