import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, firstValueFrom } from 'rxjs';
import { AuthQuery } from '../auth/auth.query';
import { IntercomState, IntercomStore } from './intercom.store';

@Injectable({ providedIn: 'root' })
export class IntercomQuery extends Query<IntercomState> {
  constructor(protected store: IntercomStore, private authQuery: AuthQuery) {
    super(store);
  }

  isInitialized$ = this.select((s) => s.isInitialized);
  isInitializing$ = this.select((s) => s.isInitializing);
  userHash$ = this.select((s) => s.userHash);

  waitUntilIsInitialized() {
    return firstValueFrom(this.isInitialized$.pipe(filter((e: boolean) => e)));
  }
}
