import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MetadataComponent } from './metadata.component';

@NgModule({
  declarations: [MetadataComponent],
  imports: [CommonModule],
  exports: [MetadataComponent],
})
export class MetadataModule {}
