import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface LegalOverlaysState {
  showImprintOverlay: boolean;
  showConditionsOverlay: boolean;
  showDataProtectionOverlay: boolean;
  showSignableContract: boolean;
  showDataProcessingOverlay: boolean;
  showReferralOverlay: boolean;
}

export function createInitialState(): LegalOverlaysState {
  return {
    showImprintOverlay: false,
    showConditionsOverlay: false,
    showDataProtectionOverlay: false,
    showSignableContract: false,
    showDataProcessingOverlay: false,
    showReferralOverlay: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'legalOverlays' })
export class LegalOverlaysStore extends Store<LegalOverlaysState> {
  constructor() {
    super(createInitialState());
  }
}
