import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-contract-style-wrapper',
  templateUrl: './contract-style-wrapper.component.html',
  styleUrls: ['./contract-style-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ContractStyleWrapperComponent {
  @Input()
  content?: string | null;
}
