import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import { appRoutes } from '../app-routing.module';
import { FunnelQueryParam } from './funnel-view/funnel-query-param';

@Injectable()
export class HandleCampaignLinksGuard implements CanActivate {
  private logger: EsuiLoggerService;

  constructor(private router: Router, logger: EsuiLoggerService) {
    this.logger = logger.getNewInstance(this);
  }

  async canActivate(next: ActivatedRouteSnapshot) {
    const campaignId = next.params[appRoutes.oldCampaign.params.campaignId];
    this.logger.debug(
      'Converting old campaign link to new format using campaign id',
      campaignId
    );
    return this.router.createUrlTree([appRoutes.funnel.path], {
      queryParams: {
        ...next.queryParams,
        [FunnelQueryParam.campaignId]: campaignId,
      },
    });
  }
}
