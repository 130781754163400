<div class="progressbar">
  <div class="progress" [style.width.%]="value ?? 0"></div>
</div>
<div class="remaining-time" *ngIf="!hideRemainingTime">
  {{
    'progressbar.remainingMin'
      | translate
        : {
            remainingMin: time && time < 1 ? 1 : time ?? '-'
          }
  }}
</div>
