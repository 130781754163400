import { Injectable } from '@angular/core';
import { Submit } from '@expresssteuer/signup-api-angular';
import {
  Generator,
  SignUpV1,
  SignUpV1Metadata,
} from '@expresssteuer/signup-api-interfaces';
import { firstValueFrom } from 'rxjs';
import { SignupStore } from './signup.store';

@Injectable({ providedIn: 'root' })
export class SignupService {
  constructor(private signupStore: SignupStore, private signupSubmit: Submit) {}

  set(data: Partial<SignUpV1>) {
    this.signupStore.update(data);
  }

  saveMetadata(metadata: Partial<SignUpV1Metadata>) {
    this.signupStore.update((state) => {
      const newMetadataArray = Object.entries(metadata).filter(
        ([_, value]) => value !== undefined
      );
      if (newMetadataArray.length === 0) {
        return;
      }
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...(Object.fromEntries(newMetadataArray) as SignUpV1Metadata),
        },
      };
    });
  }

  /*
  We differentiate between defaults and user input. At the point in time when a user
  sees a default value and clicks next, we set this default as user input. To make
  this explicit, this function should be called.
   */
  setUntouchedDefaults(data: Partial<SignUpV1>) {
    this.signupStore.update(data);
  }

  prefillClientCurrentAddressWithHomeAddress() {
    const homeAddress = this.signupStore.getValue().homeAddress;
    const alreadyEnteredCurrentAddress =
      this.signupStore.getValue().clientCurrentAddress;
    if (!alreadyEnteredCurrentAddress) {
      this.signupStore.update({ clientCurrentAddress: homeAddress });
    }
  }

  async submit() {
    this.set({ version: '1' });
    this.saveMetadata({ generator: Generator.andorraSignup });
    const data = this.signupStore.getValue();
    await firstValueFrom(this.signupSubmit.call(data as SignUpV1));
  }

  reset() {
    this.signupStore.reset();
  }
}
