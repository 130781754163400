import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ABTests } from '@expresssteuer/models';
import JSONUrl from '@jsonurl/jsonurl';
import {
  distinctUntilKeyChanged,
  map,
  shareReplay,
  skip,
  startWith,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EnforcedAbTestParamsQuery {
  static IS_TEST_PARAM_NAME = 'abTests';

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private documentRef: Document
  ) {}

  public abTests$ = this.activatedRoute.queryParams.pipe(
    skip(1), // skip first event from activatedRoute as it is always empty
    startWith(this.getAbTestsQueryParamsSnapshotFromUrl()), // instead use a custom snapshot initially
    distinctUntilKeyChanged(EnforcedAbTestParamsQuery.IS_TEST_PARAM_NAME), // only continue if something changes
    map((params) => EnforcedAbTestParamsQuery.abTests(params)), // unpack ab-tests query param
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private static abTests(
    params: Record<string, unknown>
  ): Partial<ABTests> | undefined {
    if (!(EnforcedAbTestParamsQuery.IS_TEST_PARAM_NAME in params)) {
      return undefined;
    }

    const paramValue = params[
      EnforcedAbTestParamsQuery.IS_TEST_PARAM_NAME
    ] as string;

    return JSONUrl.parse(paramValue) as Partial<ABTests>;
  }

  public getAbTests() {
    return EnforcedAbTestParamsQuery.abTests(
      this.activatedRoute?.snapshot?.queryParams
    );
  }

  private getAbTestsQueryParamsSnapshotFromUrl() {
    return {
      [EnforcedAbTestParamsQuery.IS_TEST_PARAM_NAME]: new URLSearchParams(
        this.documentRef.defaultView?.location.search
      )?.get(EnforcedAbTestParamsQuery.IS_TEST_PARAM_NAME),
    };
  }
}
