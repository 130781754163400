import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UpdateStateCallback } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { AsyncQueue } from '@expresssteuer/helper-async-queue';
import JsonURL from '@jsonurl/jsonurl';
import {
  Observable,
  defer,
  distinctUntilChanged,
  map,
  shareReplay,
} from 'rxjs';

@Injectable()
export abstract class UrlStore<TState = unknown> {
  #store$: Observable<TState>;

  protected abstract readonly initialState: TState;
  protected abstract readonly options: { name: string };

  #queue = new AsyncQueue();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    routerQuery: RouterQuery
  ) {
    this.#store$ = defer(() =>
      routerQuery.selectQueryParams([this.storeName]).pipe(
        distinctUntilChanged(),
        map<(string | undefined)[], TState>(([state]) => {
          return {
            ...this.initialState,
            ...(state ? (JsonURL.parse(state) as Partial<TState>) : undefined),
          };
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
      )
    );
  }

  getValue(): TState {
    const val = this.activatedRoute.snapshot.queryParams[this.storeName];
    return {
      ...this.initialState,
      ...(val ? (JsonURL.parse(val) as Partial<TState>) : undefined),
    };
  }
  get config() {
    return this.options;
  }
  get storeName(): string {
    return this.options.name;
  }
  get store$(): Observable<TState> {
    return this.#store$;
  }
  reset(): Promise<boolean> {
    return this.update(() => this.initialState);
  }
  update(
    stateCallback: UpdateStateCallback<TState, Partial<TState>>
  ): Promise<boolean>;
  update(state: Partial<TState>): Promise<boolean>;
  update(stateOrCallback: unknown): Promise<boolean> {
    return this.#queue.enqueue(async () => {
      const curr = this.getValue();

      let newState: TState;
      if (typeof stateOrCallback === 'function') {
        newState = stateOrCallback(curr);
      } else if (typeof stateOrCallback === 'object') {
        newState = {
          ...curr,
          ...stateOrCallback,
        };
      } else {
        const message = 'strange input detected in update';
        throw new Error(message);
      }

      const newStateStringified = JsonURL.stringify(newState);

      const queryParams: Params = { [this.storeName]: newStateStringified };
      return this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    });
  }

  destroy() {
    const queryParams: Params = { [this.storeName]: undefined };
    return this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
