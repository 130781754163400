import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
import { ReplaySubject, Subscription, combineLatest, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  take,
  tap,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ABTestConfigQuery } from '../a-b-test/a-b-test-config.query';
import { AuthQuery } from '../auth/auth.query';
import { CookiebotQuery } from '../cookiebot/cookiebot.query';
import { MarketingParamsQuery } from '../router/marketing-params.query';
import { UtmParams } from '../router/marketing-params.service';
import { HotjarService } from './hotjar.service';
import { MixpanelEvent } from './mixpanel.event';
import { MixpanelQuery } from './mixpanel.query';
import { MixpanelStore } from './mixpanel.store';

const CACHE_BUFFER_SIZE = 10;

declare global {
  interface Window {
    mixpanel: OverridedMixpanel;
  }
}

@Injectable({ providedIn: 'root' })
export class MixpanelService {
  private logger: EsuiLoggerService;
  private trackingCache = new ReplaySubject<MixpanelEvent>(CACHE_BUFFER_SIZE);
  private trackingCacheSubscription?: Subscription;
  private abTestProperties: Record<string, unknown> = {};
  private mixpanel?: OverridedMixpanel; // defined if initialized and allowed

  #waitForCookiebotResponse$ = this.cookiebotQuery.hasResponse$.pipe(
    filter((hasResponse) => hasResponse),
    take(1)
  );

  #waitForAbTestConfigLoaded$ =
    this.abTestConfigQuery.abTestConfigOnceLoaded$.pipe(
      take(1),
      catchError((error) => {
        this.logger.error("Couldn't load AB Test Config", error);
        return of({});
      }),
      map((e) => {
        Object.entries(e).forEach(([k, v]) => {
          this.abTestProperties[`${k}Experiment`] = v;
        });
      })
    );

  #activateOrDeactivateWhenReady$ = combineLatest([
    this.cookiebotQuery.isStatisticsAllowed$,
    this.#waitForCookiebotResponse$,
    this.#waitForAbTestConfigLoaded$,
  ]).pipe(
    tap(([isAllowed, _, __]) => {
      if (isAllowed) {
        this.activate();
      } else {
        this.deactivate();
      }
    })
  );

  #syncUser$ = combineLatest([
    this.authQuery.user$,
    this.query.untilIsInitialized$,
  ]).pipe(
    tap(([user, _]) => {
      if (user && user.isAnonymous === false) {
        this.setIdentifier(user.uid);
      }
    }),
    shareReplay(1)
  );

  #syncMarketingParams$ = combineLatest([
    this.marketingParamsQuery.utmParams$,
    this.query.untilIsInitialized$,
  ]).pipe(
    tap(([utmParams, _]) => {
      this.registerLastTouchUtmParams(utmParams);
    }),
    shareReplay(1)
  );

  constructor(
    logger: EsuiLoggerService,
    private store: MixpanelStore,
    private query: MixpanelQuery,
    private cookiebotQuery: CookiebotQuery,
    private authQuery: AuthQuery,
    private abTestConfigQuery: ABTestConfigQuery,
    private marketingParamsQuery: MarketingParamsQuery,
    @Inject(DOCUMENT) private document: Document,
    private hotjarService: HotjarService
  ) {
    this.logger = logger.getNewInstance(this);
    if (this.document.defaultView) {
      this.document.defaultView.mixpanel = mixpanel;
    }
  }

  sync() {
    return environment.toggles.useMixpanel
      ? combineLatest([
          this.#activateOrDeactivateWhenReady$,
          this.#syncUser$,
          this.#syncMarketingParams$,
        ])
      : of();
  }

  sendEvent(event: MixpanelEvent) {
    this.trackingCache.next(event);
    this.hotjarService.addMixpanelEvent(event);
  }

  /**
   * Clears super properties and generates a new random distinct_id for this instance. Useful for clearing data when a user logs out.
   */
  reset() {
    this.mixpanel?.reset();
  }

  private activate() {
    this.mixpanel = mixpanel;
    this.mixpanel?.init(environment.mixpanel.token, {
      api_host: environment.mixpanel.apiHost,
      debug: environment.mixpanel.debug,
      cross_subdomain_cookie: environment.mixpanel.crossSubdomainCookie,
      ip: false,
      disable_persistence: false,
    });
    this.store.update({ isInitialized: true });
    this.startSendingFromCache();
  }

  private deactivate() {
    this.stopSendingFromCache();
    this.mixpanel?.disable();
    this.mixpanel = undefined;
  }

  private startSendingFromCache() {
    this.trackingCacheSubscription = this.trackingCache.subscribe((event) => {
      this.sendToMixpanel(event);
    });
  }

  private stopSendingFromCache() {
    if (this.trackingCacheSubscription) {
      this.trackingCacheSubscription.unsubscribe();
      this.trackingCacheSubscription = undefined;
      /*
        We need to create a new tracking cache as otherwise all events will be
        resent when the cache is subscribed again. But we should only do this when
        there was a running subscription as otherwise we would drop initial events.
     */
      this.trackingCache = new ReplaySubject<MixpanelEvent>(CACHE_BUFFER_SIZE);
    }
  }

  private setIdentifier(identifier: string) {
    this.logger.info('set identifier', identifier);
    this.mixpanel?.identify(identifier);
    this.mixpanel?.people.set({ $name: identifier });
  }

  private registerLastTouchUtmParams(utmParams: Partial<UtmParams>) {
    const lastTouchUtmParams: Record<string, string> = Object.entries(
      utmParams
    ).reduce(
      (utmParams, [key, value]) => ({
        ...utmParams,
        [`${key} (last touch)`]: value,
      }),
      {}
    );
    this.logger.info('register last touch utm params', lastTouchUtmParams);
    this.mixpanel?.register(lastTouchUtmParams);
  }

  setProfilePropertyAuthType(lastAuthType: 'social' | 'password' | 'link') {
    this.mixpanel?.people.set({ lastAuthType });
  }

  private sendToMixpanel(event: MixpanelEvent) {
    this.mixpanel?.track(event.eventName, {
      ...event.eventProperties,
      ...this.abTestProperties,
    });
  }
}
