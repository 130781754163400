// A collection of types and converters for property keys
// following our naming conventions.

/**
 * A string conforming to our getter naming convention
 * @example ```
 * FormattedGetterKey<'HelloWorld'> -> 'helloWorld'
 * ```
 */
export type FormattedGetterKey<T extends string> = Uncapitalize<T>;
/**
 * Convert a string to our getter naming convention
 */
export function formattedGetterKey<T extends string>(
  input: T
): FormattedGetterKey<T> {
  const firstChar = input[0].toLowerCase();
  const rest = input.substring(1);
  return `${firstChar}${rest}` as FormattedGetterKey<T>;
}

/**
 * A string conforming to our Observable getter naming convention
 * @example ```
 * FormattedGetterObservableKey<'HelloWorld'> -> 'helloWorld$'
 * ```
 */
export type FormattedGetterObservableKey<T extends string> =
  `${FormattedGetterKey<T>}$`;
/**
 * Convert a string to our Observable getter naming convention
 */
export function formattedGetterObservableKey<T extends string>(
  input: T
): FormattedGetterObservableKey<T> {
  return `${formattedGetterKey(input)}$`;
}

/**
 * A string conforming to our setter naming convention
 * @example ```
 * FormattedSetterKey<'helloWorld'> -> 'setHelloWorld'
 * ```
 */
export type FormattedSetterKey<T extends string> = `set${Capitalize<T>}`;
/**
 * Convert a string to our setter naming convention
 */
export function formattedSetterKey<T extends string>(
  input: T
): FormattedSetterKey<T> {
  const firstChar = input[0].toUpperCase();
  const rest = input.substring(1);
  return `set${firstChar}${rest}` as FormattedSetterKey<T>;
}
