import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EdDropdownComponent } from './ed-dropdown.component';

@NgModule({
  declarations: [EdDropdownComponent],
  imports: [CommonModule, FormsModule],
  exports: [EdDropdownComponent],
})
export class EdDropdownModule {}
