import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  AnySignUpV,
  SignupSubmitInput,
} from '@expresssteuer/signup-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class Submit {
  constructor(public afFunctions: AngularFireFunctions) {}

  /**
   * Can be used to finish up the signup process. If all the necessary data is provided and valid, a taxform will be generated. This intern creates a taxcase and a  client (if not already existing)
   *
   * @api input zod:LatestSignUpVZod:@expresssteuer/signup-api-interfaces
   * @api summary finish up a lead
   * @api consumer andorra,mobileapp
   */
  call(input: SignupSubmitInput) {
    console.log('Calling signup-submit');
    return this.afFunctions.httpsCallable<AnySignUpV, void>('signup-submit')(
      input
    );
  }
}
