import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AuthPersistedState {
  emailForRequestedAuthLink?: string;
}

const initialState: () => AuthPersistedState = () => ({});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth-persisted', resettable: true })
export class AuthPersistedStore extends Store<AuthPersistedState> {
  constructor() {
    super(initialState());
  }
}
