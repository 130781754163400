import { Constructor } from '@datorama/akita';
import { InstanceTypeOfConstructor } from './mixin-helper';

type IError = any;

export interface IErrorUpdatableStore<TErrors extends IError> {
  updateError(newErrors: Partial<TErrors>): void;
  clearError(): void;
}

type WithError = { error?: IError | null };

interface IStore<TState extends WithError> {
  getValue(): TState;
  setError(error?: Partial<TState['error']> | null): void;
}

export function ErrorUpdatableStoreMixin<
  TState extends WithError,
  TBase extends Constructor<IStore<TState>>,
  TError extends NonNullable<
    ReturnType<InstanceTypeOfConstructor<TBase>['getValue']>['error']
  >
>(Base: TBase): TBase & Constructor<IErrorUpdatableStore<TError>> {
  return class ErrorUpdatable
    extends Base
    implements IErrorUpdatableStore<TError>
  {
    updateError(newErrors: Partial<TError>) {
      const oldErrors = this.getValue()?.error;
      const mergesErrors = { ...oldErrors, ...newErrors };
      const truthyEntries = Object.entries(mergesErrors).filter(
        ([_key, val]) => val
      );
      const mergedWithoutFalse =
        truthyEntries.length > 0
          ? (Object.fromEntries(truthyEntries) as Partial<TError>)
          : null;
      this.setError(mergedWithoutFalse);
    }

    clearError() {
      this.setError(null);
    }
  };
}
