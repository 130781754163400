import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ClientFileUploadState } from '@expresssteuer/models';
import { NgxDropzoneChangeEvent, NgxDropzoneComponent } from 'ngx-dropzone';

@Component({
  selector: 'esui-file-uploader',
  templateUrl: './esui-file-uploader.component.html',
  styleUrls: ['./esui-file-uploader.component.scss'],
})
export class EsuiFileUploaderComponent {
  @Input()
  uploadZoneTitle?: string;

  @Input()
  uploadZoneFileTypes?: string;

  @Input()
  uploadedFileTitle?: string;

  @Input()
  uploadButtonTitle?: string;

  @Input()
  documentData?: ClientFileUploadState | null;

  @Input()
  uploadProgress?: {
    bytesTransferred: number;
    totalBytes: number;
    percent: number;
  } | null;

  @Input()
  processingFinished?: boolean | null = true;

  @Input()
  hasSelectedFile: boolean | null = false;

  /**
   * If an external computation process should be taken into account
   * when displaying the upload process.
   * Should start with false and end with true.
   * Default is true which means external computation is finished.
   */
  @Input()
  additionalProcessingFinished = true;

  @Input()
  isUploading = false;

  @Input()
  isDeleting = false;

  @Input()
  allowDeletion = true;

  @Input()
  displayDocumentState = true;

  @Output()
  fileSelected = new EventEmitter<File>();

  @Output()
  deleteSelected = new EventEmitter<string>();

  @ViewChild('dropzone') drop?: NgxDropzoneComponent;

  addFile(event: NgxDropzoneChangeEvent) {
    this.fileSelected.next(event?.addedFiles[0]);
  }

  handleDeleteFile(fileId?: string) {
    if (!fileId) {
      return;
    }
    this.deleteSelected.next(fileId);
  }

  get uploadProgressPercentage() {
    const percentage = 10 + (this.uploadProgress?.percent || 0) * 0.8;
    return Math.floor(percentage);
  }
}
