import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EdCollapsibleItemComponent } from './ed-collapsible-item.component';

@NgModule({
  declarations: [EdCollapsibleItemComponent],
  imports: [CommonModule, MatIconModule],
  exports: [EdCollapsibleItemComponent],
})
export class EdCollapsibleItemModule {}
