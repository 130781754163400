import { Component } from '@angular/core';
import { LegalOverlaysService } from '../../../../state/legal-overlays/legal-overlays.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(public legalOverlaysService: LegalOverlaysService) {}
}
