import { Constructor } from '@datorama/akita';
import { Observable } from 'rxjs';
import {
  FormattedGetterKey,
  formattedGetterKey,
  FormattedGetterObservableKey,
  formattedGetterObservableKey,
  FormattedSetterKey,
  formattedSetterKey,
} from '../namings/namings';

export interface ISortStore<TSortName extends string, TSortKey extends string> {
  sort: {
    [k in FormattedSetterKey<TSortName>]: (
      change: Partial<Sort<TSortKey>>
    ) => void;
  };
}

export interface ISortQuery<TSortName extends string, TSortKey extends string> {
  sort: {
    [k in FormattedGetterObservableKey<TSortName>]: Observable<
      Sort<TSortKey> | undefined
    >;
  };
}

export type WithSort<TSortName extends string, TSortKey extends string> = {
  sort: {
    [k in FormattedGetterKey<TSortName>]: Sort<TSortKey>;
  };
};

interface IStore<
  TSortName extends string,
  TSortKey extends string,
  TState extends WithSort<TSortName, TSortKey>
> {
  getValue(): TState;
  update(change: Partial<TState>): void;
}

interface IQuery<
  TSortName extends string,
  TSortKey extends string,
  TState extends WithSort<TSortName, TSortKey>
> {
  select<T>(callback: (state: TState) => T): Observable<T>;
}

export function SortStoreMixin<
  TSortKey extends string,
  TSortName extends string,
  TState extends WithSort<TSortName, TSortKey>,
  TBase extends Constructor<IStore<TSortName, TSortKey, TState>>
>(
  Base: TBase,
  sortName: TSortName,
  template?: Sort<TSortKey>
): TBase & Constructor<ISortStore<TSortName, TSortKey>> {
  return class SortS extends Base implements ISortStore<TSortName, TSortKey> {
    sort!: {
      [k in FormattedSetterKey<TSortName>]: (
        change: Partial<Sort<TSortKey>>
      ) => void;
    };

    private formattedNameSetter = formattedSetterKey(sortName);
    private formattedNameGetter = formattedGetterKey(sortName);

    constructor(...args: any[]) {
      super(...args);
      if (!this.sort) {
        this.sort = {} as NonNullable<ISortStore<TSortName, TSortKey>['sort']>;
      }

      const currentValue = this.getValue().sort?.[this.formattedNameGetter];
      if (template && !currentValue) {
        this.#updatePage(template);
      }

      this.sort[this.formattedNameSetter] = (change: Partial<Sort<TSortKey>>) =>
        this.#updatePage(change);
    }

    #updatePage(change: Partial<Sort<TSortKey>>) {
      const currentValue = this.getValue();
      currentValue.sort;

      this.update({
        sort: {
          ...currentValue.sort,
          [this.formattedNameGetter]: {
            ...currentValue.sort?.[this.formattedNameGetter],
            ...change,
          },
        },
      } as any);
    }
  };
}

export function SortQueryMixin<
  TSortName extends string,
  TSortKey extends string,
  TState extends WithSort<TSortName, TSortKey>,
  TBase extends Constructor<IQuery<TSortName, TSortKey, TState>>
>(
  Base: TBase,
  sortName: TSortName
): TBase & Constructor<ISortQuery<TSortName, TSortKey>> {
  return class SortQ extends Base implements ISortQuery<TSortName, TSortKey> {
    sort!: {
      [k in FormattedGetterObservableKey<TSortName>]: Observable<
        Sort<TSortKey> | undefined
      >;
    };

    constructor(...args: any[]) {
      super(...args);
      if (!this.sort) {
        this.sort = {} as NonNullable<ISortQuery<TSortName, TSortKey>['sort']>;
      }
      const formattedNameGetter = formattedGetterKey(sortName);
      const formattedNameObservableGetter =
        formattedGetterObservableKey(sortName);

      this.sort[formattedNameObservableGetter] = this.select(
        (s) => s.sort?.[formattedNameGetter]
      );
    }
  };
}

export interface Sort<TKey extends string> {
  active: TKey | string;
  direction: 'asc' | 'desc' | '';
}
