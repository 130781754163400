<ng-container>
  <div class="trust-icons">
    <img
      class="trust-icons__image"
      alt="ssl"
      src="assets/images/icon/ssl.png"
    />
    <a
      href="https://www.ekomi.de/bewertungen-ExpressSteuer.html"
      rel="noopener"
      target="_blank"
    >
      <img
        class="trust-icons__image"
        alt="ekomi"
        src="assets/images/icon/ekomi_trust.png"
      />
    </a>
    <a
      href="https://www.bitmi.de/leistungen/software-made-in-germany"
      rel="noopener"
      target="_blank"
    >
      <img
        class="trust-icons__image"
        alt="itmittelstand"
        src="assets/images/icon/itmittelstand.png"
      />
    </a>
  </div>

  <div class="legal-links">
    <a
      class="legal-links__link"
      data-toggle="modal"
      (click)="legalOverlaysService.showImprintOverlay()"
      >{{ 'legal.imprint' | translate }}</a
    >
    |
    <a
      class="legal-links__link"
      data-toggle="modal"
      (click)="legalOverlaysService.showConditionsOverlay()"
      >{{ 'legal.conditions' | translate }}</a
    >
    |
    <a
      class="legal-links__link"
      data-toggle="modal"
      (click)="legalOverlaysService.showDataProtectionOverlay()"
      >{{ 'legal.dataProtection' | translate }}</a
    >
    |
    <a class="legal-links__link" href="javascript:Cookiebot.renew()">Cookies</a>
  </div>

  <div class="app-hint">
    {{ 'headline.calculation.weDoNotOfferTaxAdvice' | translate }}
  </div>
</ng-container>
