import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { EdTextfieldComponent } from '../ed-textfield/ed-textfield.component';

@Component({
  selector: 'ed-code',
  templateUrl: './ed-code.component.html',
  styleUrls: ['./ed-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EdCodeComponent,
      multi: true,
    },
  ],
})
export class EdCodeComponent implements ControlValueAccessor, AfterViewInit {
  get isInvalid() {
    return this.ngControl?.invalid;
  }
  get isTouched() {
    return this.ngControl?.touched;
  }
  get isDirty() {
    return this.ngControl?.dirty;
  }
  get isValid() {
    return this.ngControl?.valid;
  }
  get isUntouched() {
    return this.ngControl?.untouched;
  }
  get isPristine() {
    return this.ngControl?.pristine;
  }

  @Input()
  name?: string;
  @Input()
  codeLength = 6;
  @Input()
  disabled?: boolean | null;
  @Input()
  errorText?: string;

  @Output()
  codeComplete = new EventEmitter<string>();

  @ViewChildren('textfieldReferences')
  textfieldReferences?: QueryList<EdTextfieldComponent>;

  codes = Array(this.codeLength).fill('');
  uniqueNameProperty = `ed-code-${this.getRandomValues()}`; // prevent misleading autofill suggestions
  private inputElements?: HTMLInputElement[];
  private ngControl?: NgControl | null;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit() {
    this.gatherInputElements();
    this.focusFirstElement();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_?: string) => {};
  registerOnChange(fn: () => unknown) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};
  registerOnTouched(fn: () => unknown) {
    this.onTouch = fn;
  }

  writeValue(value?: string) {
    this.saveCodeToArray(value ?? '');
  }

  onFieldChange(fieldInput: string, index: number) {
    const value = parseInt(fieldInput);
    if (Number.isInteger(value)) {
      this.saveInputToArray(fieldInput, index);
      this.emitChanges();
      this.focusNextElement(index);
    } else {
      if (this.inputElements) {
        this.inputElements[index].value = ''; // clear non-number inputs
      }
    }
  }

  onFieldKeyUp(fieldKeyUp: KeyboardEvent, index: number) {
    if (fieldKeyUp.key === 'Backspace') {
      if (this.codes[index] === '') {
        this.deletePreviousValue(index);
        this.focusPreviousElement(index);
      } else {
        this.deleteCurrentValue(index);
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData?.getData('text');
    if (pasteData) {
      const code = parseInt(pasteData);
      if (Number.isInteger(code)) {
        this.saveCodeToArray(pasteData);
        this.focusSpecificElement(pasteData.length);
        this.emitChanges();
      }
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  private gatherInputElements() {
    this.inputElements = this.textfieldReferences
      ?.toArray()
      .map((edTextfieldComponent) => edTextfieldComponent.getInputElement());
  }

  private focusFirstElement() {
    this.inputElements?.[0].focus();
  }

  private focusNextElement(currentIndex: number) {
    if (currentIndex < this.codes.length - 1) {
      this.inputElements?.[currentIndex + 1].focus();
    } else {
      this.inputElements?.[currentIndex]?.blur();
    }
  }

  private focusPreviousElement(currentIndex: number) {
    if (currentIndex > 0) {
      this.inputElements?.[currentIndex - 1].focus();
    }
  }

  private focusSpecificElement(index: number) {
    if (index >= 0 && index < this.codes.length) {
      this.inputElements?.[index].focus();
    } else {
      this.inputElements?.forEach((element) => element.blur()); // reset focus of alle inputs
    }
  }

  private deletePreviousValue(currentIndex: number) {
    if (currentIndex > 0) {
      this.saveInputToArray('', currentIndex - 1);
      this.emitChanges();
    }
  }

  private deleteCurrentValue(currentIndex: number) {
    if (currentIndex > 0) {
      this.emitChanges();
      this.saveInputToArray('', currentIndex);
    }
  }

  private saveCodeToArray(code: string) {
    this.codes = new Array(6).fill('').map((value, index) => code[index] ?? '');
  }

  private saveInputToArray(input: string, index: number) {
    this.codes = this.codes.map((value, currentIndex) => {
      if (currentIndex === index) {
        return input;
      } else {
        return value;
      }
    });
  }

  private emitChanges() {
    const codeString = this.codes.join('');
    this.onChange(codeString);
    if (codeString.length === this.codeLength) {
      this.emitCompleteEventIfComplete(codeString);
    }
  }

  private emitCompleteEventIfComplete(code: string) {
    this.codeComplete.emit(code);
  }

  private getRandomValues() {
    const randomInts = new Uint32Array(2);
    this.document.defaultView?.crypto.getRandomValues(randomInts);
    return randomInts.join('').toString();
  }
}
