import { Component, Input } from '@angular/core';

@Component({
  selector: 'ed-progressbar',
  templateUrl: './ed-progressbar.component.html',
  styleUrls: ['./ed-progressbar.component.scss'],
})
export class EdProgressbarComponent {
  @Input()
  value?: number;

  @Input()
  time?: number;

  @Input()
  hideRemainingTime = false;
}
