import { ClientTodo } from '@expresssteuer/client-todos-api-interfaces';

export enum TodoStatus {
  /**
   * Active todo, not yet worked on
   */
  new = 'new',
  /**
   * Active todo, with no accepted but at least one incomplete answer (aka. ready === false)
   */
  draft = 'draft',
  /**
   * Active todo with at least one answer that is currently being validated
   */
  processing = 'processing',
  /**
   * Active todo, with and only with not accepted answers
   */
  erroneous = 'erroneous',
  /**
   * Active todo with at least one acceptable answer
   */
  successfully_processed = 'successfully_processed',
  /**
   * Inactive todo, with at least one accepted answer
   */
  completed = 'completed',
  /**
   * Inactive todo, without an accepted answer
   */
  not_needed = 'not_needed',
  /**
   * Active todo, with helpNeeded
   */
  escalated = 'escalated',
}

export function getStatus(
  todo: ClientTodo
  // answer: Answer,
  // feedback: AnswerFeedback
): TodoStatus {
  const status = ((todo) => {
    // if (todo.version === '0.2') {
    if (todo.acceptedAnswers?.length > 0 && todo.active === false) {
      return TodoStatus.completed;
    }
    if (todo.active === false) {
      return TodoStatus.not_needed;
    }
    if (todo.helpNeeded) {
      return TodoStatus.escalated;
    }
    if (todo.acceptedAnswers?.length > 0) {
      return TodoStatus.successfully_processed;
    }
    if (todo.processingAnswers?.length > 0) {
      return TodoStatus.processing;
    }
    if (todo.draftAnswers?.length > 0) {
      return TodoStatus.draft;
    }
    if (todo.faultyAnswers?.length > 0) {
      return TodoStatus.erroneous;
    }

    return TodoStatus.new;
  })(todo);

  // console.log('status-log', {
  //   id: t.id,
  //   active: t.active,
  //   isProcessing: t.isProcessing,
  //   relatedAnswerSet: t.relatedAnswerId != null,
  //   accepted: t.accepted,
  //   hasError: (t as unknown as { errorID: unknown }).errorID != null,
  //   status,
  // });

  return status;
}
